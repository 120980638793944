import React, { useState, useEffect, useCallback } from 'react';
import { Form, FormGroup } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput';
import { Card } from 'components/Card';
import IntlMessages from 'util/IntlMessages';
import { injectIntl, intlShape } from 'react-intl';
import * as _ from 'lodash';
import { Input } from 'reactstrap';
import RequirementList from 'components/PasswordRequirement/RequirementList';

import { getLoading, getError } from 'reducers/AuthReducer';

import { changePassword } from 'actions';

import { getUser } from 'reducers/AuthReducer';

const ChangePassword = ({ history, intl }) => {
    const ChangePwdSchema = yup.object().shape({
        // "user-mail": yup.string().required(intl.formatMessage({id: 'input.emailRequired'})).email(intl.formatMessage({id: 'input.emailRestricted'})),
        'user-pwd': yup.string().required(intl.formatMessage({ id: 'input.passwordRequired' })),
        'user-new-pwd': yup
            .string()
            .required(intl.formatMessage({ id: 'input.passwordRequired' }))
            .matches(
                /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/,
                intl.formatMessage({ id: 'input.specialCharRequired' })
            )
            .matches(/^.*[a-z].*$/, intl.formatMessage({ id: 'input.lowercaseRequired' }))
            .matches(/^.*[A-Z].*$/, intl.formatMessage({ id: 'input.uppercaseRequired' }))
            .matches(/^.*[0-9].*$/, intl.formatMessage({ id: 'input.numberRequired' }))
            .min(8, intl.formatMessage({ id: 'input.minLengthRequired' })),
        'confirm-pwd': yup
            .string()
            .oneOf([yup.ref('user-new-pwd'), null], intl.formatMessage({ id: 'input.passwordMatched' })),
    });

    const dispatch = useDispatch();

    const { loading, error, user } = useSelector(state => ({
        loading: getLoading(state.auth),
        error: getError(state.auth),
        user: getUser(state.auth),
    }));

    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        setUserEmail(_.get(user, 'member.personemail'));
    }, [user]);

    const { control, handleSubmit, errors } = useForm({
        criteriaMode: 'all',
        resolver: yupResolver(ChangePwdSchema),
    });

    const onChangePassword = data => {
        dispatch(
            changePassword(
                {
                    username: _.get(user, 'member.personemail'),
                    oldPassword: data['user-pwd'],
                    password: data['user-new-pwd'],
                },
                history,
                '/app/users/profile'
            )
        );
    };

    const oldPwPH = intl.formatMessage({ id: 'changepw.oldPassword' });
    const newPwPH = intl.formatMessage({ id: 'changepw.newPassword' });
    const confirmPwPH = intl.formatMessage({ id: 'changepw.confirmPassword' });

    return (
        <div className="changepassword-wrapper">
            <div className="row justify-content-center align-self-center w-100">
                <div className="col-12 col-md-6">
                    <Card customClass="h-100 shadow" heading={<IntlMessages id="changepw.changePassword" />}>
                        <div className="px-3 text-center">
                            <div className="pb-3 text-center">
                                {error &&
                                    (typeof error === 'number' ? (
                                        <span className="error-message">
                                            <IntlMessages id={`error.${error}`} />
                                        </span>
                                    ) : (
                                        <span className="error-message">
                                            <IntlMessages id={'error.default'} />
                                        </span>
                                    ))}
                            </div>
                            <Form onSubmit={handleSubmit(onChangePassword)}>
                                <div className="pb-3">
                                    <Input disabled value={userEmail} className="has-input input-lg" />
                                </div>

                                <CustomInput
                                    errors={errors}
                                    control={control}
                                    type="password"
                                    name="user-pwd"
                                    id="user-pwd"
                                    defaultValue=""
                                    className="has-input input-lg"
                                    placeholder={oldPwPH}
                                />

                                <CustomInput
                                    // errors={errors}
                                    control={control}
                                    type="password"
                                    name="user-new-pwd"
                                    id="user-new-pwd"
                                    defaultValue=""
                                    className="has-input input-lg"
                                    placeholder={newPwPH}
                                />

                                <CustomInput
                                    errors={errors}
                                    control={control}
                                    type="password"
                                    name="confirm-pwd"
                                    id="confirm-pwd"
                                    defaultValue=""
                                    className="has-input input-lg"
                                    placeholder={confirmPwPH}
                                />

                                <FormGroup>
                                    <Button
                                        disabled={loading}
                                        color="primary"
                                        className="btn-block text-white w-100"
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                    >
                                        <IntlMessages id="changepw.confirm" />
                                    </Button>
                                </FormGroup>
                            </Form>
                            <RequirementList className="align-self-center" errors={errors['user-new-pwd']} />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};
ChangePassword.propTypes = {
    intl: intlShape.isRequired,
};
export default injectIntl(ChangePassword);
