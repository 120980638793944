import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_FAILURE,
    FORGOT_USER_PW,
    FORGOT_USER_PW_SUCCESS,
    FORGOT_USER_PW_FAILURE,
    CONFIRMATION_FORGOT_USER_PW,
    CONFIRMATION_FORGOT_USER_PW_SUCCESS,
    CONFIRMATION_FORGOT_USER_PW_FAILURE,
    CHANGE_USER_PW,
    CHANGE_USER_PW_FAILURE,
    CHANGE_USER_PW_SUCCESS,
    VALIDATE_USER,
    VALIDATE_USER_FAILURE,
    VALIDATE_USER_SUCCESS,
    REDIRECT_CRM_SUCCESS,
    REDIRECT_CRM_FAILED,
    REDIRECT_CRM,
    SSO_LOGIN,
    SSO_LOGIN_SUCCESS,
    SSO_LOGIN_FAILURE,
} from 'actions/types';

const INIT_STATE = {
    user: JSON.parse(sessionStorage.getItem('user')),
    // user: 'null',
    loading: false,
    error: null,
    valid: false,
    crmLoading: false,
    isInApp: JSON.parse(sessionStorage.getItem('isInApp')) || false,
    inAppEntity: JSON.parse(sessionStorage.getItem('inAppEntity')) || false,
};

export const AuthReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true, error: null };

        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload, error: null };

        case LOGIN_USER_FAILURE:
            return { ...state, loading: false, error: action.error };

        case SSO_LOGIN:
            return { ...state, loading: true, error: null };

        case SSO_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                error: null,
                isInApp: true,
                inAppEntity: action.payload.inAppEntity,
            };

        case SSO_LOGIN_FAILURE:
            return { ...state, loading: false, error: action.error };

        case LOGOUT_USER:
            return { ...state, loading: true };

        case LOGOUT_USER_SUCCESS:
            return { ...state, loading: false, user: null };

        case LOGOUT_USER_FAILURE:
            return { ...state, loading: false };

        case SIGNUP_USER:
            return { ...state, loading: true };

        case SIGNUP_USER_SUCCESS:
            return { ...state, loading: false };

        case SIGNUP_USER_FAILURE:
            return { ...state, loading: false };

        case FORGOT_USER_PW:
            return { ...state, loading: true, error: null };

        case FORGOT_USER_PW_SUCCESS:
            return { ...state, loading: false, error: null };

        case FORGOT_USER_PW_FAILURE:
            return { ...state, loading: false, error: action.error };

        case CONFIRMATION_FORGOT_USER_PW:
            return { ...state, loading: true, error: null };

        case CONFIRMATION_FORGOT_USER_PW_SUCCESS:
            return { ...state, loading: false, error: null };

        case CONFIRMATION_FORGOT_USER_PW_FAILURE:
            return { ...state, loading: false, error: action.error };

        case CHANGE_USER_PW:
            return { ...state, loading: true, error: null };

        case CHANGE_USER_PW_SUCCESS:
            return { ...state, loading: false, error: null };

        case CHANGE_USER_PW_FAILURE:
            return { ...state, loading: false, error: action.error };

        case VALIDATE_USER:
            return { ...state, loading: true };

        case VALIDATE_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload, valid: true };

        case VALIDATE_USER_FAILURE:
            return { ...state, loading: false, valid: false };

        case REDIRECT_CRM:
            return { ...state, crmLoading: true };

        case REDIRECT_CRM_SUCCESS:
            return { ...state, crmLoading: false };

        case REDIRECT_CRM_FAILED:
            return { ...state, crmLoading: false };

        default:
            return { ...state };
    }
};

//selector
export const getUser = state => state.user;
export const getLoading = state => state.loading;
export const getError = state => state.error;
export const getValidity = state => state.valid;
export const getCrmLoading = state => state.crmLoading;
export const getIsInApp = state => state.isInApp;
export const getInAppEntity = state => state.inAppEntity;
