import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import * as _ from 'lodash';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl, intlShape } from 'react-intl';

import { Card } from 'components/Card';
import IntlMessages from 'util/IntlMessages';
import { getRedemption } from 'actions';

import { getRecordList as getRedemptionHistoryList, getLoading, getCount } from 'reducers/RedemptionReducer';
import { getLanguage } from 'reducers/SettingReducer';

const RedemptionHistory = ({ match, intl }) => {
    const redemptionIdC = intl.formatMessage({ id: 'redemption.redemptionId' });
    const redemptionDateC = intl.formatMessage({ id: 'redemption.redemptionDate' });
    const redemptionEntityC = intl.formatMessage({ id: 'redemption.redemptionEntity' });
    const rewardNameC = intl.formatMessage({ id: 'redemption.rewardName' });
    const quantityC = intl.formatMessage({ id: 'redemption.quantity' });
    const totalMemberPointC = intl.formatMessage({ id: 'redemption.totalMemberPoint' });
    const redemptionStatusC = intl.formatMessage({ id: 'redemption.status' });
    const promotionLuckyDrawEventC = intl.formatMessage({ id: 'promotion.luckydraw.event' });

    const columnMap = {
        [redemptionIdC]: 'name',
        [redemptionDateC]: 'required_date_time__c',
        [rewardNameC]: 'reward__c.reward_item__c.name',
        [quantityC]: 'quantity__c',
        [redemptionEntityC]: 'entity__c',
        [totalMemberPointC]: 'total_loyalty_point__c',
        [redemptionStatusC]: 'status__c',
    };

    const columns = Object.keys(columnMap);
    const columnAPIs = Object.values(columnMap);

    const [redemptionHistoryShowList, setRedemptionHistoryShowList] = useState([]);

    const [opts, setOpts] = useState({
        orderBy: 'name',
        isAsc: false,
        pageSize: 10,
        pageNo: 0,
    });

    const [pageSize, setPageSize] = useState(0);

    const tableOptions = {
        print: false,
        filter: false,
        filterType: 'dropdown',
        customToolbarSelect: () => {},
        selectableRows: 'none',
        rowsPerPageOptions: [5, 10, 20],
        onChangePage: currentPage => {
            setOpts(o => ({ ...opts, pageNo: currentPage }));
            disaptch(getRedemption({ queryParam: { language: language.languageId }, ...opts, pageNo: currentPage }));
        },
        onChangeRowsPerPage: numberOfRows => {
            setOpts(o => ({ ...opts, pageSize: numberOfRows }));
            disaptch(getRedemption({ queryParam: { language: language.languageId }, ...opts, pageSize: numberOfRows }));
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (columnMap[changedColumn].indexOf('.') > -1) return;

            let param = { isAsc: direction === 'asc' ? true : false, orderBy: columnMap[changedColumn] };

            setOpts(o => ({ ...opts, ...param }));
            disaptch(getRedemption({ queryParam: { language: language.languageId }, ...opts, ...param }));
        },
        elevation: 0,
        serverSide: true,
        count: pageSize,
        download: false,
        textLabels: {
            body: {
                noMatch: intl.formatMessage({ id: 'table.body.noMatch' }),
                toolTip: intl.formatMessage({ id: 'table.body.sort' }),
                columnHeaderTooltip: column => intl.formatMessage({ id: 'table.body.sorFor' }) + ` ${column.label}`,
            },
            pagination: {
                next: intl.formatMessage({ id: 'table.pagination.next' }),
                previous: intl.formatMessage({ id: 'table.pagination.previous' }),
                rowsPerPage: intl.formatMessage({ id: 'table.pagination.rowsPerPage' }),
                displayRows: intl.formatMessage({ id: 'table.pagination.displayRows' }),
            },
            toolbar: {
                search: intl.formatMessage({ id: 'table.toolbar.search' }),
                downloadCsv: intl.formatMessage({ id: 'table.toolbar.downloadCsv' }),
                print: intl.formatMessage({ id: 'table.toolbar.print' }),
                viewColumns: intl.formatMessage({ id: 'table.toolbar.viewColumns' }),
                filterTable: intl.formatMessage({ id: 'table.toolbar.filterTable' }),
            },
            filter: {
                all: intl.formatMessage({ id: 'table.filter.all' }),
                title: intl.formatMessage({ id: 'table.filter.title' }),
                reset: intl.formatMessage({ id: 'table.filter.reset' }),
            },
            viewColumns: {
                title: intl.formatMessage({ id: 'table.viewColumns.title' }),
                titleAria: intl.formatMessage({ id: 'table.viewColumns.titleAria' }),
            },
            selectedRows: {
                text: intl.formatMessage({ id: 'table.selectedRows.text' }),
                delete: intl.formatMessage({ id: 'table.selectedRows.delete' }),
                deleteAria: intl.formatMessage({ id: 'table.selectedRows.deleteAria' }),
            },
        },
    };

    const disaptch = useDispatch();

    const { language, redemptionHistoryList, loading, count } = useSelector(state => ({
        language: getLanguage(state.setting),
        redemptionHistoryList: getRedemptionHistoryList(state.redemption),
        loading: getLoading(state.redemption),
        count: getCount(state.redemption),
    }));

    useEffect(() => {
        setPageSize(Number(count));
    }, [count]);

    useEffect(() => {
        disaptch(getRedemption({ queryParam: { language: language.languageId }, ...opts }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        disaptch(getRedemption({ queryParam: { language: language.languageId }, ...opts }));
    }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setRedemptionHistoryShowList(list => []);
        // redemptionHistoryList.sort((preRedemptionHistory, postRedemptionHistory) => postRedemptionHistory.id - preRedemptionHistory.id)
        redemptionHistoryList.forEach((data, index) => {
            let listToPush = [];
            for (let APIName of columnAPIs) {
                if (APIName === 'required_date_time__c' && _.get(data, APIName)) {
                    listToPush.push(moment(_.get(data, APIName)).utc().local().format('YYYY-MM-DD HH:mm:ss'));
                    continue;
                }

                if (APIName === 'status__c' && _.get(data, APIName)) {
                    listToPush.push(intl.formatMessage({ id: 'redemption.status.' + _.get(data, APIName) }));
                    continue;
                }

                if (
                    APIName === 'reward__c.reward_item__c.name' &&
                    _.get(data, 'promotion_event_reward__c.reward_name__c')
                ) {
                    listToPush.push(
                        _.get(data, 'promotion_event_reward__c.reward_name__c') + ' - ' + promotionLuckyDrawEventC
                    );
                    continue;
                }

                listToPush.push(_.get(data, APIName));
            }
            setRedemptionHistoryShowList(list => [...list, listToPush]);
        });
    }, [redemptionHistoryList, language]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="redemption-history-wrapper">
            <Card>
                <MUIDataTable
                    title={
                        <Typography component="span">
                            <IntlMessages id="redemption.historyTable" />
                            {loading && (
                                <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
                            )}
                        </Typography>
                    }
                    data={redemptionHistoryShowList}
                    columns={columns}
                    options={tableOptions}
                />
            </Card>
        </div>
    );
};
RedemptionHistory.prototype = {
    intl: intlShape.isRequired,
};

export default injectIntl(RedemptionHistory);
