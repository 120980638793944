import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import * as _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import IntlMessages from 'util/IntlMessages';

import { Button } from '@material-ui/core';

import { CollapsibleCard } from 'components/Card';
import Slider from '@material-ui/core/Slider';

import { getFilter, getInputParams } from 'reducers/RewardReducer';

import { getLanguage } from 'reducers/SettingReducer';

import { getUser } from 'reducers/AuthReducer';

import { getItemTags } from 'reducers/LoyalityPortalConfigReducer';

import { getReward, setRewardFilter, getLoyaltyPortalConfig } from 'actions';
import { getReplacedEntities, revertReplacedEntities } from 'constants/constant';

const Filter = ({ intl }) => {
    const MAX_POINT = 999999;
    const MIN_POINT = 0;

    const dispatch = useDispatch();

    const searchPH = intl.formatMessage({ id: 'reward.searchPH' });

    const { filter, inputParams, itemTags, language, user } = useSelector(state => ({
        filter: getFilter(state.reward),
        inputParams: getInputParams(state.reward),
        itemTags: getItemTags(state.loyalityPortalConfig).recordList,
        language: getLanguage(state.setting),
        user: getUser(state.auth),
    }));

    //prevent trigger in first rendering
    const [isFirstRenderTag, setIsFirstRenderTag] = useState(true);

    const [entityChecked, setEntityChecked] = useState({}); //entity
    const [checkedList, setCheckedList] = useState([]); //tag
    const [uncheckedList, setUncheckedList] = useState([]); //tag
    const [searchValue, setSearchValue] = useState(''); //search
    const [pointRange, setPointRange] = useState([0, (MAX_POINT).toFixed(0)]); //range

    useEffect(() => {
        setCheckedList([]);
        dispatch(getLoyaltyPortalConfig({ queryParam: { language: language.languageId } }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    useEffect(() => {
        let entityList = _.get(user, 'member.entityList');
        const entityCheckedList = {};
        if (!_.isEmpty(entityList)) {
            
            /**
             * * 2024-05-23 
             * * Authored by: Homan Lui
             * * Add replaced entites logic by requirement
             * * to replace the entity 'EBL 2.0' as 'EBL'
            */
            let replacedEntites = getReplacedEntities(entityList);
            replacedEntites.map(entity => {
                entityCheckedList[entity] = true;
            });
            setEntityChecked(entityCheckedList);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (!isFirstRenderTag) {
            let checkedList = Object.keys(entityChecked).filter(tag => entityChecked[tag]);
            console.log('checked list ', checkedList);
            // Revert replaced entities
            let revertEntities = revertReplacedEntities(checkedList);
            revertEntities = [...revertEntities, ...checkedList];
            console.log('Revert entities ', revertEntities);

            dispatch(setRewardFilter({ ...filter, entityList: revertEntities }));
            dispatch(getReward({ queryParam: { ...filter, entityList: revertEntities }, ...inputParams }));
        }
        setIsFirstRenderTag(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityChecked]);

    useEffect(() => {
        if (itemTags.length !== 0) setUncheckedList(itemTags);
    }, [itemTags]);

    const handleChange = (event, newValue) => {
        setPointRange(newValue);
        dispatch(setRewardFilter({ ...filter, loyaltyPointStart: newValue[0], loyaltyPointEnd: newValue[1] }));
    };

    const setMinValue = value => {
        if (MAX_POINT < value) {
            pointRange[0] = MIN_POINT;
            setPointRange(pointRange);
            return;
        }
        if (value < MIN_POINT) {
            pointRange[0] = MIN_POINT;
            setPointRange(pointRange);
            return;
        }
        pointRange[0] = value;
        setPointRange(pointRange);
        dispatch(setRewardFilter({ ...filter, loyaltyPointStart: pointRange[0], loyaltyPointEnd: pointRange[1] }));
    };

    const setMaxValue = value => {
        if (MAX_POINT < value) {
            pointRange[1] = MAX_POINT;
            setPointRange(pointRange);
            return;
        }
        if (value < MIN_POINT) {
            pointRange[1] = MAX_POINT;
            setPointRange(pointRange);
            return;
        }
        pointRange[1] = value;
        setPointRange(pointRange);
        dispatch(setRewardFilter({ ...filter, loyaltyPointStart: pointRange[0], loyaltyPointEnd: pointRange[1] }));
    };

    const handleTagChange = event => {
        setEntityChecked({ ...entityChecked, [event.target.name]: event.target.checked });
    };

    const handleCheckedChange = event => {
        let index = checkedList.findIndex(tag => tag.sfid === event.sfid);

        if (index !== -1) {
            checkedList.splice(index, 1);
            setCheckedList(checkedList);

            uncheckedList.push(event);
            setUncheckedList(uncheckedList);
        } else {
            let uncheckedIndex = uncheckedList.findIndex(tag => tag.sfid === event.sfid);
            if (uncheckedIndex !== -1) {
                uncheckedList.splice(uncheckedIndex, 1);
                setUncheckedList(uncheckedList);
            }

            checkedList.push(event);
            setCheckedList(checkedList);
        }

        if (checkedList && checkedList.length > 0) {
            let sfidList = [];
            checkedList.forEach(tag => {
                sfidList.push(tag.sfid);
            });
            dispatch(setRewardFilter({ ...filter, tagList: sfidList }));
            dispatch(getReward({ queryParam: { ...filter, tagList: sfidList }, ...inputParams }));
        } else {
            let param = { ...filter, tagList: null };
            delete param.tagList;
            dispatch(setRewardFilter(param));
            dispatch(getReward({ queryParam: { ...param }, ...inputParams }));
        }
    };

    const handleSearch = event => {
        if (searchValue !== '') {
            dispatch(setRewardFilter({ ...filter, itemName: searchValue }));
            dispatch(getReward({ queryParam: { ...filter, itemName: searchValue }, ...inputParams }));
        } else if (searchValue === '') {
            let param = { ...filter, itemName: null };
            delete param.itemName;
            dispatch(setRewardFilter(param));
            dispatch(getReward({ queryParam: param, ...inputParams }));
        } else {
            dispatch(setRewardFilter({ ...filter, itemName: searchValue }));
            dispatch(getReward({ queryParam: { ...filter, itemName: searchValue }, ...inputParams }));
        }
    };

    const filterReward = () => {
        if (filter.loyaltyPointStart === '' || filter.loyaltyPointEnd === '') return;
        dispatch(getReward({ queryParam: filter, ...inputParams }));
    };

    return (
        <div className="filter-wrapper">
            <CollapsibleCard heading={<IntlMessages id="reward.filter" />} collapsible>
                <Form
                    className="py-2 px-4"
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <FormGroup>
                        <label className="reward-card-mini-text">{<IntlMessages id="reward.itemName" />}</label>
                        <InputGroup>
                            <Input
                                type="text"
                                className="has-input input-lg"
                                name="search"
                                placeholder={searchPH}
                                value={searchValue}
                                onKeyPress={() => {}}
                                onChange={e => {
                                    setSearchValue(e.target.value);
                                }}
                            ></Input>
                            <InputGroupAddon addonType="append">
                                <Button className="h-100" onClick={handleSearch}>
                                    <i className="zmdi zmdi-search" />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Form>

                <div className="py-3 px-4">
                    <label className="reward-card-mini-text">{<IntlMessages id="reward.pointRange" />}</label>
                    <div className="px-2">
                        <Slider value={pointRange} onChange={handleChange} min={MIN_POINT} max={MAX_POINT} />
                        <div>
                            <div className="row px-2">
                                <div className="col-xl-12 px-1 col-xxl-5">
                                    <Input
                                        type="number"
                                        className="w-100 has-input input-lg"
                                        name="search"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        value={pointRange[0]}
                                        onKeyPress={() => {}}
                                        onChange={e => {
                                            setMinValue(e.target.value);
                                        }}
                                        min={MIN_POINT}
                                        max={MAX_POINT - 1}
                                    ></Input>
                                </div>
                                <div className="text-center col-xl-12 col-xxl-1">-</div>
                                <div className="col-xl-12 col-xxl-5 px-1">
                                    <Input
                                        type="number"
                                        className="has-input input-lg"
                                        name="search"
                                        pattern="[0-9]*"
                                        inputmode="numeric"
                                        value={pointRange[1]}
                                        onKeyPress={() => {}}
                                        onChange={e => {
                                            setMaxValue(e.target.value);
                                        }}
                                        min={MIN_POINT + 1}
                                        max={MAX_POINT}
                                    ></Input>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <Button
                                color="primary"
                                className="btn-block text-white w-100"
                                variant="contained"
                                onClick={filterReward}
                            >
                                {<IntlMessages id="reward.confirm" />}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="py-3 px-4">
                    {!_.isEmpty(entityChecked) &&
                        Object.keys(entityChecked).map((tag, key) => {
                            return (
                                <div key={key}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={entityChecked[tag]}
                                                onChange={handleTagChange}
                                                name={tag}
                                                color="primary"
                                            />
                                        }
                                        label={tag}
                                    />
                                </div>
                            );
                        })}
                </div>

                <div className="py-3 px-4">
                    <label className="reward-card-mini-text">{<IntlMessages id="reward.selectedTagging" />}</label>
                    {checkedList && checkedList.length > 0 ? (
                        <div>
                            {checkedList.map(tag => {
                                return (
                                    <Chip
                                        label={tag.name}
                                        key={tag.sfid}
                                        color="primary"
                                        className="mr-2 mb-2"
                                        onDelete={() => handleCheckedChange(tag)}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div className="text-center reward-card-mini-text text-muted">
                            {<IntlMessages id="reward.noTagging" />}
                        </div>
                    )}
                </div>

                <div className="py-3 px-4">
                    <label className="reward-card-mini-text">{<IntlMessages id="reward.availableTagging" />}</label>
                    <div>
                        {uncheckedList.map(tag => {
                            return (
                                <Chip
                                    key={tag.sfid}
                                    label={tag.name}
                                    className="mr-2 mb-2"
                                    onClick={() => handleCheckedChange(tag)}
                                />
                            );
                        })}
                    </div>
                </div>
            </CollapsibleCard>
        </div>
    );
};

Filter.propTypes = {
    intl: intlShape.isRequired,
};
export default injectIntl(Filter);
