import { combineReducers } from 'redux';
import { AuthReducer } from './AuthReducer';
import { SettingReducer } from './SettingReducer';
import { SidebarReducer } from './SidebarReducer';
import { FavoriteRewardReducer } from './FavoriteRewardReducer';
import { LoyalityPortalConfigReducer } from './LoyalityPortalConfigReducer';
import { LoyaltyPointHistoryReducer } from './LoyaltyPointHistoryReducer';
import { MemberReducer } from './MemberReducer';
import { RedemptionReducer } from './RedemptionReducer';
import { RewardReducer } from './RewardReducer';
import { TopFavoriteRewardReducer } from './TopFavoriteRewardReducer';
import { TierRuleReducer } from './TierRuleReducer';
import { MemberRegReducer } from './MemberRegReducer';
import { PromotionReducer } from './PromotionReducer';
import { DialogReducer } from './DialogReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    setting: SettingReducer,
    sidebar: SidebarReducer,
    favoriteReward: FavoriteRewardReducer,
    loyalityPortalConfig: LoyalityPortalConfigReducer,
    loyaltyPointHistory: LoyaltyPointHistoryReducer,
    member: MemberReducer,
    memberReg: MemberRegReducer,
    redemption: RedemptionReducer,
    reward: RewardReducer,
    topFavoriteReward: TopFavoriteRewardReducer,
    tierRule: TierRuleReducer,
    promotion: PromotionReducer,
    dialog: DialogReducer,
});

export default rootReducer;
