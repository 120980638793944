import { httpPost, httpGet, httpGetById, httpDeleteById } from 'lib/request';
import AppConfig from 'constants/AppConfig';

const getFavoriteReward = (params, opts) => {
    return httpGet('favorite_reward', params);
};

const postFavoriteReward = (data, opts) => {
    return httpPost('favorite_reward', data);
};

const deleteFavoriteReward = (id, opts) => {
    return httpDeleteById('favorite_reward', id);
};

const getLoyaltyPointHistory = (params, opts) => {
    return httpGet('loyalty_point_history', params);
};

const getLoyaltyPortal = (params, opts) => {
    return httpGet('loyalty_portal', params);
};

//member
const getChildAccount = (params, opts) => {
    return httpGet('member/child_account', params);
};

const getReferralHistory = (params, opts) => {
    return httpGet('member/referral_history', params);
};

const getYearlyResult = (params, opts) => {
    return httpGet('member/yearly_result', params);
};

//member registration
const getExistingMemberReg = (params, opts) => {
    return httpGet('memberreg/existingMemberReg', params);
};

const getExistingAccountRecord = (params, opts) => {
    return httpGet('memberreg/existingAccounts', params);
};

const postMemberRegForm = (params, opts) => {
    return httpPost('memberreg', params);
};

//redemption
const getRedemption = (params, opts) => {
    return httpGet('redemption', params);
};

const postRedemption = (data, opts) => {
    return httpPost('redemption', data);
};

//reward
const getReward = (params, opts) => {
    return httpGet('reward', params);
};

const getRewardById = (id, opts) => {
    return httpGetById('reward', id, { params: opts });
};

const getTopFavoriteReward = (params, opts) => {
    return httpGet('favorite_reward/top', params);
};

const getTierRule = (params, opts) => {
    return httpGet('tier_rule', params);
};

const getPromotionLuckyDraw = (params, opts) => {
    return httpGet('promotion/luckydraw', params);
}


const postPromotionLuckyDraw = (params, opts) => {
    return httpPost('promotion/luckydraw', params);
}

const BACKEND_URL = window.location.origin;
// const BACKEND_URL = 'http://127.0.0.1:8080';

// //ecrm
// const ecrmLogin = ({ accountEmail, entity }) => {
//     let isLoginCurrentMemberEmail = !accountEmail;
//     let getAccessTokenURL = isLoginCurrentMemberEmail
//         ? `${BACKEND_URL}/api/${AppConfig.backend.ver}/member/get_access_token?entity=${entity}`
//         : `${BACKEND_URL}/api/${AppConfig.backend.ver}/member/get_access_token?email=${accountEmail}`;

//     fetch(getAccessTokenURL)
//         .then(response => response.json())
//         .then(data => {
//             let accessToken = data.data;

//             const form = document.createElement('form');
//             form.method = 'POST';
//             form.target = 'newWindow';
//             form.action = `${BACKEND_URL}/api/${AppConfig.backend.ver}/member/redirect_ecrm`;

//             const token = document.createElement('input');
//             token.type = 'hidden';
//             token.name = 'accessToken';
//             token.value = accessToken;

//             form.appendChild(token);
//             document.body.appendChild(form);
//             window.open('', 'newWindow');
//             form.submit();
//         })
//         .catch(e => {
//             console.log(e);
//         });
// };

export {
    getFavoriteReward,
    postFavoriteReward,
    deleteFavoriteReward,
    getLoyaltyPointHistory,
    getLoyaltyPortal,
    getChildAccount,
    getReferralHistory,
    getYearlyResult,
    getExistingMemberReg,
    getExistingAccountRecord,
    postMemberRegForm,
    getRedemption,
    postRedemption,
    getReward,
    getRewardById,
    getTopFavoriteReward,
    getTierRule,
    getPromotionLuckyDraw,
    postPromotionLuckyDraw,
    // ecrmLogin,
};
