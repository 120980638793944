export const CONSTANT = {
    ENTITY_EBL: 'EBL',
    ENTITY_EIE: 'EIEHK',
    ENTITY_UK: 'EMXpro (UK)',
    ENTITY_SVG: 'EMXpro (SVG)',
    ENTITY_XPRO_MF4: 'XPRO_MF4',
    ENTITY_EBL_MF4: 'EBL_MF4',
    MEMBERREG_TERM_AND_CONDITION: 'https://www.empfs.com/rewards',
    MEMBERREG_PRIVACY: 'https://www.empfs.com/rewards',
    ENTITY_EBL_LOGIN: 'https://www.empfs.com/market/trading-platform',
};

export function getEntityName(entity) {
    switch (entity) {
        case CONSTANT.ENTITY_EBL:
            return 'EBL';
        case CONSTANT.ENTITY_EIE:
            return 'EIEHK';
        case CONSTANT.ENTITY_UK:
            return 'EMXpro (UK)';
        case CONSTANT.ENTITY_SVG:
            return 'EMXpro (SVG)';
        case CONSTANT.ENTITY_XPRO_MF4:
            return 'XPRO MF4';
        case CONSTANT.ENTITY_EBL_MF4:
            return 'EBL 2.0';
        default:
            return entity;
    }
}

/**
 * * 2024-05-23 
 * * Authored by: Homan Lui
 * * Add replaced entites logic by requirement
 * * to replace the entity 'EBL 2.0' as 'EBL'
 */
export const NEED_REPLACED_ENTITIES = {
    'EBL 2.0': 'EBL',
    'EBL_MF4': 'EBL',
};

// Reverse mapping of replaced entities
export const REVERSE_REPLACED_ENTITIES = Object.fromEntries(
    Object.entries(NEED_REPLACED_ENTITIES).map(([key, value]) => [value, key])
);

export function getReplacedEntities(entities) {
    let replacedEntites = entities.map(item => NEED_REPLACED_ENTITIES[item] || item);
    return [... new Set(replacedEntites)];
}

export function revertReplacedEntities(array) {
    // Step 1: Replace entities in the array based on REVERSE_REPLACED_ENTITIES mapping
    let revertedArray = array.map(item => 
        REVERSE_REPLACED_ENTITIES[item] || item
    );
    
    // Step 2: Remove duplicates (if necessary)
    return [...new Set(revertedArray)];
}