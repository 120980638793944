import { httpPost, httpGet } from 'lib/request';

const signinUser = (data, opts) => {
    return httpPost('user/login', data);
};

const logoutUser = () => {
    return httpGet('user/logout');
};

const signupUser = (data, opts) => {
    return httpPost('user/create-user', data);
};

const forgotUserPassword = (data, opts) => {
    return httpPost('user/forgot-password', data);
};

const forgetUserPasswordConfirmation = (data, opts) => {
    return httpPost('user/confirm-forgot-password', data);
};

const changeUserPassword = (data, opts) => {
    return httpPost('user/change-user-password', data);
};

const validateUserSession = (params, opts) => {
    return httpGet('user/validate', params);
};

const verifySSOLogin = (params, opts) => {
    return httpPost('user/SSO-login', params);
};

export {
    signupUser,
    logoutUser,
    signinUser,
    forgotUserPassword,
    forgetUserPasswordConfirmation,
    changeUserPassword,
    validateUserSession,
    verifySSOLogin,
};
