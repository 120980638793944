import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Form, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { injectIntl, intlShape } from 'react-intl';

import InjectMassage from 'util/IntlMessages';
import CustomInput from 'components/CustomInput';
import LanguageProvider from 'components/Header/LanguageProvider';

import SessionLayout from 'container/SessionLayout';

import { getLoading, getError } from 'reducers/AuthReducer';

import { signin, validateUserSessionLogin } from 'actions';

const Signin = ({ history, intl }) => {
    const dispatch = useDispatch();

    const { loading, error } = useSelector(state => ({
        loading: getLoading(state.auth),
        // user   : getUser(state.auth),
        error: getError(state.auth),
    }));

    const LoginSchema = yup.object().shape({
        'user-mail': yup
            .string()
            .required(intl.formatMessage({ id: 'input.emailRequired' }))
            .email(intl.formatMessage({ id: 'input.emailRestricted' })),
        'user-pwd': yup.string().required(intl.formatMessage({ id: 'input.passwordRequired' })),
    });

    useEffect(() => {
        // dispatch(validateUserSessionLogin(history));
    }, []);

    const [state] = useState({
        email: '',
        password: '',
    });

    const { control, handleSubmit, errors } = useForm({
        resolver: yupResolver(LoginSchema),
    });

    const emailPH = intl.formatMessage({ id: 'login.enterEmail' });
    const pwdPH = intl.formatMessage({ id: 'login.enterPassword' });

    const onUserLogin = data => {
        dispatch(signin({ username: data['user-mail'], password: data['user-pwd'] }, history));
    };

    return (
        <SessionLayout
            loading={loading}
            sessionHeaderRight={
                <div className="row justify-content-center align-self-center">
                    <LanguageProvider />
                </div>
            }
        >
            <div className="session-component-container w-100 shadow">
                <div className="session-body text-center h-100 px-5">
                    <div className="session-head my-4">
                        <h2 className="font-weight-bold">
                            <InjectMassage id="login.memberLogin" />
                        </h2>
                        {error &&
                            (typeof error === 'number' ? (
                                <span className="error-message">
                                    <InjectMassage id={`error.${error}`} />
                                </span>
                            ) : (
                                <span className="error-message">
                                    <InjectMassage id={'error.default'} />
                                </span>
                            ))}
                    </div>
                    <Form onSubmit={handleSubmit(onUserLogin)}>
                        <CustomInput
                            disabled={loading}
                            errors={errors}
                            control={control}
                            type="text"
                            defaultValue={state.email}
                            value={state.email}
                            name="user-mail"
                            id="user-mail"
                            className="has-input input-lg"
                            placeholder={emailPH}
                        />

                        <CustomInput
                            disabled={loading}
                            errors={errors}
                            control={control}
                            type="Password"
                            defaultValue={state.password}
                            value={state.password}
                            name="user-pwd"
                            id="user-pwd"
                            className="has-input input-lg"
                            placeholder={pwdPH}
                        />
                        <FormGroup>
                            <Button
                                disabled={loading}
                                color="primary"
                                className="btn-block text-white w-100"
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                <InjectMassage id="login.signIn" />
                            </Button>
                        </FormGroup>
                        <FormGroup className="mb-15 text-primary row justify-content-center align-center">
                                {/* {loading ? (
                                    <span className="text-primary">
                                        <InjectMassage id="memberreg.form.not_member" />
                                    </span>
                                ) : (
                                    <Link className="text-primary" to="/new-member-reg">
                                        <InjectMassage id="memberreg.form.not_member" />
                                    </Link>
                                )} */}
                            <div className="w-50">
                                {loading ? (
                                    <span className="text-primary">
                                        <InjectMassage id="login.forgotPassword" />
                                    </span>
                                ) : (
                                    <Link className="text-primary" to="/forgot-password">
                                        <InjectMassage id="login.forgotPassword" />
                                    </Link>
                                )}
                            </div>
                            <div className="w-50">
                            </div>
                        </FormGroup>
                    </Form>
                    <p className="text-muted mt-5 text-center">
                        <InjectMassage id="login.right" />
                    </p>
                </div>
            </div>
        </SessionLayout>
    );
};

Signin.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Signin);
