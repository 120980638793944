import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CountryList from 'constants/CountryList';
import CustomInputNew from 'components/CustomInputNew';
import CustomSelect from 'components/CustomSelect';
import { IconButton } from '@material-ui/core';
import InjectMassage from 'util/IntlMessages';
import { Add, Remove } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postRedemptionNew } from 'actions';
import { injectIntl } from 'react-intl';
import { getCurrentReward } from 'actions';
import { getCurrentRecord } from 'reducers/RewardReducer';
import { getLanguage } from 'reducers/SettingReducer';
import { getInAppEntity, getUser } from 'reducers/AuthReducer';
import { CONSTANT } from 'constants/constant';
import IntlMessages from 'util/IntlMessages';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const RedemptionSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
    addressLine1: yup.string().required('Address Line 1 is required'),
    addressLine2: yup.string().required('Address Line 2 is required'),
    city: yup.string().required('City is required'),
    stateRegion: yup.string().required('State / Region is required'),
});

const CheckoutMobile = ({ match, intl }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { control, errors, handleSubmit } = useForm({
        resolver: yupResolver(RedemptionSchema),
    });

    const { curReward, language, user, entityList } = useSelector(state => ({
        curReward: getCurrentRecord(state.reward),
        language: getLanguage(state.setting),
        user: getUser(state.auth),
        entityList: getInAppEntity(state.auth),
    }));

    const [loading, setLoading] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [isAbletoAdd, setIsAbleToAdd] = useState(true);

    const handleBack = () => {
        history.goBack();
    };

    const handleRedeem = data => {
        setLoading(true);

        let param = {
            rewardId: match.params.id,
            quantity: Number(quantity),
            name: data.name,
            phone: data.phone,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            stateRegion: data.stateRegion,
            country: data.country,
        };
        const orderedInfo = {
            name: curReward?.reward_item__c.name,
            qty: quantity,
        };

        dispatch(postRedemptionNew(param, orderedInfo, intl, history));

        setLoading(false);
    };

    const handleQuantity = type => {
        if (type === 'increment') {
            setQuantity(prev => prev + 1);
        } else {
            if (quantity > 1) {
                setQuantity(prev => prev - 1);
            }
        }
    };

    useEffect(() => {
        if (!curReward) {
            dispatch(getCurrentReward(match.params.id, { queryParam: { language: language.languageId } }));
        }
    }, [curReward, language]);

    useEffect(() => {
        const userPoint = user?.member.total_loyalty_point__c - user?.member.redeemed_loyalty_point__c;
        const itemPoint = curReward?.required_loyalty_point__c;

        if (itemPoint * (quantity + 1) > userPoint) {
            setIsAbleToAdd(false);
        } else {
            setIsAbleToAdd(true);
        }
    }, [quantity]);

    return (
        <div className="checkout-new-wrapper">
            {/* <HeaderNew /> */}
            <div className="checkout-shipping-form">
                <IntlMessages id="checkout.shippingForm" />
            </div>
            <div className="item-wrapper">
                <div className="item-name">{curReward?.reward_item__c.name}</div>
                <div className="item-code">
                    <InjectMassage id="reward.itemCode" /> :{' '}
                    <span className="code">{curReward?.reward_item__c.item_code__c}</span>
                </div>
            </div>
            <div className="form-wrapper">
                <CustomInputNew
                    disabled={loading}
                    errors={errors}
                    control={control}
                    type="text"
                    name="name"
                    label="checkout.username"
                    defaultValue=""
                    id="name"
                    className="has-input input-lg"
                    placeholder={intl.formatMessage({ id: 'checkout.placeholder.username' })}
                    required={true}
                />
                <CustomInputNew
                    disabled={loading}
                    errors={errors}
                    control={control}
                    type="number"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    name="phone"
                    label="checkout.phonenumber"
                    defaultValue=""
                    id="name"
                    className="has-input input-lg"
                    placeholder={intl.formatMessage({ id: 'checkout.placeholder.phoneNumber' })}
                    required={true}
                />
                <CustomSelect
                    disabled={loading}
                    errors={errors}
                    control={control}
                    label="checkout.country"
                    name="country"
                    defaultValue={entityList.includes(CONSTANT.ENTITY_EIE) || entityList.includes(CONSTANT.ENTITY_EBL_MF4) ? 'HKG' : 'CHN'}
                    id="country"
                    className="has-input input-lg"
                    required={true}
                    options={CountryList}
                    language={language}
                />
                <CustomInputNew
                    disabled={loading}
                    errors={errors}
                    control={control}
                    type="text"
                    label="checkout.addressLine1"
                    name="addressLine1"
                    defaultValue=""
                    id="addressLine1"
                    className="has-input input-lg"
                    placeholder={intl.formatMessage({ id: 'checkout.placeholder.addressLine1' })}
                    required={true}
                />
                <CustomInputNew
                    disabled={loading}
                    errors={errors}
                    control={control}
                    type="text"
                    label="checkout.addressLine2"
                    name="addressLine2"
                    defaultValue=""
                    id="addressLine2"
                    className="has-input input-lg"
                    placeholder={intl.formatMessage({ id: 'checkout.placeholder.addressLine2' })}
                    required={true}
                />
                <CustomInputNew
                    disabled={loading}
                    errors={errors}
                    control={control}
                    type="text"
                    label="checkout.city"
                    name="city"
                    defaultValue=""
                    id="city"
                    className="has-input input-lg"
                    placeholder={intl.formatMessage({ id: 'checkout.placeholder.city' })}
                    required={true}
                />
                <CustomInputNew
                    disabled={loading}
                    errors={errors}
                    control={control}
                    type="text"
                    label="checkout.stateRegion"
                    name="stateRegion"
                    defaultValue=""
                    id="stateRegion"
                    className="has-input input-lg"
                    placeholder={intl.formatMessage({ id: 'checkout.placeholder.stateRegion' })}
                    required={true}
                />
                <div className="d-flex justify-content-between align-items-center">
                    <div className="label">
                        <InjectMassage id="checkout.quantity" />*
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ background: '#FFFFFF', borderRadius: '32px' }}
                    >
                        <IconButton onClick={() => handleQuantity('decrement')}>
                            <Remove style={{ color: quantity > 1 ? '#00B1D2' : '#CACACA' }} />
                        </IconButton>
                        <div>{quantity}</div>
                        <IconButton onClick={() => isAbletoAdd && handleQuantity('increment')}>
                            <Add style={{ color: isAbletoAdd ? '#00B1D2' : '#CACACA' }} />
                        </IconButton>
                    </div>
                </div>
                <div className="actions">
                    <Button className="back-button" fullWidth onClick={handleBack}>
                        <InjectMassage id="reward.back" />
                    </Button>
                    <Button
                        className={'redeem-button'}
                        disabled={loading}
                        fullWidth
                        onClick={handleSubmit(handleRedeem)}
                    >
                        <InjectMassage id="reward.confirm" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default injectIntl(CheckoutMobile);
