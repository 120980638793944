import React, { useState, Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { useDispatch } from 'react-redux';

import IntlMessages from 'util/IntlMessages';

import { collapsedSidebarAction, selectedSidebarAction } from 'actions';

const NavMenuItem = ({ menu, onToggleMenu, winState, index }) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        subMenuOpen: '',
    });

    const onToggleCollapseMenu = index => {
        if (state.subMenuOpen === '') {
            setState({
                ...state,
                subMenuOpen: index,
            });
        } else if (state.subMenuOpen !== index) {
            setState({
                ...state,
                subMenuOpen: index,
            });
        } else {
            setState({ ...state, subMenuOpen: '' });
        }
    };

    const handleOnClick = e => {
        dispatch(selectedSidebarAction(index));
        if (winState.windowWidth <= 1199) {
            dispatch(collapsedSidebarAction(false));
        }
    };

    const { subMenuOpen } = state;
    if (menu.child_routes != null) {
        return (
            <Fragment>
                <ListItem
                    button
                    component="li"
                    onClick={onToggleMenu}
                    className={`list-item ${classNames({ 'item-active': menu.open })}`}
                >
                    <ListItemIcon className="menu-icon">
                        <i className={menu.menu_icon}></i>
                    </ListItemIcon>
                    <span className="menu text-capitalize">
                        <IntlMessages id={menu.menu_title} />
                    </span>
                </ListItem>
                <Collapse in={menu.open} timeout="auto" className="sub-menu">
                    <Fragment>
                        {menu.type_multi == null ? (
                            <List className="list-unstyled py-0">
                                {menu.child_routes.map((subMenu, index) => {
                                    return (
                                        <ListItem button component="li" key={index}>
                                            <NavLink to={subMenu.path} activeClassName="item-active">
                                                <span className="menu">
                                                    <IntlMessages id={subMenu.menu_title} />
                                                </span>
                                                {subMenu.new_item && subMenu.new_item === true ? (
                                                    <Chip label="new" className="new-item" color="secondary" />
                                                ) : (
                                                    ''
                                                )}
                                            </NavLink>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        ) : (
                            <List className="list-unstyled py-0">
                                {menu.child_routes.map((subMenu, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <ListItem
                                                button
                                                component="li"
                                                onClick={() => onToggleCollapseMenu(index)}
                                                className={`list-item ${classNames({
                                                    'item-active': subMenuOpen === index,
                                                })}`}
                                            >
                                                <span className="menu">
                                                    <IntlMessages id={subMenu.menu_title} />
                                                    {menu.new_item && menu.new_item === true ? (
                                                        <Chip label="new" className="new-item" color="secondary" />
                                                    ) : null}
                                                </span>
                                            </ListItem>
                                            <Collapse in={subMenuOpen === index} timeout="auto">
                                                <List className="list-unstyled py-0">
                                                    {subMenu.child_routes.map((nestedMenu, nestedKey) => (
                                                        <ListItem button component="li" key={nestedKey}>
                                                            <NavLink activeClassName="item-active" to={nestedMenu}>
                                                                <span className="menu pl-10 d-inline-block">
                                                                    <IntlMessages id={nestedMenu.menu_title} />
                                                                    {menu.new_item && menu.new_item === true ? (
                                                                        <Chip
                                                                            label="new"
                                                                            className="new-item"
                                                                            color="secondary"
                                                                        />
                                                                    ) : null}
                                                                </span>
                                                            </NavLink>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        </Fragment>
                                    );
                                })}
                            </List>
                        )}
                    </Fragment>
                </Collapse>
            </Fragment>
        );
    }
    return (
        <ListItem selected={menu.selected} button component="li" className="p-3">
            <NavLink onClick={e => handleOnClick(e)} activeClassName="item-active" to={menu.path}>
                <ListItemIcon className="menu-icon p-2">
                    <i className={menu.menu_icon}></i>
                </ListItemIcon>
                <span className="menu">
                    <IntlMessages id={menu.menu_title} />
                </span>
            </NavLink>
        </ListItem>
    );
};

export default NavMenuItem;
