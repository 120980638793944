import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';

import AppConfig from 'constants/AppConfig';

import UserBlock from './UserBlock';
import SidebarContent from './SidebarContent';

const Sidebar = React.memo(
    () => {
        const [state, setState] = useState({
            windowWidth: 0,
            windowHeight: 0,
        });

        // only render once
        useEffect(() => {
            const updateDimensions = () => {
                setState({ ...state, windowWidth: window.innerWidth, windowHeight: window.innerHeight });
            };

            window.addEventListener('resize', updateDimensions);
            return () => {
                window.removeEventListener('resize', updateDimensions);
            };
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        return (
            <Fragment>
                <div className="rct-sidebar" style={{ backgroundImage: `url(${AppConfig.sidebarBgImage})` }}>
                    <div className="rct-sidebar-content sidebar-overlay-light">
                        <div className="site-logo justify-content-center">
                            <Link to="/">
                                <img
                                    src={AppConfig.appLogo}
                                    alt="session-logo"
                                    className="img-fluid"
                                    width="120"
                                />
                            </Link>
                        </div>
                        <div className="rct-sidebar-wrap">
                            <Scrollbars
                                className="rct-scroll"
                                autoHide
                                autoHideDuration={100}
                                style={{ height: 'calc(90vh - 60px)' }}
                            >
                                <UserBlock />
                                <SidebarContent winState={state} />
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    },
    (prevProps, nextProps) => {
        const { locale } = prevProps;
        if (!locale) {
            return true;
        } else {
            return false;
        }
    }
);

export default withRouter(Sidebar);
