import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'components/Card';
import { useHistory } from 'react-router-dom';
import TabPanel from 'components/Widgets/TabPanel';
import { AppBar, Tabs, Tab, Button } from '@material-ui/core';
import * as _ from 'lodash';
import Zoom from 'react-medium-image-zoom';
import ImageGallery from 'react-image-gallery';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';

import { getCurrentRecord } from 'reducers/RewardReducer';

import IntlMessages from 'util/IntlMessages';

import { getLanguage } from 'reducers/SettingReducer';

import { getCurrentReward } from 'actions';

const AwardDetail = ({ match, location }) => {
    const imageGalleryRef = useRef(null);

    const history = useHistory();
    const dispatch = useDispatch();

    const { curReward, language } = useSelector(state => ({
        curReward: getCurrentRecord(state.reward),
        language: getLanguage(state.setting),
    }));

    const [tabValue, setTabValue] = React.useState(0);

    useEffect(() => {
        dispatch(getCurrentReward(match.params.id, { queryParam: { language: language.languageId } }));
        if (imageGalleryRef.current) {
            imageGalleryRef.current.slideToIndex(0);
        }
    }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

    const [imageSet, setImageSet] = useState([]);

    useEffect(() => {
        let tmpImageSet = [];

        if (curReward) {
            if (_.get(curReward, 'reward_item__c.image_path__c')) {
                tmpImageSet.push({
                    original: _.get(curReward, 'reward_item__c.image_path__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.image_path__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.sub_image_path_1__c')) {
                tmpImageSet.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_1__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_1__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.sub_image_path_2__c')) {
                tmpImageSet.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_2__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_2__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.sub_image_path_3__c')) {
                tmpImageSet.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_3__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_3__c'),
                });
            }

            setImageSet(tmpImageSet);
        }
    }, [curReward]);

    const imageGalleryRenderer = item => {
        return (
            <div>
                <Zoom>
                    <img src={item.original} alt={item.originalAlt} width="300" height="300" />
                </Zoom>
            </div>
        );
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleRedempt = () => {
        history.push({
            pathname: location.pathname + '/checkout',
        });
    };

    return (
        <div className="reward-wrapper">
            {!_.isEmpty(curReward) && (
                <div className="ml-lg-3 mt-3">
                    <Card customClass="h-100 shadow">
                        <div className="row row-eq-height justify-content-center py-5">
                            <div className="col-sm-6 col-md-6 col-lg-5">
                                <ImageGallery
                                    ref={imageGalleryRef}
                                    items={imageSet}
                                    thumbnailPosition="right"
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    showNav={false}
                                    renderItem={imageGalleryRenderer}
                                ></ImageGallery>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-5">
                                <div className="px-4">
                                    <span className="reward-card-subtitle">
                                        {_.get(curReward, 'reward_item__c.brand_name__c')}
                                    </span>
                                </div>
                                <div className="px-4">
                                    <span className="reward-card-title">{_.get(curReward, 'reward_item__c.name')}</span>
                                </div>
                                <div className="pt-2 px-4">
                                    <span className="text-muted reward-card-text-xs">
                                        {<IntlMessages id="reward.itemCode" />} :{' '}
                                        {_.get(curReward, 'reward_item__c.item_code__c')}
                                    </span>
                                </div>
                                <div className="py-2 px-4">
                                    <span className="text-muted reward-card-text-xs">
                                        {<IntlMessages id="reward.itemDescription" />} :
                                        <br />
                                        <Typography
                                            component={'span'}
                                            display="inline"
                                            style={{ whiteSpace: 'pre-line' }}
                                        >
                                            <span className="text-muted reward-card-text-xs">
                                                {_.get(curReward, 'reward_item__c.item_description__c')}
                                            </span>
                                        </Typography>
                                    </span>
                                </div>
                                <hr className="mx-4 mb-2" />
                                <div className="mb-2 px-4 text-right">
                                    <span className="reward-card-title">
                                        <i className="zmdi zmdi-toll"></i>
                                        &nbsp;
                                        <NumberFormat
                                            value={_.get(curReward, 'required_loyalty_point__c')}
                                            thousandSeparator={true}
                                            displayType={'text'}
                                        ></NumberFormat>
                                        {/* {<IntlMessages id="reward.points" />} */}
                                    </span>
                                </div>
                                <div className="py-2 px-4 align-self-center">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleRedempt}
                                        className="w-100"
                                    >
                                        {<IntlMessages id="reward.redeem" />}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <div className="py-3">
                        <Card customClass="h-100 shadow">
                            <AppBar position="static" variant="elevation" elevation={0}>
                                <Tabs variant="scrollable" value={tabValue} onChange={handleTabChange}>
                                    <Tab id="itemRemark" label={<IntlMessages id="reward.itemRemark" />} />
                                    <Tab id="shippingInfo" label={<IntlMessages id="reward.shippingInfo" />} />
                                    <Tab id="itemTC" label={<IntlMessages id="reward.itemTC" />} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={tabValue} index={0} className="reward-tab-container">
                                <span>
                                    <Typography component={'span'} display="inline" style={{ whiteSpace: 'pre-line' }}>
                                        <span className="text-muted reward-card-text-xs">
                                            {_.get(curReward, 'reward_item__c.item_remark__c') ? (
                                                _.get(curReward, 'reward_item__c.item_remark__c')
                                            ) : (
                                                <IntlMessages id="reward.noInformation" />
                                            )}
                                        </span>
                                    </Typography>
                                </span>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1} className="reward-tab-container">
                                <span>
                                    <Typography component={'span'} display="inline" style={{ whiteSpace: 'pre-line' }}>
                                        <span className="text-muted reward-card-text-xs">
                                            {_.get(curReward, 'reward_item__c.shipping_information__c') ? (
                                                _.get(curReward, 'reward_item__c.shipping_information__c')
                                            ) : (
                                                <IntlMessages id="reward.noInformation" />
                                            )}
                                        </span>
                                    </Typography>
                                </span>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2} className="reward-tab-container">
                                <span>
                                    <Typography component={'span'} display="inline" style={{ whiteSpace: 'pre-line' }}>
                                        <span className="text-muted reward-card-text-xs">
                                            {_.get(curReward, 'reward_item__c.terms_conditions__c') ? (
                                                _.get(curReward, 'reward_item__c.terms_conditions__c')
                                            ) : (
                                                <IntlMessages id="reward.noInformation" />
                                            )}
                                        </span>
                                    </Typography>
                                </span>
                            </TabPanel>
                        </Card>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AwardDetail;
