const CountryList = [
    {
        EN: 'United Arab Emirates',
        SC: '阿拉伯联合大公国',
        TC: '阿拉伯聯合大公國',
        value: 'ARE',
        code: 971,
    },
    {
        EN: 'Benin',
        SC: '贝宁',
        TC: '貝南',
        value: 'BEN',
        code: 229,
    },
    {
        EN: 'Bahrain',
        SC: '巴林',
        TC: '巴林',
        value: 'BHR',
        code: 973,
    },
    {
        EN: 'Bahamas',
        SC: '巴哈马',
        TC: '巴哈馬',
        value: 'BHS',
        code: '1-242',
    },
    {
        EN: 'Belize',
        SC: '贝里斯',
        TC: '貝裡斯',
        value: 'BLZ',
        code: 501,
    },
    {
        EN: 'Bolivia',
        SC: '玻利维亚',
        TC: '玻利維亞',
        value: 'BOL',
        code: 591,
    },
    {
        EN: 'Cape Verde',
        SC: '维德角',
        TC: '維德角',
        value: 'CAV',
        code: 238,
    },
    {
        EN: 'Colombia',
        SC: '哥伦比亚',
        TC: '哥倫比亞',
        value: 'COL',
        code: 57,
    },
    {
        EN: 'Costa Rica',
        SC: '哥斯达黎加',
        TC: '哥斯達黎加',
        value: 'CRI',
        code: 506,
    },
    {
        EN: 'Algeria',
        SC: '阿尔及利亚',
        TC: '阿爾及利亞',
        value: 'DZA',
        code: 213,
    },
    {
        EN: 'Ecuador',
        SC: '厄瓜多尔',
        TC: '厄瓜多爾',
        value: 'ECU',
        code: 593,
    },
    {
        EN: 'Ghana',
        SC: '加纳',
        TC: '加納',
        value: 'GHA',
        code: 233,
    },
    {
        EN: 'Gibraltar',
        SC: '直布罗陀',
        TC: '直布羅陀',
        value: 'GIB',
        code: 350,
    },
    {
        EN: 'Gambia',
        SC: '甘比亚',
        TC: '甘比亞',
        value: 'GMB',
        code: 220,
    },
    {
        EN: 'Guinea-Bissau',
        SC: '几内亚比绍',
        TC: '幾內亞比紹',
        value: 'GNB',
        code: 245,
    },
    {
        EN: 'Guyana',
        SC: '圭亚那',
        TC: '圭亞那',
        value: 'GUY',
        code: 592,
    },
    {
        EN: 'Indonesia',
        SC: '印度尼西亚',
        TC: '印度尼西亞',
        value: 'IDN',
        code: 62,
    },
    {
        EN: 'Jordan',
        SC: '约旦',
        TC: '約旦',
        value: 'JOR',
        code: 962,
    },
    {
        EN: 'Kenya',
        SC: '肯亚',
        TC: '肯亞',
        value: 'KEN',
        code: 254,
    },
    {
        EN: 'Laos',
        SC: '老挝',
        TC: '老撾',
        value: 'LAO',
        code: 856,
    },
    {
        EN: 'Liberia',
        SC: '赖比瑞亚',
        TC: '賴比瑞亞',
        value: 'LBR',
        code: 231,
    },
    {
        EN: 'Liechtenstein',
        SC: '列支敦斯登',
        TC: '列支敦斯登',
        value: 'LIE',
        code: 423,
    },
    {
        EN: 'Mexico',
        SC: '墨西哥',
        TC: '墨西哥',
        value: 'MEX',
        code: 52,
    },
    {
        EN: 'Mali',
        SC: '马里',
        TC: '馬里',
        value: 'MLI',
        code: 223,
    },
    {
        EN: 'Malaysia',
        SC: '马来西亚',
        TC: '馬來西亞',
        value: 'MYS',
        code: 60,
    },
    {
        EN: 'Niger',
        SC: '尼日',
        TC: '尼日',
        value: 'NER',
        code: 227,
    },
    {
        EN: 'Nigeria',
        SC: '奈及利亚',
        TC: '奈及利亞',
        value: 'NGA',
        code: 234,
    },
    {
        EN: 'Panama',
        SC: '巴拿马',
        TC: '巴拿馬',
        value: 'PAN',
        code: 507,
    },
    {
        EN: 'Philippines',
        SC: '菲律宾',
        TC: '菲律賓',
        value: 'PHL',
        code: 63,
    },
    {
        EN: 'Papua New Guinea',
        SC: '巴布亚新几内亚',
        TC: '巴布亞紐幾內亞',
        value: 'PNG',
        code: 675,
    },
    {
        EN: 'Palestine',
        SC: '巴勒斯坦',
        TC: '巴勒斯坦',
        value: 'PSE',
        code: 970,
    },
    {
        EN: 'Senegal',
        SC: '塞内加尔',
        TC: '塞內加爾',
        value: 'SEN',
        code: 221,
    },
    {
        EN: 'Sierra Leone',
        SC: '狮子山',
        TC: '獅子山',
        value: 'SLE',
        code: 232,
    },
    {
        EN: 'San Marino',
        SC: '圣马利诺',
        TC: '聖馬利諾',
        value: 'SMR',
        code: 378,
    },
    {
        EN: 'Togo',
        SC: '多哥',
        TC: '多哥',
        value: 'TGO',
        code: 228,
    },
    {
        EN: 'Uganda',
        SC: '乌干达',
        TC: '烏干達',
        value: 'UGA',
        code: 256,
    },
    {
        EN: 'Aruba',
        SC: '阿鲁巴',
        TC: '阿魯巴',
        value: 'ABW',
        code: 297,
    },
    {
        EN: 'Anguilla',
        SC: '安圭拉',
        TC: '安圭拉',
        value: 'AIA',
        code: '1-264',
    },
    {
        EN: 'Albania',
        SC: '阿尔巴尼亚',
        TC: '阿爾巴尼亞',
        value: 'ALB',
        code: 355,
    },
    {
        EN: 'Andorra',
        SC: '安道尔',
        TC: '安道爾',
        value: 'AND',
        code: 376,
    },
    {
        EN: 'Argentina',
        SC: '阿根廷',
        TC: '阿根廷',
        value: 'ARG',
        code: 54,
    },
    {
        EN: 'Armenia',
        SC: '亚美尼亚',
        TC: '亞美尼亞',
        value: 'ARM',
        code: 374,
    },
    {
        EN: 'American Samoa',
        SC: '美属萨摩亚',
        TC: '美屬薩摩亞',
        value: 'ASM',
        code: 1,
    },
    {
        EN: 'Antaractica',
        SC: '南极洲',
        TC: '南極洲',
        value: 'ATA',
        code: 672,
    },
    {
        EN: 'French Southern Territories',
        SC: '法属南部领地',
        TC: '法屬南部領地',
        value: 'ATF',
        code: 262,
    },
    {
        EN: 'Antigua And Barbuda',
        SC: '安提瓜和巴布达',
        TC: '安提瓜和巴布達',
        value: 'ATG',
        code: '1-268',
    },
    {
        EN: 'Australia',
        SC: '澳大利亚',
        TC: '澳大利亞',
        value: 'AUS',
        code: 61,
    },
    {
        EN: 'Austria',
        SC: '奥地利',
        TC: '奧地利',
        value: 'AUT',
        code: 43,
    },
    {
        EN: 'Azerbaijan',
        SC: '阿塞拜疆',
        TC: '阿塞拜疆',
        value: 'AZE',
        code: 994,
    },
    {
        EN: 'Burundi',
        SC: '布隆迪',
        TC: '布隆迪',
        value: 'BDI',
        code: 257,
    },
    {
        EN: 'Belgium',
        SC: '比利时',
        TC: '比利時',
        value: 'BEL',
        code: 32,
    },
    {
        EN: 'Burkina',
        SC: '布基纳',
        TC: '布基納',
        value: 'BFA',
        code: 226,
    },
    {
        EN: 'Bangladesh',
        SC: '孟加拉',
        TC: '孟加拉',
        value: 'BGD',
        code: 880,
    },
    {
        EN: 'Bulgaria',
        SC: '保加利亚',
        TC: '保加利亞',
        value: 'BGR',
        code: 359,
    },
    {
        EN: 'Bosnia and Herzegovina',
        SC: '波斯尼亚和黑塞哥维那',
        TC: '波斯尼亞和黑塞哥维那',
        value: 'BIH',
        code: 387,
    },
    {
        EN: 'Saint Barthelemy',
        SC: '圣巴瑟米',
        TC: '聖巴瑟米',
        value: 'BLM',
        code: 590,
    },
    {
        EN: 'Belarus',
        SC: '白俄罗斯',
        TC: '白俄羅斯',
        value: 'BLR',
        code: 375,
    },
    {
        EN: 'Bermuda',
        SC: '百慕达',
        TC: '百慕達',
        value: 'BMU',
        code: 1,
    },
    {
        EN: 'Brazil',
        SC: '巴西',
        TC: '巴西',
        value: 'BRA',
        code: 55,
    },
    {
        EN: 'Barbados',
        SC: '巴巴多斯',
        TC: '巴巴多斯',
        value: 'BRB',
        code: '1-246',
    },
    {
        EN: 'Brunei',
        SC: '文莱',
        TC: '文萊',
        value: 'BRN',
        code: 673,
    },
    {
        EN: 'Bhutan',
        SC: '不丹',
        TC: '不丹',
        value: 'BTN',
        code: 975,
    },
    {
        EN: 'Bouvet Island',
        SC: '布威岛',
        TC: '布威島',
        value: 'BVT',
        code: 47,
    },
    {
        EN: 'Botswana',
        SC: '博茨瓦纳',
        TC: '博茨瓦納',
        value: 'BWA',
        code: 267,
    },
    {
        EN: 'Canada',
        SC: '加拿大',
        TC: '加拿大',
        value: 'CAN',
        code: 1,
    },
    {
        EN: 'Cocos (Keeling) Islands',
        SC: '科科斯（基林）群岛',
        TC: '科科斯（基林）群島',
        value: 'CCK',
        code: 61,
    },
    {
        EN: 'Switzerland',
        SC: '瑞士',
        TC: '瑞士',
        value: 'CHE',
        code: 41,
    },
    {
        EN: 'Chile',
        SC: '智利',
        TC: '智利',
        value: 'CHL',
        code: 56,
    },
    {
        EN: 'Mainland China',
        SC: '中国',
        TC: '中國',
        value: 'CHN',
        code: 86,
    },
    {
        EN: 'Cameroon',
        SC: '喀麦隆',
        TC: '喀麥隆',
        value: 'CMR',
        code: 237,
    },
    {
        EN: 'Cook Islands',
        SC: '库克群岛',
        TC: '庫克群島',
        value: 'COK',
        code: 682,
    },
    {
        EN: 'Comoros',
        SC: '葛摩',
        TC: '葛摩',
        value: 'COM',
        code: 269,
    },
    {
        EN: 'Christmas Island',
        SC: '圣诞岛',
        TC: '聖誕島',
        value: 'CXR',
        code: 61,
    },
    {
        EN: 'Cayman Islands',
        SC: '开曼群岛',
        TC: '開曼群島',
        value: 'CYM',
        code: '1-345',
    },
    {
        EN: 'Cyprus',
        SC: '塞浦路斯',
        TC: '塞浦路斯',
        value: 'CYP',
        code: 357,
    },
    {
        EN: 'Czech Republic',
        SC: '捷克共和国',
        TC: '捷克共和國',
        value: 'CZE',
        code: 420,
    },
    {
        EN: 'Germany',
        SC: '德国',
        TC: '德國',
        value: 'DEU',
        code: 49,
    },
    {
        EN: 'Djibouti',
        SC: '吉布提',
        TC: '吉布提',
        value: 'DJI',
        code: 253,
    },
    {
        EN: 'Dominica',
        SC: '多米尼加',
        TC: '多米尼加',
        value: 'DMA',
        code: '1-767',
    },
    {
        EN: 'Denmark',
        SC: '丹麦',
        TC: '丹麥',
        value: 'DNK',
        code: 45,
    },
    {
        EN: 'Dominican Republic',
        SC: '多米尼加共和国',
        TC: '多明尼加共和國',
        value: 'DOM',
        code: '1-809, 1-829, 1-849',
    },
    {
        EN: 'Western Sahara',
        SC: '西撒哈拉',
        TC: '西撒哈拉',
        value: 'ESH',
        code: 212,
    },
    {
        EN: 'Spain',
        SC: '西班牙',
        TC: '西班牙',
        value: 'ESP',
        code: 34,
    },
    {
        EN: 'Estonia',
        SC: '爱沙尼亚',
        TC: '愛沙尼亞',
        value: 'EST',
        code: 372,
    },
    {
        EN: 'Ethiopia',
        SC: '衣索比亚',
        TC: '衣索比亞',
        value: 'ETH',
        code: 251,
    },
    {
        EN: 'Finland',
        SC: '芬兰',
        TC: '芬蘭',
        value: 'FIN',
        code: 358,
    },
    {
        EN: 'Fiji',
        SC: '斐济',
        TC: '斐濟',
        value: 'FJI',
        code: 679,
    },
    {
        EN: 'Falkland Islands',
        SC: '福克兰群岛',
        TC: '福克蘭群島',
        value: 'FLK',
        code: 500,
    },
    {
        EN: 'France',
        SC: '法国',
        TC: '法國',
        value: 'FRA',
        code: 33,
    },
    {
        EN: 'France, Metropolitan',
        SC: '法国大都会',
        TC: '法國大都會',
        value: 'FRM',
        code: 33,
    },
    {
        EN: 'Faeroe Islands',
        SC: '法罗群岛',
        TC: '法羅群島',
        value: 'FRO',
        code: 298,
    },
    {
        EN: 'Micronesia',
        SC: '密克罗尼西亚',
        TC: '密克羅尼西亞',
        value: 'FSM',
        code: 691,
    },
    {
        EN: 'Gabon',
        SC: '加彭',
        TC: '加彭',
        value: 'GAB',
        code: 241,
    },
    {
        EN: 'United Kingdom',
        SC: '英国',
        TC: '英國',
        value: 'GBR',
        code: 44,
    },
    {
        EN: 'Georgia',
        SC: '乔治亚',
        TC: '喬治亞',
        value: 'GEO',
        code: 995,
    },
    {
        EN: 'Guernsey',
        SC: '根西岛',
        TC: '根西島',
        value: 'GGY',
        code: '44-1481',
    },
    {
        EN: 'Guadeloupe',
        SC: '瓜德罗普岛',
        TC: '瓜德羅普島',
        value: 'GLP',
        code: 590,
    },
    {
        EN: 'Greece',
        SC: '希腊',
        TC: '希臘',
        value: 'GRC',
        code: 30,
    },
    {
        EN: 'Grenada',
        SC: '格瑞那达',
        TC: '格瑞那達',
        value: 'GRD',
        code: '1-473',
    },
    {
        EN: 'Greenland',
        SC: '格陵兰',
        TC: '格陵蘭',
        value: 'GRL',
        code: 299,
    },
    {
        EN: 'Guatemala',
        SC: '危地马拉',
        TC: '危地馬拉',
        value: 'GTM',
        code: 502,
    },
    {
        EN: 'French Guiana',
        SC: '法属圭亚那',
        TC: '法屬圭亞那',
        value: 'GUF',
        code: 594,
    },
    {
        EN: 'Guam',
        SC: '关岛',
        TC: '關島',
        value: 'GUM',
        code: '1-671',
    },
    {
        EN: 'Hong Kong, China',
        SC: '香港, 中国',
        TC: '香港, 中國',
        value: 'HKG',
        code: 852,
    },
    {
        EN: 'Heard and Mc Donald Islands',
        SC: '赫德岛和麦克唐纳群岛',
        TC: '赫德島和麥克唐納群島',
        value: 'HMD',
        code: 672,
    },
    {
        EN: 'Honduras',
        SC: '宏都拉斯',
        TC: '宏都拉斯',
        value: 'HND',
        code: 504,
    },
    {
        EN: 'Croatia (Hrvatska)',
        SC: '克罗地亚',
        TC: '克羅地亞',
        value: 'HRV',
        code: 385,
    },
    {
        EN: 'Hungary',
        SC: '匈牙利',
        TC: '匈牙利',
        value: 'HUN',
        code: 36,
    },
    {
        EN: 'Isle of Man',
        SC: '马恩岛',
        TC: '馬恩島',
        value: 'IMN',
        code: '44-1624',
    },
    {
        EN: 'India',
        SC: '印度',
        TC: '印度',
        value: 'IND',
        code: 91,
    },
    {
        EN: 'British Indian Ocean Territory',
        SC: '英属印度洋领地',
        TC: '英屬印度洋領地',
        value: 'IOT',
        code: 246,
    },
    {
        EN: 'Ireland',
        SC: '爱尔兰',
        TC: '愛爾蘭',
        value: 'IRL',
        code: 353,
    },
    {
        EN: 'Iceland',
        SC: '冰岛',
        TC: '冰島',
        value: 'ISL',
        code: 354,
    },
    {
        EN: 'Israel',
        SC: '以色列',
        TC: '以色列',
        value: 'ISR',
        code: 972,
    },
    {
        EN: 'Italy',
        SC: '意大利',
        TC: '義大利',
        value: 'ITA',
        code: 39,
    },
    {
        EN: 'Jamaica',
        SC: '牙买加',
        TC: '牙買加',
        value: 'JAM',
        code: '1-876',
    },
    {
        EN: 'Jersey',
        SC: '泽西岛',
        TC: '澤西島',
        value: 'JEY',
        code: '44-1534',
    },
    {
        EN: 'Japan',
        SC: '日本',
        TC: '日本',
        value: 'JPN',
        code: 81,
    },
    {
        EN: 'Kazakhstan',
        SC: '哈萨克斯坦',
        TC: '哈薩克斯坦',
        value: 'KAZ',
        code: 7,
    },
    {
        EN: 'Kyrgyzstan',
        SC: '吉尔吉斯斯坦',
        TC: '吉爾吉斯斯坦',
        value: 'KGZ',
        code: 996,
    },
    {
        EN: 'Kiribati',
        SC: '吉里巴斯',
        TC: '吉裡巴斯',
        value: 'KIR',
        code: 686,
    },
    {
        EN: 'Saint Kitts and Nevis',
        SC: '圣基茨和尼维斯',
        TC: '聖基茨和尼維斯',
        value: 'KNA',
        code: '1-869',
    },
    {
        EN: 'Korea, Republic of',
        SC: '韩国',
        TC: '韓國',
        value: 'KOR',
        code: 82,
    },
    {
        EN: 'Kosovo',
        SC: '科索沃',
        TC: '科索沃',
        value: 'KOS',
        code: 383,
    },
    {
        EN: 'Kuwait',
        SC: '科威特',
        TC: '科威特',
        value: 'KWT',
        code: 965,
    },
    {
        EN: 'Saint Lucia',
        SC: '圣卢西亚',
        TC: '聖露西亞',
        value: 'LCA',
        code: '1-758',
    },
    {
        EN: 'Sri Lanka',
        SC: '斯里兰卡',
        TC: '斯里蘭卡',
        value: 'LKA',
        code: 94,
    },
    {
        EN: 'Lesotho',
        SC: '赖索托',
        TC: '賴索托',
        value: 'LSO',
        code: 266,
    },
    {
        EN: 'Lithuania',
        SC: '立陶宛',
        TC: '立陶宛',
        value: 'LTU',
        code: 370,
    },
    {
        EN: 'Luxembourg',
        SC: '卢森堡',
        TC: '盧森堡',
        value: 'LUX',
        code: 352,
    },
    {
        EN: 'Latvia',
        SC: '拉脱维亚',
        TC: '拉脫維亞',
        value: 'LVA',
        code: 371,
    },
    {
        EN: 'Macao, China',
        SC: '澳门, 中国',
        TC: '澳門, 中國',
        value: 'MAC',
        code: 853,
    },
    {
        EN: 'Morocco',
        SC: '摩纳哥',
        TC: '摩納哥',
        value: 'MAR',
        code: 212,
    },
    {
        EN: 'Monaco',
        SC: '摩纳哥',
        TC: '摩納哥',
        value: 'MCO',
        code: 377,
    },
    {
        EN: 'Moldova',
        SC: '摩尔多瓦',
        TC: '摩爾多瓦',
        value: 'MDA',
        code: 373,
    },
    {
        EN: 'Madagascar',
        SC: '马达加斯加',
        TC: '馬達加斯加',
        value: 'MDG',
        code: 261,
    },
    {
        EN: 'Maldives',
        SC: '马尔地夫',
        TC: '瑪律地夫',
        value: 'MDV',
        code: 960,
    },
    {
        EN: 'Marshall Islands',
        SC: '马绍尔群岛',
        TC: '馬紹爾群島',
        value: 'MHL',
        code: 692,
    },
    {
        EN: 'Macedonia',
        SC: '马其顿',
        TC: '馬其頓',
        value: 'MKD',
        code: 389,
    },
    {
        EN: 'Malta',
        SC: '马耳他',
        TC: '馬爾他',
        value: 'MLT',
        code: 356,
    },
    {
        EN: 'Montenegro',
        SC: '蒙特内哥罗',
        TC: '蒙特內哥羅',
        value: 'MNE',
        code: 382,
    },
    {
        EN: 'Mongolia',
        SC: '蒙古',
        TC: '蒙古',
        value: 'MNG',
        code: 976,
    },
    {
        EN: 'Northern Mariana Islands',
        SC: '北马里亚纳群岛',
        TC: '北馬里安納群島',
        value: 'MNP',
        code: '1-670',
    },
    {
        EN: 'Mozambique',
        SC: '莫桑比克',
        TC: '莫桑比克',
        value: 'MOZ',
        code: 258,
    },
    {
        EN: 'Mauritania',
        SC: '毛里塔尼亚',
        TC: '毛里塔尼亞',
        value: 'MRT',
        code: 222,
    },
    {
        EN: 'Montserrat',
        SC: '蒙特塞拉特',
        TC: '蒙瑟拉特島',
        value: 'MSR',
        code: '1-664',
    },
    {
        EN: 'Martinique',
        SC: '马丁尼克岛',
        TC: '馬丁尼克島',
        value: 'MTQ',
        code: 596,
    },
    {
        EN: 'Mauritius',
        SC: '模里西斯',
        TC: '模裡西斯',
        value: 'MUS',
        code: 230,
    },
    {
        EN: 'Malawi',
        SC: '马拉威',
        TC: '馬拉威',
        value: 'MWI',
        code: 265,
    },
    {
        EN: 'Mayotte',
        SC: '马约特',
        TC: '馬約特',
        value: 'MYT',
        code: 262,
    },
    {
        EN: 'Namibia',
        SC: '纳米比亚',
        TC: '納米比亞',
        value: 'NAM',
        code: 264,
    },
    {
        EN: 'New Caledonia',
        SC: '新喀里多尼亚',
        TC: '新喀里多尼亞',
        value: 'NCL',
        code: 687,
    },
    {
        EN: 'Norfolk Island',
        SC: '诺福克岛',
        TC: '諾福克島',
        value: 'NFK',
        code: 672,
    },
    {
        EN: 'Niue',
        SC: '纽埃',
        TC: '紐埃',
        value: 'NIU',
        code: 683,
    },
    {
        EN: 'Netherland Antilles',
        SC: '荷属安的列斯',
        TC: '荷屬安替列斯',
        value: 'NLA',
        code: 599,
    },
    {
        EN: 'Netherland Antilles',
        SC: '荷属安的列斯',
        TC: '荷屬安的列斯',
        value: 'NLD',
        code: 599,
    },
    {
        EN: 'Norway',
        SC: '挪威',
        TC: '挪威',
        value: 'NOR',
        code: 47,
    },
    {
        EN: 'Nepal',
        SC: '尼泊尔',
        TC: '尼泊爾',
        value: 'NPL',
        code: 977,
    },
    {
        EN: 'Nauru',
        SC: '诺鲁',
        TC: '諾魯',
        value: 'NRU',
        code: 674,
    },
    {
        EN: 'New Zealand',
        SC: '纽西兰',
        TC: '紐西蘭',
        value: 'NZL',
        code: 64,
    },
    {
        EN: 'Oman',
        SC: '阿曼',
        TC: '阿曼',
        value: 'OMN',
        code: 968,
    },
    {
        EN: 'Pitcairn',
        SC: '皮特凯恩群岛',
        TC: '皮特凱恩群島',
        value: 'PCN',
        code: 64,
    },
    {
        EN: 'Peru',
        SC: '秘鲁',
        TC: '秘魯',
        value: 'PER',
        code: 51,
    },
    {
        EN: 'Palau',
        SC: '帕劳',
        TC: '帕勞',
        value: 'PLW',
        code: 680,
    },
    {
        EN: 'Poland',
        SC: '波兰',
        TC: '波蘭',
        value: 'POL',
        code: 48,
    },
    {
        EN: 'Puerto Rico',
        SC: '波多黎各',
        TC: '波多黎各',
        value: 'PRI',
        code: '1-787, 1-939',
    },
    {
        EN: 'Portugal',
        SC: '葡萄牙',
        TC: '葡萄牙',
        value: 'PRT',
        code: 351,
    },
    {
        EN: 'Paraguay',
        SC: '巴拉圭',
        TC: '巴拉圭',
        value: 'PRY',
        code: 595,
    },
    {
        EN: 'French Polynesia',
        SC: '法属玻里尼西亚 (大溪地)',
        TC: '法屬玻裡尼西亞 (大溪地)',
        value: 'PYF',
        code: 689,
    },
    {
        EN: 'Qatar',
        SC: '卡达',
        TC: '卡達',
        value: 'QAT',
        code: 974,
    },
    {
        EN: 'Reunion',
        SC: '留尼汪岛',
        TC: '留尼旺島',
        value: 'REU',
        code: 262,
    },
    {
        EN: 'Romania',
        SC: '罗马尼亚',
        TC: '羅馬尼亞',
        value: 'ROU',
        code: 40,
    },
    {
        EN: 'Russia',
        SC: '俄罗斯',
        TC: '俄羅斯',
        value: 'RUS',
        code: 7,
    },
    {
        EN: 'Rwanda',
        SC: '卢安达',
        TC: '盧安達',
        value: 'RWA',
        code: 250,
    },
    {
        EN: 'Saudi Arabia',
        SC: '沙特阿拉伯',
        TC: '沙特阿拉伯',
        value: 'SAU',
        code: 966,
    },
    {
        EN: 'Singapore',
        SC: '新加坡',
        TC: '新加坡',
        value: 'SGP',
        code: 65,
    },
    {
        EN: 'South Georgia South Sandwich Islands',
        SC: '南乔治亚和南桑威奇群岛',
        TC: '南喬治亞和南桑威奇群島',
        value: 'SGS',
        code: 500,
    },
    {
        EN: 'Saint Helena',
        SC: '圣赫勒拿',
        TC: '聖赫勒拿',
        value: 'SHN',
        code: 290,
    },
    {
        EN: 'Svalbard and Jan Mayen Islands',
        SC: '斯瓦巴和扬马延',
        TC: '斯瓦巴和揚馬延',
        value: 'SJM',
        code: 47,
    },
    {
        EN: 'Solomon Islands',
        SC: '索罗门群岛',
        TC: '索羅門群島',
        value: 'SLB',
        code: 677,
    },
    {
        EN: 'EI Salvador',
        SC: '萨尔瓦多',
        TC: '薩爾瓦多',
        value: 'SLV',
        code: 503,
    },
    {
        EN: 'St. Pierre & Miquelon',
        SC: '圣皮埃尔和密克隆群岛',
        TC: '聖皮埃爾和密克隆群島',
        value: 'SPM',
        code: 508,
    },
    {
        EN: 'Serbia',
        SC: '塞尔维亚',
        TC: '塞爾維亞',
        value: 'SRB',
        code: 381,
    },
    {
        EN: 'Sao Tome and Principe',
        SC: '圣多美普林西比',
        TC: '聖多美普林西比',
        value: 'STP',
        code: 239,
    },
    {
        EN: 'Suriname',
        SC: '苏利南',
        TC: '蘇利南',
        value: 'SUR',
        code: 597,
    },
    {
        EN: 'Slovakia',
        SC: '斯洛伐克',
        TC: '斯洛伐克',
        value: 'SVK',
        code: 421,
    },
    {
        EN: 'Slovenia',
        SC: '斯洛维尼亚',
        TC: '斯洛維尼亞',
        value: 'SVN',
        code: 386,
    },
    {
        EN: 'Sweden',
        SC: '瑞典',
        TC: '瑞典',
        value: 'SWE',
        code: 46,
    },
    {
        EN: 'Swaziland',
        SC: '史瓦济兰',
        TC: '史瓦濟蘭',
        value: 'SWL',
        code: 268,
    },
    {
        EN: 'Saint Martin',
        SC: '圣马丁',
        TC: '聖馬丁',
        value: 'SXM',
        code: 590,
    },
    {
        EN: 'Seychelles',
        SC: '塞席尔',
        TC: '塞席爾',
        value: 'SYC',
        code: 248,
    },
    {
        EN: 'Turks & Caicos Islands',
        SC: '特克斯和凯科斯群岛',
        TC: '特克斯和凱科斯群島',
        value: 'TCA',
        code: 1,
    },
    {
        EN: 'Thailand',
        SC: '泰国',
        TC: '泰國',
        value: 'THA',
        code: 66,
    },
    {
        EN: 'Tajikistan',
        SC: '塔吉克',
        TC: '塔吉克',
        value: 'TJK',
        code: 992,
    },
    {
        EN: 'Tokelau',
        SC: '托克劳',
        TC: '托克勞',
        value: 'TKL',
        code: 690,
    },
    {
        EN: 'Turkmenistan',
        SC: '土库曼',
        TC: '土庫曼',
        value: 'TKM',
        code: 993,
    },
    {
        EN: 'East Timor',
        SC: '东帝汶',
        TC: '東帝汶',
        value: 'TLS',
        code: 670,
    },
    {
        EN: 'Tonga',
        SC: '东加',
        TC: '東加',
        value: 'TON',
        code: 676,
    },
    {
        EN: 'Trinidad and Tobago',
        SC: '千里达及托巴哥',
        TC: '千里達及托貝哥',
        value: 'TTO',
        code: '1-868',
    },
    {
        EN: 'Tunisia',
        SC: '突尼斯',
        TC: '突尼斯',
        value: 'TUN',
        code: 216,
    },
    {
        EN: 'Turkey',
        SC: '土耳其',
        TC: '土耳其',
        value: 'TUR',
        code: 90,
    },
    {
        EN: 'Tuvalu',
        SC: '吐瓦鲁',
        TC: '吐瓦魯',
        value: 'TUV',
        code: 688,
    },
    {
        EN: 'Taiwan, China',
        SC: '台湾, 中国',
        TC: '臺灣, 中國',
        value: 'TWN',
        code: 886,
    },
    {
        EN: 'Tanzania',
        SC: '坦桑尼亚',
        TC: '坦桑尼亞',
        value: 'TZA',
        code: 255,
    },
    {
        EN: 'Ukraine',
        SC: '乌克兰',
        TC: '烏克蘭',
        value: 'UKR',
        code: 380,
    },
    {
        EN: 'United States minor outlying islands',
        SC: '美国本土外小岛屿',
        TC: '美國本土外小島嶼',
        value: 'UMI',
        code: 1,
    },
    {
        EN: 'Uruguay',
        SC: '乌拉圭',
        TC: '烏拉圭',
        value: 'URY',
        code: 598,
    },
    {
        EN: 'United States',
        SC: '美国',
        TC: '美國',
        value: 'USA',
        code: 1,
    },
    {
        EN: 'Uzbekistan',
        SC: '乌兹别克',
        TC: '烏茲別克',
        value: 'UZB',
        code: 998,
    },
    {
        EN: 'Vatican City',
        SC: '梵蒂冈',
        TC: '梵蒂岡',
        value: 'VAT',
        code: 379,
    },
    {
        EN: 'Vatican City',
        SC: '梵蒂冈',
        TC: '梵蒂岡',
        value: 'VCS',
        code: 379,
    },
    {
        EN: 'Saint Vincent and the Grenadines',
        SC: '圣文森及格瑞那丁',
        TC: '聖文森及格瑞那丁',
        value: 'VCT',
        code: '1-784',
    },
    {
        EN: 'British Virgin Islands',
        SC: '英属维尔京群岛',
        TC: '英屬維爾京群島',
        value: 'VGB',
        code: '1-284',
    },
    {
        EN: 'Virgin Islands (U.S.)',
        SC: '美属维京群岛',
        TC: '美屬維爾京群島',
        value: 'VIR',
        code: '1-340',
    },
    {
        EN: 'Vietnam',
        SC: '越南',
        TC: '越南',
        value: 'VNM',
        code: 84,
    },
    {
        EN: 'Vanuatu',
        SC: '瓦努阿图',
        TC: '瓦努阿圖',
        value: 'VUT',
        code: 678,
    },
    {
        EN: 'Wallis & Futuna',
        SC: '瓦利斯和富图纳群岛',
        TC: '瓦利斯和富圖納群島',
        value: 'WLF',
        code: 681,
    },
    {
        EN: 'Samoa',
        SC: '萨摩亚',
        TC: '薩摩亞',
        value: 'WSM',
        code: 685,
    },
    {
        EN: 'Zaire',
        SC: '扎伊尔',
        TC: '扎伊爾',
        value: 'ZAE',
        code: 243,
    },
    {
        EN: 'South Africa',
        SC: '南非',
        TC: '南非',
        value: 'ZAF',
        code: 27,
    },
    {
        EN: 'Zambia',
        SC: '赞比亚',
        TC: '贊比亞',
        value: 'ZMB',
        code: 260,
    },
];

export default CountryList;
