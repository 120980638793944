import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MemberRegisterForm from './regform';

const MemberRegister = ({ match }) => {
    return (
        <div className="memberregister-warpper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/form`} />
                <Route path={`${match.url}/form`} component={MemberRegisterForm} />
            </Switch>
        </div>
    );
};
export default MemberRegister;
