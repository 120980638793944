import { Typography, Input } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { FormGroup } from 'reactstrap';
import InjectMassage from 'util/IntlMessages';

const CustomInputNew = ({ label, name, control, defaultValue, render, errors, rules, required, ...params }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            errors={errors}
            {...params}
            rules={rules}
            render={props => (
                <FormGroup className="has-wrapper">
                    {label && (
                        <Typography
                            style={{
                                fontFamily: 'montserrat',
                                fontSize: '14px',
                                lineHeight: '140%',
                                color: '#444444',
                                marginBottom: '6px',
                            }}
                        >
                            <InjectMassage id={label} />
                            {required && ' *'}
                        </Typography>
                    )}
                    <Input
                        disabled={params.disabled}
                        type={params.type}
                        placeholder={params.placeholder}
                        {...props}
                        style={{ borderRadius: '32px', background: '#FFFFFF', width: '100%', padding: '6px 12px' }}
                        disableUnderline
                    />
                    <span className="error-message">{errors && errors[name] && errors[name]['message']}</span>
                </FormGroup>
            )}
        ></Controller>
    );
};

export default CustomInputNew;
