import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import * as _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import NavMenuItem from './NavMenuItem';
import { injectIntl, intlShape } from 'react-intl';

import { onToggleMenu } from 'actions';
import { getSidebarMenus } from 'reducers/SidebarReducer';
import { getLanguage } from 'reducers/SettingReducer';
import { getUser } from 'reducers/AuthReducer';
import { CONSTANT } from 'constants/constant';

const SidebarContent = ({ winState, intl }) => {
    const loginPostMessage = intl.formatMessage({ id: 'sidebar.loginPost' });
    const loginPreMessage = intl.formatMessage({ id: 'sidebar.loginPre' });

    const [entityList, setEntityList] = useState([]);

    const dispatch = useDispatch();

    const { sidebarMenus, user, language } = useSelector(state => ({
        sidebarMenus: getSidebarMenus(state.sidebar),
        user: getUser(state.auth),
        language: getLanguage(state.setting),
    }));

    useEffect(() => {
        let entityListResult = _.get(user, 'member.entityList');
        if (Array.isArray(entityListResult)) {
            let deduplicatedEntityList = entityListResult.filter(function (item, pos) {
                return entityListResult.indexOf(item) == pos;
            });
            setEntityList(deduplicatedEntityList);
        } else if (typeof entityListResult === 'string') {
            setEntityList([entityListResult]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const toggleMenu = (menu, stateCategory) => {
        let data = {
            menu,
            stateCategory,
        };
        dispatch(onToggleMenu(data));
    };

    return (
        <Scrollbars autoHide autoHideDuration={100}>
            <List
                className="rct-mainMenu p-0 m-0 list-unstyled"
                subheader={<ListSubheader className="side-title" component="li"></ListSubheader>}
            >
                {sidebarMenus.category1.map((menu, key) => (
                    <NavMenuItem
                        winState={winState}
                        menu={menu}
                        key={key}
                        index={key}
                        selected={menu.selected}
                        onToggleMenu={() => toggleMenu(menu, 'category1')}
                    />
                ))}
            </List>

            <Box p={4} />
        </Scrollbars>
    );
};

export default injectIntl(withRouter(SidebarContent));
