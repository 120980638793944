import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LuckyDraw from './luckydraw';

const Promotion = ({ match }) => {
    return (
        <div className="promotion-wrapper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/luckydraw`} />
                <Route exact path={`${match.url}/luckydraw`} component={LuckyDraw} />
            </Switch>
        </div>
    );
};
export default Promotion;
