import React, { useEffect, useState } from 'react';
import { Card } from 'components/Card';
import { Button, Select } from '@material-ui/core';
import { Form } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import * as _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import InjectMassage from 'util/IntlMessages';
import CustomInput from 'components/CustomInput';
import CountryList from 'constants/CountryList';
import Zoom from 'react-medium-image-zoom';
import ImageGallery from 'react-image-gallery';

import { useSelector, useDispatch } from 'react-redux';

import { getCurrentRecord } from 'reducers/RewardReducer';

import { getLoading } from 'reducers/RedemptionReducer';

import { getCurrentReward, postRedemption } from 'actions';

import { getLanguage } from 'reducers/SettingReducer';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const RedemptionSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
    addressLine1: yup.string().required('Address Line 1 is required'),
    addressLine2: yup.string().required('Address Line 2 is required'),
    city: yup.string().required('City is required'),
    stateRegion: yup.string().required('State / Region is required'),
});

const Checkout = ({ match, history }) => {
    const dispatch = useDispatch();

    const { curReward, redemptionLoading, language } = useSelector(state => ({
        curReward: getCurrentRecord(state.reward),
        redemptionLoading: getLoading(state.redemption),
        language: getLanguage(state.setting),
    }));

    useEffect(() => {
        if (!curReward) {
            dispatch(getCurrentReward(match.params.id, { queryParam: { language: language.languageId } }));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRedemption = data => {
        let param = {
            rewardId: match.params.id,
            quantity: Number(data.quantity),
            name: data.name,
            phone: data.phone,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            stateRegion: data.stateRegion,
            country: data.country,
        };
        dispatch(postRedemption(param, history));
    };

    const { control, handleSubmit, errors } = useForm({
        resolver: yupResolver(RedemptionSchema),
    });

    const [imageSet, setImageSet] = useState([]);

    useEffect(() => {
        let tmpImageSet = [];

        if (curReward) {
            if (_.get(curReward, 'reward_item__c.image_path__c')) {
                tmpImageSet.push({
                    original: _.get(curReward, 'reward_item__c.image_path__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.image_path__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.sub_image_path_1__c')) {
                tmpImageSet.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_1__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_1__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.sub_image_path_2__c')) {
                tmpImageSet.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_2__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_2__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.sub_image_path_3__c')) {
                tmpImageSet.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_3__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_3__c'),
                });
            }

            setImageSet(tmpImageSet);
        }
    }, [curReward]);

    const imageGalleryRenderer = item => {
        return (
            <div>
                <Zoom>
                    <img src={item.original} alt={item.originalAlt} width="300" height="300" />
                </Zoom>
            </div>
        );
    };

    return (
        <div className="checkout-wrapper">
            {curReward ? (
                <div className="ml-lg-3 mt-3">
                    <Card>
                        <div className="mx-2 row row-eq-height justify-content-center py-5">
                            <div className="col-sm-6 col-md-6 col-lg-5">
                                <ImageGallery
                                    items={imageSet}
                                    thumbnailPosition="right"
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                    showNav={false}
                                    renderItem={imageGalleryRenderer}
                                ></ImageGallery>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-5">
                                <Form onSubmit={handleSubmit(handleRedemption)}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-2 col-sm-6  pl-0">
                                                <InjectMassage id="checkout.itemname" />
                                            </div>
                                            <div className="col-md-10 col-sm-6 pl-0">
                                                {_.get(curReward, 'reward_item__c.name')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-3">
                                        <div>
                                            <InjectMassage id="checkout.quantity" />
                                        </div>
                                        <Controller
                                            control={control}
                                            name="quantity"
                                            defaultValue="1"
                                            as={
                                                <Select
                                                    disabled={redemptionLoading}
                                                    className="w-100"
                                                    native
                                                    inputProps={{
                                                        name: 'quantity',
                                                        id: 'quantity',
                                                    }}
                                                >
                                                    <option value={1}>{1}</option>
                                                    {_.range(2, 100).map(i => (
                                                        <option key={i} value={i}>
                                                            {i}
                                                        </option>
                                                    ))}
                                                </Select>
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <InjectMassage id="checkout.username" />
                                        </div>
                                        <CustomInput
                                            disabled={redemptionLoading}
                                            errors={errors}
                                            control={control}
                                            type="text"
                                            name="name"
                                            defaultValue=""
                                            id="name"
                                            className="has-input input-lg"
                                            placeholder=""
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <InjectMassage id="checkout.phonenumber" />
                                        </div>
                                        <CustomInput
                                            disabled={redemptionLoading}
                                            errors={errors}
                                            control={control}
                                            type="text"
                                            name="phone"
                                            defaultValue=""
                                            id="phone"
                                            className="has-input input-lg"
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="pb-3">
                                        <div>
                                            <InjectMassage id="checkout.country" />
                                        </div>

                                        <Controller
                                            control={control}
                                            name="country"
                                            defaultValue="HKG"
                                            as={
                                                <Select
                                                    disabled={redemptionLoading}
                                                    className="w-100"
                                                    native
                                                    inputProps={{
                                                        name: 'country',
                                                        id: 'value',
                                                    }}
                                                >
                                                    {CountryList.map((country, i) => (
                                                        <option key={i} value={country.value}>
                                                            {country[language.short]}
                                                        </option>
                                                    ))}
                                                </Select>
                                            }
                                        />
                                    </div>

                                    <div>
                                        <div>
                                            <InjectMassage id="checkout.addressLine1" />
                                        </div>
                                        <CustomInput
                                            disabled={redemptionLoading}
                                            errors={errors}
                                            control={control}
                                            type="textarea"
                                            name="addressLine1"
                                            defaultValue=""
                                            id="addressLine1"
                                            className="has-input input-lg"
                                            placeholder=""
                                        />
                                    </div>

                                    <div>
                                        <div>
                                            <InjectMassage id="checkout.addressLine2" />
                                        </div>
                                        <CustomInput
                                            disabled={redemptionLoading}
                                            errors={errors}
                                            control={control}
                                            type="textarea"
                                            name="addressLine2"
                                            defaultValue=""
                                            id="addressLine2"
                                            className="has-input input-lg"
                                            placeholder=""
                                        />
                                    </div>

                                    <div>
                                        <div>
                                            <InjectMassage id="checkout.city" />
                                        </div>
                                        <CustomInput
                                            disabled={redemptionLoading}
                                            errors={errors}
                                            control={control}
                                            type="text"
                                            name="city"
                                            defaultValue=""
                                            id="city"
                                            className="has-input input-lg"
                                            placeholder=""
                                        />
                                    </div>

                                    <div>
                                        <div>
                                            <InjectMassage id="checkout.stateRegion" />
                                        </div>
                                        <CustomInput
                                            disabled={redemptionLoading}
                                            errors={errors}
                                            control={control}
                                            type="text"
                                            name="stateRegion"
                                            defaultValue=""
                                            id="stateRegion"
                                            className="has-input input-lg"
                                            placeholder=""
                                        />
                                    </div>

                                    <div className="py-3 flex-container">
                                        <Button
                                            disabled={redemptionLoading}
                                            className="w-100"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            <InjectMassage id="checkout.redemption" />
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Card>
                </div>
            ) : (
                <div />
            )}
        </div>
    );
};
export default Checkout;
