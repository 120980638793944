import appLocaleData from 'react-intl/locale-data/zh';
import zhMessages from '../locales/zh-THans';

const TZhLan = {
    messages: {
        ...zhMessages,
    },
    locale: 'zh-THans-CN',
    data: appLocaleData,
};
export default TZhLan;
