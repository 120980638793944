import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import RedemptionHistory from './history';
import RedemptionHistoryMobile from './historyMobile';
import RedemptionAward from './award';
import AwardDetail from './awarddetail';
import AwardCheckout from './checkout';
import AwardCheckoutMobile from './checkoutMobile';
import RedemptionAwardMobile from './awardMobile';
import AwardDetailMobile from './awardDetailMobile';
const Redemption = ({ match }) => {
    return (
        <div className="redemption-warpper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/history`} />
                <Route path={`${match.url}/history`} component={RedemptionHistory} />
                <Route exact path={`${match.url}/history-mobile`} component={RedemptionHistoryMobile} />
                <Route exact path={`${match.url}/award`} component={RedemptionAward} />
                <Route exact path={`${match.url}/award-mobile`} component={RedemptionAwardMobile} />
                <Route exact path={`${match.url}/award/:id`} component={AwardDetail} />
                <Route exact path={`${match.url}/award-mobile/:id`} component={AwardDetailMobile} />
                <Route exact path={`${match.url}/award/:id/checkout`} component={AwardCheckout} />
                <Route exact path={`${match.url}/award-mobile/:id/checkout`} component={AwardCheckoutMobile} />
            </Switch>
        </div>
    );
};
export default Redemption;
