//main logo
import logoImg from 'assets/img/logo.png';
//sde bar
import sidebar from 'assets/img/sidebar.jpg';
import sidebarProfile from 'assets/img/profile.jpg';

const Config = {
    appLogo: logoImg, // App Logo
    brandName: 'Emperor', // Brand Name
    sidebarBgImage: sidebar,
    sidebarProfileImage: sidebarProfile,
    backend: {
        //backend
        ver: 'v1',
    },
    locale: {
        // default language
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'en',
        short: 'EN',
    },
    flagIcon: {
        //icon of locale
        en: 'EN',
        zh: '简',
        tzh: '繁',
    },
    year: 2023,
    copyRightText: `© 2023 Emperor Financial Services Group.`, // Copy Right Text
    navCollapsed: false, //sidebar is Collapsed or not in default
    theme: {
        //theme of material ui
        palette: {
            primary: {
                main: '#00b2d3',
                danger: '#FF3739',
            },
            secondary: {
                main: '#FF3739',
                danger: '#FF3739',
            },
        },
        overrides: {
            MuiButton: {
                containedPrimary: {
                    color: 'white',
                },
            },
            MuiAppBar: {
                colorPrimary: {
                    color: 'white',
                },
            },
            MUIDataTableToolbar: {
                root: {
                    color: 'white',
                    backgroundColor: '#00b2d3',
                    display: 'flex !important',
                },
            },
            MuiChip: {
                root: {
                    color: 'white',
                    backgroundColor: '#00b2d3',
                },
            },
            MuiCircularProgress: {
                colorPrimary: {
                    color: 'white',
                },
            },
            MuiListItem: {
                root: {
                    '&$selected, &$selected:hover': {
                        backgroundColor: '#00b2d3',
                        color: 'white',
                        textDecoration: 'none',
                    },

                    '&:hover': {
                        backgroundColor: '#00b2d3',
                        color: 'white',
                        textDecoration: 'none',
                    },
                },
            },
            MuiAccordion: {
                root: {
                    '&.MuiAccordion-root:before': {
                        backgroundColor: 'white',
                    },
                    '&$expanded': {
                        margin: '0 auto',
                    },
                    '&.MuiAccordionDetails-root': {
                        padding: '0px',
                    },
                },
            },
        },
        typography: {
            useNextVariants: true,
            // Use the system font instead of the default Roboto font.
            fontFamily: ['Roboto', 'Helvetica', 'Arial', 'Heebo', 'sans-serif'].join(','),
            htmlFontSize: 16,
            h2: {
                fontSize: 21,
                fontWeight: 400,
            },
            body1: {
                fontWeight: 400,
            },
        },
        shadows: ['none'],
    },
};

const AppConfig = Config;

export default AppConfig;
