import { GET_TIER_RULE, GET_TIER_RULE_FAILURE, GET_TIER_RULE_SUCCESS } from 'actions/types';

const INIT_STATE = {
    recordList: [],
    count: 0,
    loading: false,
};

export const TierRuleReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TIER_RULE:
            return { ...state, loading: true };

        case GET_TIER_RULE_FAILURE:
            return { ...state, loading: false };

        case GET_TIER_RULE_SUCCESS:
            return { ...state, loading: false, recordList: action.payload.recordList, count: action.payload.count };

        default:
            return { ...state };
    }
};

//selector
export const getRecordList = state => state.recordList;
export const getCount = state => state.count;
export const getLoading = state => state.loading;
