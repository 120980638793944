import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import Header from 'components/Header/Header';
import { Scrollbars } from 'react-custom-scrollbars';
import Sidebar from 'react-sidebar';

import { useDispatch, useSelector } from 'react-redux';
import { getNavCollapsed } from 'reducers/SettingReducer';
import { getIsInApp } from 'reducers/AuthReducer';
import { collapsedSidebarAction } from 'actions';
import SidebarContent from 'components/Sidebar';

const DefaultLayout = ({ children }) => {
    const location = useLocation();
    const [state, setState] = useState({
        windowWidth: 0,
        windowHeight: 0,
    });

    const dispatch = useDispatch();

    const { navCollapsed, isInApp } = useSelector(state => ({
        navCollapsed: getNavCollapsed(state.setting),
        isInApp: getIsInApp(state.auth),
    }));

    //only render once
    useEffect(() => {
        const updateDimensions = () => {
            setState({ ...state, windowWidth: window.innerWidth, windowHeight: window.innerHeight });
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Scrollbar height
    const getScrollBarStyle = () => {
        return {
            height: 'calc(100vh - 50px)',
        };
    };

    const renderSidebar = () => {
        return !isInApp ? <SidebarContent></SidebarContent> : <></>;
    };

    const renderHeader = () => {
        return !isInApp ? <Header windowWidth={state.windowWidth}></Header> : null;
    };

    const renderPage = ({ children }) => {
        return (
            <Scrollbars className="rct-scroll" autoHide autoHideDuration={100} style={getScrollBarStyle()}>
                <div className={`rct-page-content ${location.pathname.includes('new') ? '' : 'p-3'} `}>
                    {children}
                    {/* <Footer /> */}
                </div>
            </Scrollbars>
        );
    };

    const renderNewPage = ({ children }) => {
        return <div className="rct-page-content">{children}</div>;
    };

    return (
        <div className="app">
            <div className="app-main-container">
                {/* <Header></Header> */}
                <Sidebar
                    sidebar={renderSidebar()}
                    open={state.windowWidth <= 1199 ? navCollapsed : false}
                    docked={state.windowWidth > 1199 ? !navCollapsed : false}
                    onSetOpen={() => dispatch(collapsedSidebarAction(false))}
                    styles={{ content: { overflowY: '' } }}
                    // contentClassName={classnames({ 'app-conrainer-wrapper': miniSidebar })}
                >
                    <div className="app-container">
                        <div className="rct-app-content">
                            {!location.pathname.includes('mobile') && <div className="app-header">{renderHeader()}</div>}
                            <div className="rct-page">
                                {location.pathname.includes('mobile')
                                    ? renderNewPage({ children })
                                    : renderPage({ children })}
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </div>
        </div>
    );
};
export default withRouter(DefaultLayout);
