import { removeEmpty } from 'util/helper';
import {
    GET_REWARD,
    GET_REWARD_SUCCESS,
    GET_REWARD_FAILURE,
    SET_REWARD_FILTER,
    SET_REWARD_INPUT_PARAMS,
    GET_CURRENT_REWARD,
    GET_CURRENT_REWARD_SUCCESS,
    GET_CURRENT_REWARD_FAILURE,
} from 'actions/types';

const INIT_STATE = {
    curRecord: null,
    recordList: [],
    count: 0,
    loading: false,
    filter: {
        loyaltyPointStart: 0,
        loyaltyPointEnd: 999999,
        language: 'english',
        // entityList: ['EMXpro (SVG)'],
    },
    inputParams: {
        isAsc: true,
        pageNo: 0,
        pageSize: 9,
    },
};

export const RewardReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REWARD:
            return { ...state, loading: true };

        case GET_REWARD_FAILURE:
            return { ...state, loading: false };

        case GET_REWARD_SUCCESS:
            return { ...state, loading: false, recordList: action.payload.recordList, count: action.payload.count };
        case SET_REWARD_FILTER:
            return {
                ...state,
                filter: action.payload,
            };
        case SET_REWARD_INPUT_PARAMS:
            return {
                ...state,
                inputParams: removeEmpty({
                    ...state.inputParams,
                    isAsc: action.payload.isAsc,
                    pageNo: action.payload.pageNo,
                    pageSize: action.payload.pageSize,
                }),
            };

        case GET_CURRENT_REWARD:
            return { ...state, loading: true };

        case GET_CURRENT_REWARD_SUCCESS:
            return {
                ...state,
                loading: false,
                curRecord: action.payload,
            };

        case GET_CURRENT_REWARD_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return { ...state };
    }
};

//selector
export const getLoading = state => state.loading;
export const getRecordList = state => state.recordList;
export const getCount = state => state.count;
export const getFilter = state => state.filter;
export const getInputParams = state => state.inputParams;
export const getCurrentRecord = state => state.curRecord;
