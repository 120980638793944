import { Controller } from 'react-hook-form';
import { Input, FormGroup } from 'reactstrap';

const CustomInput = ({ name, control, defaultValue, render, errors, rules, ...params }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            errors={errors}
            {...params}
            rules={rules}
            render={props => (
                <FormGroup className="has-wrapper">
                    <Input disabled={params.disabled} type={params.type} placeholder={params.placeholder} {...props} />
                    <span className="error-message">{errors && errors[name] && errors[name]['message']}</span>
                </FormGroup>
            )}
        ></Controller>
    );
};

export default CustomInput;
