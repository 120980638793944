import {
    GET_MEMBER_REG_RECORD,
    GET_MEMBER_REG_RECORD_FAILURE,
    GET_MEMBER_REG_RECORD_SUCCESS,
    POST_MEMBER_REG_RECORD,
    POST_MEMBER_REG_RECORD_SUCCESS,
    POST_MEMBER_REG_RECORD_FAILURE,
    GET_ACCOUNT_RECORD,
    GET_ACCOUNT_RECORD_FAILURE,
    GET_ACCOUNT_RECORD_SUCCESS,
} from 'actions/types';

import _ from 'lodash';

const INIT_STATE = {
    recordList: [],
    count: 0,

    loading: false,
};

export const MemberRegReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MEMBER_REG_RECORD:
            return { ...state, loading: true };

        case GET_MEMBER_REG_RECORD_FAILURE:
            return { ...state, loading: false };

        case GET_MEMBER_REG_RECORD_SUCCESS:
            return { ...state, loading: false, recordList: action.payload.result.recordList };
        case POST_MEMBER_REG_RECORD:
            return { ...state, loading: true };
        case POST_MEMBER_REG_RECORD_FAILURE:
            return { ...state, loading: false };

        case POST_MEMBER_REG_RECORD_SUCCESS:
            return { ...state, loading: false, recordList: action.payload.result.recordList };

        case GET_ACCOUNT_RECORD:
            return { ...state, loading: true };

        case GET_ACCOUNT_RECORD_FAILURE:
            return { ...state, loading: false };

        case GET_ACCOUNT_RECORD_SUCCESS:
            return { ...state, loading: false, childAccount: action.payload.result.recordList };
        default:
            return { ...state };
    }
};

//selector
export const getMemberRegRecords = state => state.recordList;

export const selectFilteredRecords = (state, entity) => {
    return _.filter(state.childAccount, { entity__c: entity });
};
export const selectFilteredPendingRecords = (state, entity) => {
    return _.filter(state.recordList, { status__c: 'Pending', entity__c: entity });
};
