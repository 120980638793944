import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_FAILURE,
    LOGOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_FAILURE,
    FORGOT_USER_PW,
    FORGOT_USER_PW_SUCCESS,
    FORGOT_USER_PW_FAILURE,
    CONFIRMATION_FORGOT_USER_PW,
    CONFIRMATION_FORGOT_USER_PW_SUCCESS,
    CONFIRMATION_FORGOT_USER_PW_FAILURE,
    CHANGE_USER_PW_SUCCESS,
    CHANGE_USER_PW,
    CHANGE_USER_PW_FAILURE,
    VALIDATE_USER,
    VALIDATE_USER_FAILURE,
    VALIDATE_USER_SUCCESS,
    REDIRECT_CRM,
    REDIRECT_CRM_FAILED,
    REDIRECT_CRM_SUCCESS,
    SSO_LOGIN,
    SSO_LOGIN_SUCCESS,
    SSO_LOGIN_FAILURE,
} from 'actions/types';

import {
    signinUser,
    logoutUser,
    signupUser,
    forgotUserPassword,
    forgetUserPasswordConfirmation,
    changeUserPassword,
    validateUserSession as validateUserSessionService,
    verifySSOLogin as verifySSOLoginService,
} from 'services';
import { NotificationManager } from 'react-notifications';
import * as _ from 'lodash';
import AppConfig from 'constants/AppConfig';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const signin =
    ({ username, password }, history) =>
    dispatch => {
        dispatch({ type: LOGIN_USER });
        sessionStorage.clear();
        signinUser({ username, password })
            .then(res => {
                sessionStorage.setItem('user', JSON.stringify(res.data));
                dispatch({ type: LOGIN_USER_SUCCESS, payload: res.data });
                history.push('/');
            })
            .catch(error => {
                if (_.get(error, 'response.data')) {
                    console.log(error.response.data.message);
                    if (error.response.data.code === 10009 || error.response.data.code === 10013)
                        history.push('/change-password');
                }
                dispatch({ type: LOGIN_USER_FAILURE, error: _.get(error, 'response.data.code') });
            });
    };

export const logout = () => dispatch => {
    dispatch({ type: LOGOUT_USER });
    logoutUser()
        .then(res => {
            sessionStorage.removeItem('user');
            dispatch({ type: LOGOUT_USER_SUCCESS });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: LOGOUT_USER_FAILURE });
        });
};

export const signup =
    ({ username, password }, history) =>
    dispatch => {
        dispatch({ type: SIGNUP_USER });
        signupUser({ username, password })
            .then(res => {
                dispatch({ type: SIGNUP_USER_SUCCESS });
                NotificationManager.success('Registration Success. Please confirm through your email.');
            })
            .catch(error => {
                if (_.get(error, 'response.data')) {
                    NotificationManager.error(error.response.data.message);
                }
                dispatch({ type: SIGNUP_USER_FAILURE });
            });
    };

export const forgotPassword =
    ({ username }, history) =>
    dispatch => {
        dispatch({ type: FORGOT_USER_PW });
        forgotUserPassword({ username })
            .then(res => {
                dispatch({ type: FORGOT_USER_PW_SUCCESS });
                const MySwal = withReactContent(Swal);
                let userStatus = _.get(res.data, 'User.UserStatus');

                //if user want to reset the password for the first time login
                if (!!userStatus && userStatus === 'FORCE_CHANGE_PASSWORD') {
                    history.push('/');
                    MySwal.fire({
                        position: 'top-end',
                        icon: 'success',
                        showConfirmButton: false,
                        title: <p>The One-time password has been sent. Please check your email.</p>,
                    });
                } else {
                    MySwal.fire({
                        position: 'top-end',
                        icon: 'success',
                        showConfirmButton: false,
                        title: <p>The Confirmation Code has been sent. Please check your email.</p>,
                    });
                }
            })
            .catch(error => {
                dispatch({ type: FORGOT_USER_PW_FAILURE, error: _.get(error, 'response.data.code') });
            });
    };

export const forgetPasswordConfirmation =
    ({ username, confirmationCode, password }, history) =>
    dispatch => {
        dispatch({ type: CONFIRMATION_FORGOT_USER_PW });
        forgetUserPasswordConfirmation({ username, confirmationCode, password })
            .then(res => {
                dispatch({ type: CONFIRMATION_FORGOT_USER_PW_SUCCESS });
                history.push('/');
                const MySwal = withReactContent(Swal);
                MySwal.fire({
                    icon: 'success',
                    title: <p>Password has been changed.</p>,
                });
            })
            .catch(error => {
                dispatch({ type: CONFIRMATION_FORGOT_USER_PW_FAILURE, error: _.get(error, 'response.data.code') });
            });
    };

export const changePassword =
    ({ username, oldPassword, password }, history, destination) =>
    dispatch => {
        dispatch({ type: CHANGE_USER_PW });
        changeUserPassword({ username, oldPassword, password })
            .then(res => {
                dispatch({ type: CHANGE_USER_PW_SUCCESS });
                history.push(destination || '/');
                const MySwal = withReactContent(Swal);
                MySwal.fire({
                    icon: 'success',
                    title: <p>Password has been changed.</p>,
                });
            })
            .catch(error => {
                dispatch({ type: CHANGE_USER_PW_FAILURE, error: _.get(error, 'response.data.code') });
            });
    };

export const validateUserSession = history => dispatch => {
    dispatch({ type: VALIDATE_USER });
    validateUserSessionService()
        .then(res => {
            // localStorage.setItem("user_id", "user-id");
            sessionStorage.setItem('user', JSON.stringify(res.data));
            dispatch({ type: VALIDATE_USER_SUCCESS, payload: res.data });
        })
        .catch(error => {
            dispatch({ type: VALIDATE_USER_FAILURE });
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch(logout());
            history.push('/signin');
            // NotificationManager.error(error.message);
        });
};

export const validateUserSessionLogin = history => dispatch => {
    dispatch({ type: VALIDATE_USER });
    validateUserSessionService()
        .then(res => {
            sessionStorage.setItem('user', JSON.stringify(res.data));
            history.push('/app/dashboard/home');
            dispatch({ type: VALIDATE_USER_SUCCESS, payload: res.data });
        })
        .catch(error => {
            // history.push('/signin');
            dispatch({ type: VALIDATE_USER_FAILURE });
        });
};

// const BACKEND_URL = 'http://127.0.0.1:8080';
const BACKEND_URL = window.location.origin;

export const crmLogin =
    ({ accountEmail, entity }) =>
    dispatch => {
        dispatch({ type: REDIRECT_CRM });

        let isLoginCurrentMemberEmail = !accountEmail;
        let getAccessTokenURL = isLoginCurrentMemberEmail
            ? `${BACKEND_URL}/api/${AppConfig.backend.ver}/member/get_access_token?entity=${entity}`
            : `${BACKEND_URL}/api/${AppConfig.backend.ver}/member/get_access_token?email=${accountEmail}&entity=${entity}`;

        fetch(getAccessTokenURL)
            .then(response => response.json())
            .then(data => {
                let accessToken = data.data;

                const form = document.createElement('form');
                form.method = 'POST';
                form.target = 'newWindow';
                form.action = `${BACKEND_URL}/api/${AppConfig.backend.ver}/member/redirect_crm`;

                const token = document.createElement('input');
                token.type = 'hidden';
                token.name = 'accessToken';
                token.value = accessToken;

                const crmEntity = document.createElement('input');
                crmEntity.type = 'hidden';
                crmEntity.name = 'entity';
                crmEntity.value = entity;

                form.appendChild(token);
                form.appendChild(crmEntity);
                document.body.appendChild(form);
                window.open('', 'newWindow');
                form.submit();
                dispatch({ type: REDIRECT_CRM_SUCCESS });
            })
            .catch(e => {
                dispatch({ type: REDIRECT_CRM_FAILED });
                console.log(e);
            });
    };

export const validateSSOLogin = (history, redirectUrl, accessToken, inAppEntity) => dispatch => {
    dispatch({ type: SSO_LOGIN });
    verifySSOLoginService({ accessToken })
        .then(res => {
            sessionStorage.setItem('user', JSON.stringify(res.data));
            sessionStorage.setItem('isInApp', JSON.stringify(true));
            sessionStorage.setItem('inAppEntity', JSON.stringify(inAppEntity));

            dispatch({ type: SSO_LOGIN_SUCCESS, payload: { user: res.data, inAppEntity } });
            history.push(redirectUrl || '/app/dashboard/home');
        })
        .catch(error => {
            dispatch(logout());
            history.push('/signin');
            dispatch({ type: SSO_LOGIN_FAILURE });
        });
};
