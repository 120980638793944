import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'components/Card';
import {
    Button,
    Select,
    Box,
    Container,
    CardMedia,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Form } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import * as _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { injectIntl, intlShape } from 'react-intl';
import InjectMassage from 'util/IntlMessages';
import { LuckyGrid } from '@lucky-canvas/react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { isMobile } from 'react-device-detect';

import { getPromotionEventRecord, postPromotionEventRecord, setLuckyDrawInitial } from 'actions';

import { useSelector, useDispatch } from 'react-redux';

import { getRecordList, getRewardsList, getLoading, getMemberRecord, getDrawResult } from 'reducers/PromotionReducer';

import { getLanguage } from 'reducers/SettingReducer';

const PromotionSchema = yup.object().shape({});

const LuckyDraw = ({ match, intl }) => {
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);

    let { language, loading, promotionEvent, promotionEventRewards, promotionEventMember, promotionDrawResult } =
        useSelector(state => ({
            language: getLanguage(state.setting),
            loading: getLoading(state.promotion),
            promotionEvent: getRecordList(state.promotion),
            promotionEventRewards: getRewardsList(state.promotion),
            promotionEventMember: getMemberRecord(state.promotion),
            promotionDrawResult: getDrawResult(state.promotion),
        }));
    // console.log(promotionEvent, promotionEventRewards, promotionEventMember, promotionDrawResult);

    const { control, handleSubmit, errors } = useForm({
        resolver: yupResolver(PromotionSchema),
    });

    const [prizes, setPrizes] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [blocks] = useState([
        { padding: '10px', background: '#FED661' },
        { padding: '10px', background: '#e9e8fe' },
    ]);

    const myLucky = useRef();

    const licenseNumber = _.get(promotionEvent, 'license_number__c');
    const eventWebsite = _.get(promotionEvent, 'event_website__c');
    const eventBackgroundImage = _.get(promotionEvent, 'background_image__c');
    const eventContactDate = _.get(promotionEvent, 'contact_date__c');
    const eventDepositWithdrawalDeadline = _.get(promotionEvent, 'deposit_withdrawal_deadline__c');
    const eventCounterDiff = _.subtract(
        _.get(promotionEventMember, 'calculation_counter__c', 0),
        _.get(promotionEventMember, 'calculation_used_counter__c', 0)
    );
    const eventCounter = eventCounterDiff < 0 ? 0 : eventCounterDiff;

    const startLuckyDraw = async () => {
        if (eventCounter <= 0) {
            console.log('no quota');
        } else {
            setIsDrawing(true);
            myLucky.current.play();
            dispatch(postPromotionEventRecord());
        }
    };

    useEffect(() => {
        //get luckydraw event
        dispatch(getPromotionEventRecord());


        MySwal.fire({
            html: '感謝客戶的熱烈參與及支持，英皇金融的投資旅行團夏日狂歡大抽獎已完滿結束！合資格的得獎者可於會員中心內的「兌換紀錄」內查看獎品，2023年10月6日或之前會獲專人通知領獎事宜，而未能獲獎的參加者不會收到個別通知。<a href="https://www.empfs.com/api/file/summer-travel-T&C">其他條款及細則。</a>',
            confirmButtonText: '確認',
        });
    }, []);

    useEffect(() => {
        let tmpPrizesSet = [];

        if (promotionEventRewards) {
            tmpPrizesSet = promotionEventRewards.map((item, index) => {
                return {
                    x: _.get(item, 'position_x__c'),
                    y: _.get(item, 'position_y__c'),
                    font: [{ text: _.get(item, 'reward_name__c') }],
                    borderRadius: '0px',
                    imgs: [
                        {
                            src: _.get(item, 'grid_image__c'),
                            width: '100%',
                            height: '100%',
                            activeSrc: _.get(item, 'grid_active_image__c'),
                        },
                    ],
                    resultImage: _.get(item, 'lucky_draw_image__c'),
                };
            });
            setPrizes(tmpPrizesSet);
        }
    }, [promotionEventRewards]);

    useEffect(() => {
        if (!!promotionDrawResult) {
            let prizeIndex = promotionDrawResult - 1;
            setTimeout(() => {
                myLucky.current.stop(prizeIndex);
            }, 1000);
            dispatch(setLuckyDrawInitial());
        }
    }, [promotionDrawResult]);

    return (
        !!promotionEvent &&
        promotionEventRewards.length > 0 && (
            <>
                <CardMedia
                    image={eventBackgroundImage}
                    style={{
                        backgroundPosition: 'top',
                        backgroundSize: smallDevice ? 'contain' : 'auto',
                        backgroundColor: smallDevice ? '#2ED4EC' : 'transparent',
                    }}
                >
                    <Box
                        minHeight={smallDevice ? 800 : 1500}
                        height="auto"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                    >
                        <Container maxWidth="md" disableGutters>
                            <div
                                className="luckydraw-wrapper"
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    height: 0,
                                    width: smallDevice ? '95%' : 960,
                                }}
                            >
                                <div style={{ margin: smallDevice ? '250px' : '600px' }} className="mx-auto">
                                    <Container maxWidth="md">
                                        <div className="mr-auto row row-eq-height justify-content-center">
                                            推廣生意的競賽牌照號碼 {licenseNumber}
                                        </div>
                                        <div className=" row row-eq-height justify-content-center py-2">
                                            <div className="bg-white rounded-pill p-3">
                                                <div
                                                    style={{
                                                        fontSize: smallDevice ? 10 : 30,
                                                        color: '#42d4ea',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    你獲得的抽獎機會: {eventCounter} 次
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mr-auto row row-eq-height justify-content-center py-2">
                                            推廣活動詳情及參加辦法請
                                            <a
                                                href={eventWebsite}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-white text-decoration-underline"
                                            >
                                                按此
                                            </a>
                                        </div>
                                        <div className="mr-auto row row-eq-height justify-content-center py-3">
                                            <LuckyGrid
                                                ref={myLucky}
                                                width={smallDevice ? '320px' : '600px'}
                                                height={smallDevice ? '320px' : '600px'}
                                                rows={3}
                                                cols={3}
                                                blocks={blocks}
                                                prizes={prizes}
                                                activeStyle={{ background: 'transparent' }}
                                                defaultConfig={{
                                                    gutter: 15,
                                                    decelerationTime: 1000,
                                                    accelerationTime: 1000,
                                                    speed: 20,
                                                }}
                                                onEnd={prize => {
                                                    // console.log('恭喜你抽到 ' + prize.font[0].text + '');
                                                    MySwal.fire({
                                                        title: '恭喜您中獎啦！',
                                                        html:
                                                            '<strong>' +
                                                            prize.font[0].text +
                                                            '</strong>' +
                                                            ' 已經添加到您的會員中心內的「兌換紀錄」，我們的客服會於' +
                                                            eventContactDate +
                                                            '前聯絡跟進領獎事宜。',
                                                        footer:
                                                            '*提提您: 如在' +
                                                            eventDepositWithdrawalDeadline +
                                                            '前出金將被取消獲獎資格 <br> 推廣生意的競賽牌照號碼 : ' +
                                                            licenseNumber,
                                                        imageUrl: prize?.resultImage || '',
                                                        imageWidth: 400,
                                                        imageAlt: 'Custom image',
                                                        confirmButtonText:'確認'
                                                    });
                                                    dispatch(getPromotionEventRecord());
                                                    setIsDrawing(false);
                                                }}
                                            />
                                        </div>
                                        <div className="mr-auto  row row-eq-height justify-content-center">
                                            <Button
                                                onClick={startLuckyDraw}
                                                style={{
                                                    backgroundColor: '#FED661',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    borderRadius: '30px',
                                                }}
                                                variant="contained"
                                                size="large"
                                                disabled={isDrawing ? true : false}
                                            >
                                                立即抽獎
                                            </Button>
                                        </div>
                                    </Container>
                                </div>
                            </div>
                        </Container>
                    </Box>
                </CardMedia>
                <div style={{ width: smallDevice ? '100%' : 1080, margin: '0 auto', background: '#42d4ea' }}>
                    <Container maxWidth="md">
                        <div className="mx-3 row row-eq-height justify-content-center py-5">
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>條款及細則</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: _.get(promotionEvent, 'terms_conditions__c'),
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </Container>
                </div>
            </>
        )
    );
};

export default injectIntl(LuckyDraw);
