import React from 'react';
import ContentLoader from 'react-content-loader';

const DashBoardGridLoader = props => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={800}
        // viewBox="0 0 100% 400"
        backgroundColor="#d9d9d9"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="6" ry="6" width="30%" height="300" />
        <rect x="33%" y="0" rx="6" ry="6" width="63%" height="300" />

        <rect x="0" y="350" rx="6" ry="6" width="30%" height="300" />
        <rect x="33%" y="350" rx="6" ry="6" width="30%" height="300" />
        <rect x="66%" y="350" rx="6" ry="6" width="30%" height="300" />
    </ContentLoader>
);

export default DashBoardGridLoader;
