import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import SessionLayout from 'container/SessionLayout';

import AppConfig from 'constants/AppConfig';

import { getLoading } from 'reducers/AuthReducer';

import { signup } from 'actions';

const SignUp = ({ history }) => {
    const dispatch = useDispatch();

    const { loading } = useSelector(state => ({
        loading: getLoading(state.auth),
    }));

    const [state, setState] = useState({
        user: '',
        password: '',
    });

    const onUserSignUp = () => {
        if (state.user !== '' && state.password !== '') {
            dispatch(signup({ username: state.user, password: state.QueueAnimpassword }, history));
        }
    };

    return (
        <SessionLayout
            loading={loading}
            sessionHeaderRight={
                <div>
                    <Link to="/signin" className="mr-2 text-white">
                        Already have an account?
                    </Link>
                    <Button component={Link} to="/signin" variant="contained" className="btn-light">
                        Sign In
                    </Button>
                </div>
            }
        >
            <div className="session-head mb-15">
                <h2 className="font-weight-bold">Get started with {AppConfig.brandName}</h2>
            </div>
            <Form>
                <FormGroup className="has-wrapper">
                    <Input
                        type="email"
                        value={state.user}
                        name="user-name"
                        id="user-name"
                        className="has-input input-lg"
                        placeholder="Enter Your Email"
                        onChange={e => setState({ ...state, user: e.target.value })}
                    />
                    <span className="has-icon">
                        <i className="ti-user"></i>
                    </span>
                </FormGroup>
                <FormGroup className="has-wrapper">
                    <Input
                        value={state.password}
                        type="Password"
                        name="user-pwd"
                        id="pwd"
                        className="has-input input-lg"
                        placeholder="Password"
                        onChange={e => setState({ ...state, password: e.target.value })}
                    />
                    <span className="has-icon">
                        <i className="ti-lock"></i>
                    </span>
                </FormGroup>
                <FormGroup className="mb-15">
                    <Button
                        color="primary"
                        className="btn-block text-white w-100"
                        variant="contained"
                        size="large"
                        onClick={() => onUserSignUp()}
                    >
                        Sign Up
                    </Button>
                </FormGroup>
            </Form>
            <p className="text-muted">By signing up you agree to {AppConfig.brandName}</p>
            <p>
                <Link to="/terms-condition" className="text-muted">
                    Terms of Service
                </Link>
            </p>
        </SessionLayout>
    );
};

export default SignUp;
