import { COLLAPSED_SIDEBAR, SET_LANGUAGE, TOGGLE_MENU, SELECT_MENU } from './types';

export const collapsedSidebarAction = isCollapsed => ({
    type: COLLAPSED_SIDEBAR,
    isCollapsed,
});

export const selectedSidebarAction = number => ({
    type: SELECT_MENU,
    payload: number,
});

export const setLanguage = language => {
    localStorage.setItem('locale', JSON.stringify(language));

    return {
        type: SET_LANGUAGE,
        payload: language,
    };
};

export const onToggleMenu = selectedMenu => ({
    type: TOGGLE_MENU,
    payload: selectedMenu,
});
