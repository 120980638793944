import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Card } from 'components/Card';
import IntlMessages from 'util/IntlMessages';

import { getLoyaltyPointHistory } from 'actions';

import { getRecordList, getLoading, getCount } from 'reducers/LoyaltyPointHistoryReducer';
import { getLanguage } from 'reducers/SettingReducer';
import { injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import NotFound from 'components/NotFound';

const PointHistoryMobile = ({ intl }) => {
    const dispatch = useDispatch();

    const [pageSize] = useState(9);

    const [opts, setOpts] = useState({
        orderBy: 'name',
        isAsc: false,
        pageSize: pageSize,
        pageNo: 0,
    });

    const increment = intl.formatMessage({ id: 'memberpoint.increment' });
    const redemption = intl.formatMessage({ id: 'memberpoint.redemption' });
    const systemAdjustment = intl.formatMessage({ id: 'memberpoint.systemAdjustment' });
    const typeMap = {
        'Increment': increment,
        'Redemption': redemption,
        'System Adjustment': systemAdjustment,
    };

    const content = [
        { label: 'memberpoint.title', value: data => typeMap[data.type__c] },
        { label: 'memberpoint.source', value: data => <IntlMessages id="memberpoint.transaction" /> },
        // { label: 'memberpoint.entity', value: data => '-' },
        {
            label: 'memberpoint.point',
            value: data => (
                <>
                    {data.loyalty_point__c > 0 && '+'}
                    <NumberFormat value={data.loyalty_point__c} thousandSeparator={true} displayType="text" />
                </>
            ),
        },
    ];

    const { loyaltyPointList, loading, count, language } = useSelector(state => ({
        loyaltyPointList: getRecordList(state.loyaltyPointHistory),
        loading: getLoading(state.loyaltyPointHistory),
        count: getCount(state.loyaltyPointHistory),
        language: getLanguage(state.setting),
    }));

    useEffect(() => {
        dispatch(getLoyaltyPointHistory({ queryParam: {}, ...opts }));
    }, [language, opts]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="point-history-new-wrapper">
            <div className="page-title">
                <IntlMessages id="memberpoint.pointTransactionHistory" />
            </div>
            <div className="card-list">
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center  h-100">
                        <CircularProgress size={75} />
                    </div>
                ) : loyaltyPointList.length === 0 ? (
                    <NotFound message={'memberpoint.noHistoryFound'} />
                ) : (
                    loyaltyPointList.length > 0 &&
                    loyaltyPointList.map((point, index) => (
                        <Card customClass="p-3" key={index}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="order">
                                    <div className="order-label">
                                        <IntlMessages id="memberpoint.pointTransaction" />
                                    </div>
                                    <div className="order-value">{point.name}</div>
                                </div>
                                <div className="content-value">
                                    {moment(point.createddate).utc().local().format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                            </div>
                            <div className="content-wrapper">
                                {content.map(el => (
                                    <div key={el.label} className="content">
                                        <div className="content-label">
                                            <IntlMessages id={el.label} />:
                                        </div>
                                        <div
                                            className={`content-value${
                                                el.label === 'memberpoint.point' ? '-point' : ''
                                            }`}
                                        >
                                            {el.value(point)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card>
                    ))
                )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
                {!loading && loyaltyPointList.length > 0 && (
                    <Pagination
                        page={opts.pageNo + 1}
                        count={Math.ceil(count / pageSize)}
                        color="primary"
                        onChange={(_, currentPage) => setOpts(prev => ({ ...prev, pageNo: currentPage - 1 }))}
                        classes={{
                            root: {
                                '&.Mui-selected': {
                                    'background-color': 'red',
                                },
                            },
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default injectIntl(PointHistoryMobile);
