import {
    GET_LOYALTY_PORTAL_CONFIG,
    GET_LOYALTY_PORTAL_CONFIG_FAILURE,
    GET_LOYALTY_PORTAL_CONFIG_SUCCESS,
} from 'actions/types';

const INIT_STATE = {
    banners: {
        count: 0,
        recordList: [],
    },
    itemTags: {
        count: 0,
        recordList: [],
    },
    loading: false,
};

export const LoyalityPortalConfigReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LOYALTY_PORTAL_CONFIG:
            return { ...state, loading: true };

        case GET_LOYALTY_PORTAL_CONFIG_FAILURE:
            return { ...state, loading: false };

        case GET_LOYALTY_PORTAL_CONFIG_SUCCESS:
            return { ...state, loading: false, banners: action.payload.banners, itemTags: action.payload.itemTags };

        default:
            return { ...state };
    }
};

//selector
export const getItemTags = state => state.itemTags;
export const getLoading = state => state.loading;
export const getBanners = state => state.banners;
