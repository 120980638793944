import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

const Requirement = ({ isvalid, message, intl }) => {
    return (
        <div>
            {isvalid ? (
                <div className="requirement-green font-weight-bold">
                    <span>
                        <i className="zmdi zmdi-check-all"></i>
                        &nbsp;
                        {message}
                    </span>
                </div>
            ) : (
                <div className="requirement-red font-weight-bold">
                    <span>
                        <i className="zmdi zmdi-close"></i>
                        &nbsp;
                        {message}
                    </span>
                </div>
            )}
        </div>
    );
};

Requirement.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Requirement);
