import {
    GET_PROMOTION_LUCKYDRAW,
    GET_PROMOTION_LUCKYDRAW_FAILURE,
    GET_PROMOTION_LUCKYDRAW_SUCCESS,
    POST_PROMOTION_LUCKYDRAW,
    POST_PROMOTION_LUCKYDRAW_FAILURE,
    POST_PROMOTION_LUCKYDRAW_SUCCESS,
    SET_LUCKYDRAW_RESULT_INITIAL,
} from 'actions/types';

const INIT_STATE = {
    recordList: {},
    loading: false,
    membersList: {},
    rewardsList: [],
    drawResult: null,
};

export const PromotionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROMOTION_LUCKYDRAW:
            return { ...state, loading: true };

        case GET_PROMOTION_LUCKYDRAW_FAILURE:
            return { ...state, loading: false };

        case GET_PROMOTION_LUCKYDRAW_SUCCESS:
            return {
                ...state,
                loading: false,
                recordList: action.payload.result.recordList,
                rewardsList: action.payload.result.rewardsList,
                membersList: action.payload.result.membersList,
            };

        case POST_PROMOTION_LUCKYDRAW:
            return { ...state, loading: true };

        case POST_PROMOTION_LUCKYDRAW_SUCCESS:
            return { ...state, loading: false, drawResult: action.payload.result };

        case POST_PROMOTION_LUCKYDRAW_FAILURE:
            return { ...state, loading: false };

        case SET_LUCKYDRAW_RESULT_INITIAL:
            return { ...state, loading: false, drawResult: null };

        default:
            return { ...state };
    }
};

//selector
export const getLoading = state => state.loading;
export const getRecordList = state => {
    return state.recordList;
};
export const getRewardsList = state => {
    return state.rewardsList;
};
export const getMemberRecord = state => {
    return state.membersList;
};
export const getDrawResult = state => {
    return state.drawResult;
};
export const getCount = state => state.count;
