import App from 'container/App';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store';

//import css
import './lib/mainCss';

const MainApp = () => (
    <Provider store={configureStore()}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </Router>
        </MuiPickersUtilsProvider>
    </Provider>
);

export default MainApp;
