import React from 'react';

import { CardHeading } from './CardHeading';

const Card = ({ children, heading, customClass }) => {
    return (
        <div className={(customClass || '') + ' rct-block'}>
            {heading && <CardHeading title={heading} />}
            {children}
        </div>
    );
};

export { Card };
