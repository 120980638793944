import {
    GET_CHILD_ACCOUNT,
    GET_CHILD_ACCOUNT_FAILURE,
    GET_CHILD_ACCOUNT_SUCCESS,
    GET_REFERRAL_HISTORY,
    GET_REFERRAL_HISTORY_SUCCESS,
    GET_REFERRAL_HISTORY_FAILURE,
    GET_YEARLY_RESULT,
    GET_YEARLY_RESULT_SUCCESS,
    GET_YEARLY_RESULT_FAILURE,
} from 'actions/types';

const INIT_STATE = {
    childAccount: {
        recordList: [],
        count: 0,
    },
    referralHistory: {
        recordList: [],
        count: 0,
    },
    loading: false,
};

export const MemberReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CHILD_ACCOUNT:
            return { ...state, loading: true };

        case GET_CHILD_ACCOUNT_FAILURE:
            return { ...state, loading: false };

        case GET_CHILD_ACCOUNT_SUCCESS:
            return { ...state, loading: false, childAccount: action.payload.recordList };

        case GET_REFERRAL_HISTORY:
            return { ...state, loading: true };

        case GET_REFERRAL_HISTORY_FAILURE:
            return { ...state, loading: false };

        case GET_REFERRAL_HISTORY_SUCCESS:
            return { ...state, loading: false, referralHistory: action.payload.recordList };

        case GET_YEARLY_RESULT:
            return { ...state, loading: true };

        case GET_YEARLY_RESULT_FAILURE:
            return { ...state, loading: false };

        case GET_YEARLY_RESULT_SUCCESS:
            return {
                ...state,
                loading: false,
                yearlyResult: {
                    loyaltyPoint: action.payload.loyaltyPoint,
                    referral: action.payload.referral,
                },
            };

        default:
            return { ...state };
    }
};

//selector
export const getChildAccount = state => state.childAccount;
export const getReferralHistory = state => state.referralHistory;
export const getYearlyResult = state => state.yearlyResult;
export const getLoading = state => state.loading;
