import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import UserProfile from './profile';
import ChangePassword from './changepassword';

const Profile = ({ match }) => {
    return (
        <div className="profile-warpper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/profile`} />
                <Route path={`${match.url}/profile`} component={UserProfile} />
                <Route path={`${match.url}/change-password`} component={ChangePassword} />
            </Switch>
        </div>
    );
};
export default Profile;
