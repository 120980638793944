import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme  } from '@material-ui/core/styles';

import AppLocale from 'lang';
import { getLanguage } from 'reducers/SettingReducer';
import AppConfig from 'constants/AppConfig';

const ThemeProvider = ({ children }) => {
    const { locale } = useSelector(state => ({
        locale: getLanguage(state.setting),
    }));

    const curLocale = AppLocale[locale.locale];

    return (
        <MuiThemeProvider theme={createTheme (AppConfig.theme)}>
            <IntlProvider locale={curLocale.locale} messages={curLocale.messages}>
                <Fragment>{children}</Fragment>
            </IntlProvider>
        </MuiThemeProvider>
    );
};

export default ThemeProvider;
