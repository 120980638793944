import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';

import { Card } from 'components/Card';
import IntlMessages from 'util/IntlMessages';

import CircularProgress from '@material-ui/core/CircularProgress';

import { getRedemption } from 'actions';

import { getRecordList as getRedemptionHistoryList, getLoading, getCount } from 'reducers/RedemptionReducer';
import { getLanguage } from 'reducers/SettingReducer';
import { injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import NotFound from 'components/NotFound';

const RedemptionHistoryMobile = ({ match, intl }) => {
    const dispatch = useDispatch();

    const [pageSize] = useState(9);

    const [opts, setOpts] = useState({
        orderBy: 'name',
        isAsc: false,
        pageSize: pageSize,
        pageNo: 0,
    });

    const promotionLuckyDrawEventC = intl.formatMessage({ id: 'promotion.luckydraw.event' });
    const content = [
        {
            label: 'redemption.rewardName',
            value: data => {
                if (_.get(data, 'promotion_event_reward__c.reward_name__c')) {
                    return _.get(data, 'promotion_event_reward__c.reward_name__c') + ' - ' + promotionLuckyDrawEventC;
                } else {
                    return _.get(data, 'reward__c.reward_item__c.name');
                }
            },
        },
        { label: 'redemption.quantity', value: data => data.quantity__c },
        // { label: 'redemption.redemptionEntity', value: data => data.entity__c },
        {
            label: 'redemption.redemptionPoint',
            value: data => (
                <NumberFormat value={data.total_loyalty_point__c} thousandSeparator={true} displayType="text" />
            ),
        },
        {
            label: 'redemption.status',
            value: data => intl.formatMessage({ id: 'redemption.status.' + data.status__c }),
        },
    ];

    const { language, redemptionHistoryList, loading, count } = useSelector(state => ({
        language: getLanguage(state.setting),
        redemptionHistoryList: getRedemptionHistoryList(state.redemption),
        loading: getLoading(state.redemption),
        count: getCount(state.redemption),
    }));

    useEffect(() => {
        dispatch(getRedemption({ queryParam: { language: language.languageId }, ...opts }));
    }, [language, opts]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="redemption-history-new-wrapper">
            <div className="page-title">
                <IntlMessages id="redemption.history" />
            </div>
            <div className="card-list">
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <CircularProgress size={75} />
                    </div>
                ) : redemptionHistoryList.length === 0 ? (
                    <NotFound message={'redemption.noHistoryFound'} />
                ) : (
                    redemptionHistoryList.length > 0 &&
                    redemptionHistoryList.map((redemption, index) => (
                        <Card customClass="p-3" key={index}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="order">
                                    <div className="order-label">
                                        <IntlMessages id="redemption.redemptionOrders" />#
                                    </div>
                                    <div className="order-value">
                                        {redemption.name ?? <IntlMessages id="redemption.processing" />}
                                    </div>
                                </div>
                                <div className="content-value">
                                    {moment(redemption.required_date_time__c)
                                        .utc()
                                        .local()
                                        .format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                            </div>
                            <div className="content-wrapper">
                                {content.map(el => (
                                    <>
                                        {el.label === 'redemption.status' && <hr style={{ margin: '8px 0' }} />}
                                        <div key={el.label} className="content">
                                            <div className="content-label">
                                                <IntlMessages id={el.label} />:
                                            </div>
                                            <div
                                                className={`content-value${
                                                    el.label === 'redemption.status' ? '-status' : ''
                                                }${el.label === 'redemption.rewardName' ? '-grey-5' : ''}`}
                                            >
                                                {el.value(redemption)}
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </Card>
                    ))
                )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
                {!loading && redemptionHistoryList.length > 0 && (
                    <Pagination
                        page={opts.pageNo + 1}
                        count={Math.ceil(count / pageSize)}
                        color="primary"
                        onChange={(_, currentPage) => setOpts(prev => ({ ...prev, pageNo: currentPage - 1 }))}
                    />
                )}
            </div>
        </div>
    );
};

export default injectIntl(RedemptionHistoryMobile);
