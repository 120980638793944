import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import {
    Typography,
    Container,
    Button,
    Grid,
    TextField,
    FormControlLabel,
    FormControl,
    Checkbox,
    Card,
    CardHeader,
    CardContent,
    CircularProgress,
    Tooltip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CheckCircle, RadioButtonUnchecked, Info } from '@material-ui/icons';
import IntlMessages from 'util/IntlMessages';
import { injectIntl, intlShape } from 'react-intl';
import AlertBoxEmailOTP from 'components/Widgets/AlertBoxEmailOTP';
import CustomMaterialInput from 'components/CustomMaterialInput';
import { CONSTANT } from 'constants/constant';
import { MobileAreaCodeList } from 'constants/MemberForm';
import { httpPost, httpGet } from 'lib/request';

import InputAdornment from '@material-ui/core/InputAdornment';

import * as _ from 'lodash';

import { getLanguage } from 'reducers/SettingReducer';
import { selectFilteredPendingRecords, selectFilteredRecords } from 'reducers/MemberRegReducer';

import { getMemberRegRecord, postMemberRegRecord, getExistingAccountRecord } from 'actions';
import { getLoading, getError, getUser } from 'reducers/AuthReducer';

const MemberRegisterForm = ({ history, intl }) => {
    const EBL_ENTITY = CONSTANT.ENTITY_EBL;
    const EIE_ENTITY = CONSTANT.ENTITY_EIE;
    const EBL_MF4_ENTITY = CONSTANT.ENTITY_EBL_MF4;
    const {
        loading,
        error,
        user,
        EBLPendingRecord,
        EIEPendingRecord,
        EBLMF4PendingRecord,
        EBLRegisteredRecord,
        EIERegisteredRecord,
        EBLMF4RegisteredRecord,
        SMSlanguage,
    } = useSelector(state => ({
        loading: getLoading(state.auth),
        error: getError(state.auth),
        user: getUser(state.auth),
        EBLPendingRecord: selectFilteredPendingRecords(state.memberReg, EBL_ENTITY),
        EIEPendingRecord: selectFilteredPendingRecords(state.memberReg, EIE_ENTITY),
        EBLMF4PendingRecord: selectFilteredPendingRecords(state.memberReg, EBL_MF4_ENTITY),
        EBLRegisteredRecord: selectFilteredRecords(state.memberReg, EBL_ENTITY),
        EIERegisteredRecord: selectFilteredRecords(state.memberReg, EIE_ENTITY),
        EBLMF4RegisteredRecord: selectFilteredRecords(state.memberReg, EBL_MF4_ENTITY),
        SMSlanguage: getLanguage(state.setting).short,
    }));
    const dispatch = useDispatch();

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors, isSubmitSuccessful },
        setValue,
        setError,
        clearErrors,
    } = useForm({
        mode: 'all',
        criteriaMode: 'all',
        defaultValues: {
            memberCode: '',
            EBL_email: '',
            EIE_email: '',
            mobile: '',
            area_code: '',
        },
        // resolver: yupResolver()
    });

    const serverErrorPrefix = 'server_';
    const haveEBLPendingRecord = EBLPendingRecord?.length >= 1 ? true : false;
    const haveEIEPendingRecord = EIEPendingRecord?.length >= 1 ? true : false;
    const haveEBLMF4PendingRecord = EBLMF4PendingRecord?.length >= 1 ? true : false;
    const haveEBLRegisteredRecord = EBLRegisteredRecord?.length >= 1 ? true : false;
    const haveEIERegisteredRecord = EIERegisteredRecord?.length >= 1 ? true : false;
    const haveEBLMF4RegisteredRecord = EBLMF4RegisteredRecord?.length >= 1 ? true : false;

    const [entity_EBL, setEntityEBL] = useState(false);
    const [entity_EIE, setEntityEIE] = useState(false);
    const [entityEBLMF4, setEntityEBLMF4] = useState(false);
    const [OTP_verified, setOTPVerified] = useState(false);
    const [validatingEmail, setValidatingEmail] = useState(false);
    const [OTPstatus, setOTPstatus] = useState(false);
    const [OTPCode, setOTPCode] = useState('');
    const [OTPCodeErrorMessage, setOTPCodeErrorMessage] = useState('');
    const fieldMobile = watch('mobile');
    const fieldAreaCode = watch('area_code');
    const OTPHandleClose = () => {
        setOTPstatus(false);
    };
    const getCodeButtonClick = async () => {
        if (fieldMobile) {
            try {
                setOTPstatus(true);
                const mobile = (fieldAreaCode + fieldMobile).toString();
                await httpPost('memberreg/mobileOTPRequest', {
                    language: SMSlanguage,
                    mobile,
                });
            } catch (error) {
                setOTPstatus(false);
            }
        }
    };

    const confirmOTPButtonClick = async otp => {
        if (otp) {
            try {
                const mobile = (fieldAreaCode + fieldMobile).toString();
                await httpPost('memberreg/mobileOTPVerify', {
                    otp,
                    mobile,
                });
                //     //valid OTP
                setOTPstatus(false);
                setOTPCodeErrorMessage('');
                setOTPVerified(true);
            } catch (error) {
                // invalid OTP
                setOTPCodeErrorMessage(
                    intl.formatMessage({
                        id: 'memberreg.form.mobile_otp_invalid',
                    })
                );
            }
        }
    };

    const entities = [
        {
            entity: CONSTANT.ENTITY_EBL,
            fieldName: 'entity_EBL',
            fieldValue: entity_EBL,
            emailFieldName: 'EBL_email',
            setFieldName: setEntityEBL,
            registeredRecord: EBLRegisteredRecord,
            pendingRecord: EBLPendingRecord,
            haveRegisteredRecord: haveEBLRegisteredRecord,
            havePendingRecord: haveEBLPendingRecord,
        },
        {
            entity: CONSTANT.ENTITY_EIE,
            fieldName: 'entity_EIE',
            fieldValue: entity_EIE,
            emailFieldName: 'EIE_email',
            setFieldName: setEntityEIE,
            registeredRecord: EIERegisteredRecord,
            pendingRecord: EIEPendingRecord,
            haveRegisteredRecord: haveEIERegisteredRecord,
            havePendingRecord: haveEIEPendingRecord,
        },
        {
            entity: CONSTANT.ENTITY_EBL_MF4,
            fieldName: 'entity_EBL_MF4',
            fieldValue: entityEBLMF4,
            emailFieldName: 'EBL_MF4_email',
            setFieldName: setEntityEBLMF4,
            registeredRecord: EBLMF4RegisteredRecord,
            pendingRecord: EBLMF4PendingRecord,
            haveRegisteredRecord: haveEBLMF4RegisteredRecord,
            havePendingRecord: haveEBLMF4PendingRecord,
        },
    ];

    const disabledSubmitButton = () => {
        if (loading) return true;
        //all records are pending
        if (_.every(entities, { havePendingRecord: true })) {
            return true;
        }
        //all entities not checked
        if (_.every(entities, { fieldValue: false })) {
            return true;
        }

        //have the pending records but no checked any entity
        const withoutPendingRecords = _.filter(entities, { havePendingRecord: false });
        if (_.every(withoutPendingRecords, { fieldValue: false })) {
            return true;
        }

        if (!OTP_verified) return true;
    };

    const disabledSMSVerifyButton = () => {
        if (loading) return true;
        //all records are pending
        if (_.every(entities, { havePendingRecord: true })) {
            return true;
        }
        //all entities not checked
        if (_.every(entities, { fieldValue: false })) {
            return true;
        }

        //have the pending records but no checked any entity
        const withoutPendingRecords = _.filter(entities, { havePendingRecord: false });
        if (_.every(withoutPendingRecords, { fieldValue: false })) {
            return true;
        }

        if (!fieldMobile) {
            return true;
        }
        if (!fieldAreaCode) {
            return true;
        }
        if (OTP_verified) {
            return true;
        }
    };

    useEffect(() => {
        const userMemberCode = _.get(user, 'member.member_code__c');
        setValue('memberCode', userMemberCode);

        //get existing member reg
        dispatch(getMemberRegRecord({ membershipCode: userMemberCode }));

        //get already linked account
        dispatch(getExistingAccountRecord());
    }, [user]);

    useEffect(() => {
        entities.map(record => {
            if (!record.fieldValue) {
                //clear the unchecked entity server error
                clearErrors(serverErrorPrefix + record.emailFieldName);
            }

            if (record.haveRegisteredRecord) {
                // record.setFieldName(true);
                // console.log(record.registeredRecord);
                // setValue(record.emailFieldName, record.registeredRecord[0].email__c);
            }

            if (record.havePendingRecord) {
                record.setFieldName(true);
                setValue(record.emailFieldName, record.pendingRecord[0].email__c);
            }
        });
    }, [JSON.stringify(entities)]);

    const onSubmit = async formData => {
        if (!validatingEmail) {
            let param = {
                mobileAreaCode: formData.area_code,
                mobile: formData.mobile,
                memberCode: formData.memberCode,
            };

            entities.map(async record => {
                if (record.fieldValue & !record.havePendingRecord) {
                    param.entity = record.entity;
                    param.email = formData[_.find(entities, { entity: record.entity }).emailFieldName];

                    // console.log('submitting record', param);
                    dispatch(postMemberRegRecord(param, history));
                }
            });
        }
    };

    const validateEmail = async (email, entity, emailFieldName) => {
        try {
            if (email && entity && emailFieldName) {
                setValidatingEmail(true);
                let isValidEmail = false;
                const validEmailResponse = await httpGet('memberreg/validEmail', { email, entity });
                isValidEmail = validEmailResponse.data.result.status;

                isValidEmail
                    ? clearErrors(serverErrorPrefix + emailFieldName)
                    : setError(serverErrorPrefix + emailFieldName, {
                          message: intl.formatMessage({
                              id: `memberreg.form.invalid_email`,
                          }),
                      });
                setValidatingEmail(false);
                return isValidEmail;
            }
        } catch (error) {
            setValidatingEmail(false);
        }
    };

    return (
        <div className="memberregister-wrapper">
            <div className="row justify-content-center align-self-center">
                <div className="col-12 col-md-6">
                    {!isSubmitSuccessful ? (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Card>
                                <CardHeader
                                    title={<IntlMessages id="memberreg.form.title" />}
                                    style={{ backgroundColor: '#00b2d3', color: '#fff', textAlign: 'center' }}
                                />
                                <CardContent>
                                    <div className="px-3 text-center">
                                        <div className="pb-3 text-center">
                                            {error &&
                                                (typeof error === 'number' ? (
                                                    <span className="error-message">
                                                        <IntlMessages id={`error.${error}`} />
                                                    </span>
                                                ) : (
                                                    <span className="error-message">
                                                        <IntlMessages id={'error.default'} />
                                                    </span>
                                                ))}
                                        </div>

                                        <div className="pb-3">
                                            <CustomMaterialInput
                                                control={control}
                                                disabled
                                                fullWidth
                                                variant="outlined"
                                                name="memberCode"
                                                label={intl.formatMessage({ id: 'memberreg.form.membershipCode' })}
                                            />
                                        </div>
                                        <div className="text-left">
                                            {entities.map((entity, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={entity.entity + index}
                                                        control={
                                                            <Checkbox
                                                                checked={entity.fieldValue}
                                                                onChange={e => {
                                                                    entity.setFieldName(e.target.checked);
                                                                }}
                                                                disabled={
                                                                    entity.havePendingRecord ||
                                                                    entity.haveRegisteredRecord
                                                                }
                                                                icon={<RadioButtonUnchecked />}
                                                                checkedIcon={
                                                                    <CheckCircle style={{ color: '#00b2d3' }} />
                                                                }
                                                            />
                                                        }
                                                        label={intl.formatMessage({
                                                            id: `memberreg.form.entity.${entity.fieldName}`,
                                                        })}
                                                    />
                                                );
                                            })}

                                            {entities.map((entity, index) => {
                                                if (entity.fieldValue)
                                                    return (
                                                        <div className="pb-3" key={entity.entity + index}>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Grid item xs={11}>
                                                                    <Controller
                                                                        rules={{ required: true }}
                                                                        name={entity.emailFieldName}
                                                                        control={control}
                                                                        render={props => {
                                                                            return (
                                                                                <TextField
                                                                                    label={intl.formatMessage({
                                                                                        id: `memberreg.form.entity.${entity.fieldName}.email`,
                                                                                    })}
                                                                                    disabled={
                                                                                        entity.havePendingRecord ||
                                                                                        entity.haveRegisteredRecord
                                                                                    }
                                                                                    value={props.value}
                                                                                    error={
                                                                                        !!errors[
                                                                                            serverErrorPrefix +
                                                                                                entity.emailFieldName
                                                                                        ] ||
                                                                                        !!errors[entity.emailFieldName]
                                                                                    }
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    onChange={value => {
                                                                                        props.onChange(value);
                                                                                    }}
                                                                                    onKeyUp={() => {
                                                                                        validateEmail(
                                                                                            props.value,
                                                                                            entity.entity,
                                                                                            entity.emailFieldName
                                                                                        );
                                                                                    }}
                                                                                    InputProps={{
                                                                                        maxLength: 100,
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                {validatingEmail ? (
                                                                                                    <CircularProgress color="secondary" />
                                                                                                ) : (
                                                                                                    ''
                                                                                                )}
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    helperText={
                                                                                        errors[
                                                                                            serverErrorPrefix +
                                                                                                entity.emailFieldName
                                                                                        ]?.message
                                                                                    }
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid container item xs={1} justifyContent="center">
                                                                    <Tooltip
                                                                        enterTouchDelay={50}
                                                                        interactive
                                                                        title={intl.formatMessage({
                                                                            id: 'memberreg.form.info.accounts',
                                                                        })}
                                                                    >
                                                                        <Info />
                                                                    </Tooltip>
                                                                </Grid>
                                                                {entity.havePendingRecord && (
                                                                    <Typography
                                                                        color="error"
                                                                        display="block"
                                                                        align="left"
                                                                    >
                                                                        {intl.formatMessage({
                                                                            id: 'memberreg.form.application_processing',
                                                                        })}
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                    );
                                            })}
                                        </div>

                                        <div className="pb-3">
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item xs={4} md={3}>
                                                    <Controller
                                                        rules={{ required: true }}
                                                        render={props => (
                                                            <Autocomplete
                                                                {...props}
                                                                freeSolo
                                                                options={MobileAreaCodeList.map(option => option.value)}
                                                                disabled={OTP_verified}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={intl.formatMessage({
                                                                            id: 'memberreg.form.mobile_area',
                                                                        })}
                                                                        variant="outlined"
                                                                        error={!!errors.area_code}
                                                                    />
                                                                )}
                                                                onInputChange={(event, data) => {
                                                                    props.onChange(data);
                                                                }}
                                                                onChange={(event, data) => {
                                                                    props.onChange(data);
                                                                }}
                                                            />
                                                        )}
                                                        name="area_code"
                                                        control={control}
                                                    />
                                                </Grid>
                                                <Grid item xs={8} md={7}>
                                                    <CustomMaterialInput
                                                        rules={{ required: true }}
                                                        control={control}
                                                        label={intl.formatMessage({ id: 'memberreg.form.mobile' })}
                                                        name="mobile"
                                                        fullWidth
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 50,
                                                        }}
                                                        disabled={OTP_verified}
                                                        error={!!errors.mobile}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2} justifyContent="center" container>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={getCodeButtonClick}
                                                        disabled={disabledSMSVerifyButton()}
                                                    >
                                                        {OTP_verified
                                                            ? intl.formatMessage({
                                                                  id: 'memberreg.form.mobile_otp_verified',
                                                              })
                                                            : intl.formatMessage({
                                                                  id: 'memberreg.form.mobile_otp_verify',
                                                              })}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="pb-3">
                                            <Button
                                                disabled={disabledSubmitButton()}
                                                color="primary"
                                                className="btn-block text-white w-100"
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                            >
                                                <IntlMessages id="memberreg.form.submit_button" />
                                            </Button>
                                            <Typography color="textSecondary">
                                                <IntlMessages
                                                    id="memberreg.form.terms_conditions"
                                                    values={{
                                                        terms: (
                                                            <a
                                                                href={CONSTANT.MEMBERREG_TERM_AND_CONDITION}
                                                                rel="noreferrer"
                                                                target="_blank"
                                                            >
                                                                <IntlMessages id="memberreg.form.terms_conditions_url" />
                                                            </a>
                                                        ),
                                                        privacy: (
                                                            <a
                                                                href={CONSTANT.MEMBERREG_PRIVACY}
                                                                rel="noreferrer"
                                                                target="_blank"
                                                            >
                                                                <IntlMessages id="memberreg.form.privacy_url" />
                                                            </a>
                                                        ),
                                                    }}
                                                />
                                            </Typography>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </form>
                    ) : (
                        <Card>
                            <CardHeader
                                title={<IntlMessages id="memberreg.form.title" />}
                                style={{ backgroundColor: '#00b2d3', color: '#fff', textAlign: 'center' }}
                            />
                            <CardContent>
                                <Typography align="center">
                                    {intl.formatMessage({
                                        id: 'memberreg.form.thanks.message',
                                    })}
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                </div>
            </div>
            <AlertBoxEmailOTP
                open={OTPstatus}
                handleClose={OTPHandleClose}
                confirmButtonClick={confirmOTPButtonClick}
                OTPCode={OTPCode}
                setOTPCode={setOTPCode}
                OTPCodeErrorMessage={OTPCodeErrorMessage}
            />
        </div>
    );
};

MemberRegisterForm.prototype = {
    intl: intlShape.isRequired,
};

export default injectIntl(MemberRegisterForm);
