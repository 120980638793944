import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import LogoutBottom from './LogoutButtom';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 100, left: 700 }}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            // backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const MoreProvider = ({ windowWidth }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (windowWidth <= 1199) {
            setAnchorEl(null);
        }
    }, [windowWidth]);

    return (
        <div>
            <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="d-sm-flex d-md-none humburger"
                aria-label="display more actions"
                edge="end"
            >
                <MoreIcon />
            </IconButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>
                    <LogoutBottom />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
};

export default MoreProvider;
