import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SortOutlined } from '@material-ui/icons';
import SearchInput from 'components/SearchInput';
import IntlMessages from 'util/IntlMessages';
import FilterDialog from 'components/FilterDialog';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'components/Card';
import { CircularProgress } from '@material-ui/core';
import star from 'assets/img/star.png';
import { Pagination } from '@material-ui/lab';

import { getLanguage } from 'reducers/SettingReducer';
import { getInAppEntity, getUser } from 'reducers/AuthReducer';

import { getRecordList, getLoading, getCount, getInputParams, getFilter } from 'reducers/RewardReducer';
import { getRecordList as getFavoriteRewardList } from 'reducers/FavoriteRewardReducer';
import { getReward, setRewardInputParams, getFavoriteReward, setRewardFilter } from 'actions/LoyaltyActions';
import NumberFormat from 'react-number-format';
import { injectIntl } from 'react-intl';
import NotFound from 'components/NotFound';

const RedemptionAwardMobile = ({ match, intl }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setSearch] = useState('');
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const { awards, loading, count, inputParams, filter, favoriteReward, language, entityList, user } = useSelector(
        state => ({
            awards: getRecordList(state.reward),
            loading: getLoading(state.reward),
            count: Number(getCount(state.reward)),
            inputParams: getInputParams(state.reward),
            filter: getFilter(state.reward),
            favoriteReward: getFavoriteRewardList(state.favoriteReward),
            language: getLanguage(state.setting),
            entityList: getInAppEntity(state.auth),
            user: getUser(state.auth),
        })
    );

    const handleSearch = () => {
        if (search !== '') {
            dispatch(setRewardFilter({ ...filter, itemName: search }));
            dispatch(setRewardInputParams({ ...inputParams, pageNo: 0 }));
            dispatch(getReward({ queryParam: { ...filter, itemName: search }, ...inputParams, pageNo: 0 }));
        } else if (search === '') {
            let param = { ...filter, itemName: null };
            delete param.itemName;
            dispatch(setRewardFilter(param));
            dispatch(setRewardInputParams({ ...inputParams, pageNo: 0 }));
            dispatch(getReward({ queryParam: param, ...inputParams, pageNo: 0 }));
        } else {
            dispatch(setRewardFilter({ ...filter, itemName: search }));
            dispatch(setRewardInputParams({ ...inputParams, pageNo: 0 }));
            dispatch(getReward({ queryParam: { ...filter, itemName: search }, ...inputParams, pageNo: 0 }));
        }
    };

    const handleSetFilter = value => {
        dispatch(setRewardInputParams({ ...inputParams, pageNo: 0 }));
        dispatch(getReward({ queryParam: value, ...inputParams, pageNo: 0 }));
    };

    const handlePagination = page => {
        dispatch(setRewardInputParams({ ...inputParams, pageNo: page - 1 }));
        dispatch(getReward({ queryParam: filter, ...inputParams, pageNo: page - 1 }));
    };

    const handleDetailItem = id => {
        history.push(`${match.path}/${id}`);
    };

    const handleOpenFilter = () => {
        setIsFilterOpen(true);
    };

    const handleCloseFilter = () => {
        setIsFilterOpen(false);
    };

    useEffect(() => {
        if (!awards) {
            let param = { ...filter, entityList: entityList };
            if (param.tagList && param.tagList.length === 0) {
                delete param.tagList;
            }

            dispatch(getReward({ queryParam: param, ...inputParams, pageNo: 0 }));
        }
    }, [awards]);

    useEffect(() => {
        let param = { ...filter };
        if (param.tagList && param.tagList.length === 0) {
            delete param.tagList;
        }

        dispatch(setRewardFilter({ ...param, language: language.languageId, entityList: user.member.entityList }));
        dispatch(
            getReward({
                queryParam: { ...param, language: language.languageId, entityList: user.member.entityList },
                ...inputParams,
            })
        );

        // dispatch(getFavoriteReward({ queryParam: {}, orderBy: 'sfid', isAsc: true }));
    }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filter.itemName) {
            setSearch(filter.itemName);
        }
    }, [filter]);

    return (
        <div className="reward-new-wrapper">
            <div>
                <div className="reward-filter-wrapper">
                    <SearchInput
                        style={{ width: '75%' }}
                        placeholder={intl.formatMessage({ id: 'reward.search' })}
                        value={search}
                        onChange={val => setSearch(val)}
                        onSearch={() => handleSearch()}
                    />
                    <div className="reward-filter" onClick={handleOpenFilter}>
                        <div className="text">
                            <IntlMessages id="reward.filter" />
                        </div>
                        <SortOutlined />
                    </div>
                </div>
            </div>
            <div className="reward-card-wrapper">
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <CircularProgress size={75} />
                    </div>
                ) : awards.length === 0 ? (
                    <NotFound message={'redemption.noRewardFound'} />
                ) : (
                    awards.length > 0 &&
                    awards.map((item, idx) => (
                        <Card customClass="p-3" key={idx}>
                            <div className="reward-card" onClick={() => handleDetailItem(item.sfid)}>
                                <img
                                    src={item.reward_item__c.image_path__c}
                                    alt={item.name}
                                    width={140}
                                    height={140}
                                    style={{ borderRadius: '8px', minWidth: 140 }}
                                />
                                <div className="item">
                                    <div>{item.reward_item__c.name}</div>
                                    <div className="point">
                                        <NumberFormat
                                            value={item.required_loyalty_point__c}
                                            thousandSeparator={true}
                                            displayType="text"
                                        />
                                        <img src={star} width={16} height={16} alt="start" />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))
                )}
            </div>
            {!loading && awards.length !== 0 && (
                <div className="d-flex justify-content-center align-items-center">
                    <Pagination
                        page={inputParams.pageNo + 1}
                        count={Math.ceil(count / inputParams.pageSize)}
                        color="primary"
                        onChange={(_, currentPage) => handlePagination(currentPage)}
                    />
                </div>
            )}
            <FilterDialog open={isFilterOpen} onClose={handleCloseFilter} setFilter={val => handleSetFilter(val)} />
        </div>
    );
};

export default injectIntl(RedemptionAwardMobile);
