import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import QueueAnim from 'rc-queue-anim';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppConfig from 'constants/AppConfig';
import { withRouter } from 'react-router-dom';
import SessionSlider from 'components/Widgets/SessionSlider';

import { getBanners } from 'reducers/LoyalityPortalConfigReducer';

import { getLanguage } from 'reducers/SettingReducer';

import { getLoyaltyPortalConfig } from 'actions';

const SessionLayout = ({ sessionHeaderRight, children, loading }) => {
    const imageSlideConfig = {
        width: '0',
        height: '0',
    };

    const dispatch = useDispatch();

    const { banners, language } = useSelector(state => ({
        banners: getBanners(state.loyalityPortalConfig),
        language: getLanguage(state.setting),
    }));

    useEffect(() => {
        dispatch(getLoyaltyPortalConfig({ queryParam: { language: language.languageId } }));
    }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoginBannerList(banners.recordList.filter(data => data.type__c === 'Login Page Banner'));
    }, [banners]);

    const [loginBannerList, setLoginBannerList] = useState([]);

    return (
        <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper row row-eq-height justify-content-center overflow-hidden">
                <div className="col-sm-6 col-md-6 col-lg-6 px-0 h-100" style={{ overflowY: 'auto' }}>
                    {loading && <LinearProgress />}
                    <AppBar elevation={0} position="static" className="session-header">
                        <Toolbar>
                            <div className="container">
                                <div className="d-flex justify-content-between px-2">
                                    <div className="session-logo">
                                        <Link to="/">
                                            <img
                                                src={AppConfig.appLogo}
                                                alt="session-logo"
                                                className="img-fluid"
                                                width="120"
                                            />
                                        </Link>
                                    </div>
                                    {sessionHeaderRight}
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div className="session-inner-wrapper">
                        <div className="container flex-container">{children}</div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 px-0 session-slider-container">
                    <SessionSlider data={loginBannerList} imageSlideConfig={imageSlideConfig} />
                </div>
            </div>
        </QueueAnim>
    );
};

export default withRouter(SessionLayout);
