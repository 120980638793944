import React, { useEffect, useState } from 'react';
import { CancelOutlined, CloseOutlined } from '@material-ui/icons';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    withStyles,
    Slider,
    Typography,
    createStyles,
    IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getFilter, getInputParams } from 'reducers/RewardReducer';
import { getLanguage } from 'reducers/SettingReducer';
import { getItemTags } from 'reducers/LoyalityPortalConfigReducer';
import { getLoyaltyPortalConfig, setRewardFilter } from 'actions';
import IntlMessages from 'util/IntlMessages';
import { getReward } from 'services';
import * as _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import CurrencyInput from 'react-currency-input-field';
import { getInAppEntity } from 'reducers/AuthReducer';
import CustomButton from 'components/CustomButton';

const CustomSlider = withStyles({
    root: {
        color: '#FFB800',
        height: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    thumb: {
        'height': 27,
        'width': 27,
        'borderRadius': '16px',
        'backgroundColor': '#fff',
        'border': '1px solid #EEE',
        'marginLeft': -13,
        'boxShadow': '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    track: {
        height: '8px',
    },
    rail: {
        color: '#EEE',
        opacity: 1,
        height: '8px',
        borderRadius: '4px',
    },
})(Slider);

const CustomSliderThumb = props => {
    return (
        <span {...props}>
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
        </span>
    );
};

const FilterDialog = ({ open, onClose, setFilter }) => {
    const MAX_POINT = 999999;
    const MIN_POINT = 0;

    const styles = useStyles();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [resultsCount, setResultsCount] = useState(0);
    const [pointRange, setPointRange] = useState([MIN_POINT, MAX_POINT]);
    const [isPointRangeValid, setIsPointRangeValid] = useState(true);
    const [selectedTag, setSelectedTag] = useState([]);

    const { filter, inputParams, itemTags, language, entityList } = useSelector(state => ({
        filter: getFilter(state.reward),
        inputParams: getInputParams(state.reward),
        itemTags: getItemTags(state.loyalityPortalConfig).recordList,
        language: getLanguage(state.setting),
        entityList: getInAppEntity(state.auth),
    }));

    const handlePointRange = (_, value) => {
        setPointRange(value);
    };

    const handleMinPoint = value => {
        if (!value) {
            return setPointRange(['', pointRange[1]]);
        }

        if (value > +pointRange[1]) {
            setPointRange([+pointRange[1] - 1, pointRange[1]]);
            return;
        }
        if (value < MIN_POINT) {
            setPointRange([MIN_POINT, pointRange[1]]);
            return;
        }
        setPointRange([value, pointRange[1]]);
    };

    const handleMaxPoint = value => {
        if (!value) {
            return setPointRange([pointRange[0], '']);
        }

        if (value > MAX_POINT) {
            setPointRange([pointRange[0], MAX_POINT]);
            return;
        }
        setPointRange([pointRange[0], value]);
    };

    const handleSelectTag = id => {
        setSelectedTag(prev => [...prev, id]);
    };

    const handleDeselectTag = id => {
        const filtered = selectedTag.filter(el => el !== id);
        setSelectedTag(filtered);
    };

    const handleConfirm = () => {
        const param = {
            ...filter,
            loyaltyPointStart: String(pointRange[0]),
            loyaltyPointEnd: String(pointRange[1]),
        };
        if (selectedTag.length === 0) {
            delete param.tagList;
        } else {
            param.tagList = selectedTag;
        }
        setFilter(param);
        dispatch(setRewardFilter(param));
        onClose();
    };

    const fetchReward = params => {
        setLoading(true);
        getReward(params)
            .then(data => {
                setResultsCount(data.data.result.count);
            })
            .catch(error => {
                if (_.get(error, 'response.data')) {
                    NotificationManager.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        dispatch(getLoyaltyPortalConfig({ queryParam: { language: language.languageId } }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    useEffect(() => {
        if (filter) {
            if (filter.loyaltyPointStart) {
                setPointRange(prev => [filter.loyaltyPointStart, prev[1]]);
            }

            if (filter.loyaltyPointEnd) {
                setPointRange(prev => [prev[0], filter.loyaltyPointEnd]);
            }

            if (filter.tagList) {
                setSelectedTag(filter.tagList);
            }
        }
    }, []);

    useEffect(() => {
        if (pointRange[0] === '') {
            setIsPointRangeValid(false);
        } else {
            setIsPointRangeValid(true);
        }

        if (pointRange[0] !== '' && pointRange[1] !== '') {
            setIsPointRangeValid(true);
        } else {
            setIsPointRangeValid(false);
        }

        const timer = setTimeout(() => {
            if (pointRange[0] !== '' && pointRange[1] !== '' && isPointRangeValid) {
                const param = {
                    ...filter,
                    loyaltyPointStart: String(pointRange[0]),
                    loyaltyPointEnd: String(pointRange[1]),
                    entityList: entityList,
                };
                if (selectedTag.length === 0) {
                    delete param.tagList;
                } else {
                    param.tagList = selectedTag;
                }
                fetchReward({
                    queryParam: param,
                    ...inputParams,
                });
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [pointRange, selectedTag]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ style: { borderRadius: '16px' } }}>
            <DialogTitle>
                <div className="d-flex justify-content-between align-items-center">
                    <Typography style={styles.title}>
                        <IntlMessages id="reward.filter" />
                    </Typography>
                    <IconButton onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent style={styles.wrapper}>
                <div>
                    <Typography style={styles.label}>
                        <IntlMessages id="reward.pointRange" />
                    </Typography>
                    <CustomSlider
                        ThumbComponent={CustomSliderThumb}
                        value={pointRange}
                        onChange={handlePointRange}
                        min={MIN_POINT}
                        max={MAX_POINT}
                        disableSwap
                    />
                    <div style={styles.rangeInput}>
                        <CurrencyInput
                            pattern="[0-9]*"
                            inputMode="numeric"
                            value={pointRange[0]}
                            onValueChange={handleMinPoint}
                            min={MIN_POINT}
                            max={MAX_POINT - 1}
                            style={styles.input}
                            className="w-100 has-input input-lg"
                        />

                        <span>-</span>

                        <CurrencyInput
                            pattern="[0-9]*"
                            inputMode="numeric"
                            value={pointRange[1]}
                            onValueChange={handleMaxPoint}
                            onBlur={() => {
                                pointRange[1] === '' && setPointRange(prev => [+prev[0], +prev[0] + 1]);
                            }}
                            max={MAX_POINT}
                            style={styles.input}
                            className="w-100 has-input input-lg"
                        />
                    </div>
                </div>
                <div>
                    <Typography style={styles.label}>
                        <IntlMessages id="reward.tagging" />
                    </Typography>
                    <div style={styles.tagWrapper}>
                        {itemTags.map(item => (
                            <div
                                key={item.id}
                                style={selectedTag.includes(item.sfid) ? styles.tagActive : styles.tagInactive}
                            >
                                <Typography style={styles.tagText} onClick={() => handleSelectTag(item.sfid)}>
                                    {item.name}
                                </Typography>
                                {selectedTag.includes(item.sfid) && (
                                    <IconButton style={{ padding: 0 }} onClick={() => handleDeselectTag(item.sfid)}>
                                        <CancelOutlined />
                                    </IconButton>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <CustomButton
                    onClick={handleConfirm}
                    disabled={!isPointRangeValid || resultsCount === 0}
                    loading={loading}
                >
                    <IntlMessages id="reward.confirm" />
                    <span style={{ marginLeft: '4px' }}>
                        ({resultsCount} <IntlMessages id="reward.result" />)
                    </span>
                </CustomButton>
            </DialogContent>
        </Dialog>
    );
};

export default FilterDialog;

const useStyles = createStyles(() => ({
    wrapper: {
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    title: {
        fontFamily: 'montserrat',
        fontSize: '1.125rem',
        color: '#585858',
        lineHeight: '140%',
    },
    label: {
        fontFamily: 'montserrat',
        fontSize: '0.813rem',
        color: '#444444',
        lineHeight: '120%',
        fontWeight: 600,
        marginBottom: '6px',
    },
    rangeInput: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
    },
    input: {
        textAlign: 'center',
        borderRadius: '23px',
        padding: '12px 16px',
        height: '46px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #CACACA',
        background: '#fff',
    },

    tagWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        gap: '12px',
    },
    tagActive: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        background: '#FED661',
        padding: '4px 8px 4px 10px',
        borderRadius: '20px',
    },
    tagInactive: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#EEEEEE',
        padding: '4px 8px 4px 10px',
        borderRadius: '20px',
    },
    tagText: {
        fontFamily: 'montserrat',
        fontSize: '0.875rem',
        color: '#444444',
    },
}));
