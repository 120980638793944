import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import NumberFormat from 'react-number-format';
import IntlMessages from 'util/IntlMessages';
import { textTruncate } from 'util/helper';
import * as _ from 'lodash';

const TopFavoriteReward = ({ datas }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        rtl: false,
    };

    return (
        <Slider {...settings}>
            {datas &&
                datas.map((data, key) => {
                    const item = {
                        sfid: _.get(data, 'reward__c.sfid', '') || '',
                        name: _.get(data, 'reward__c.reward_item__c.name', '') || '',
                        image: _.get(data, 'reward__c.reward_item__c.image_path__c', '') || '',
                        description: _.get(data, 'reward__c.reward_item__c.item_description__c', '') || '',
                        price: _.get(data, 'reward__c.required_loyalty_point__c', 0) || 0,
                    };
                    return (
                        <div key={key}>
                            <div>
                                <div className="overlay-wrap overflow-hidden">
                                    <div className="text-center py-4 flex-container">
                                        <img
                                            src={item.image}
                                            className="d-block mx-auto"
                                            alt="product"
                                            width="250"
                                            height="250"
                                        />
                                    </div>
                                    <div>
                                        <Link
                                            to={'/app/redemption/award/' + item.sfid}
                                            className="top-overlay-item-image flex-container"
                                        >
                                            <div className="text-center w-100 cart-link text-white py-2">
                                                {<IntlMessages id="reward.viewDetail" />}
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="product-info border-top p-3 px-lg-5">
                                    <div>
                                        <div className="flex-container reward-item-title">
                                            <h4 className="text-dark w-100">{textTruncate(item.name, 25)}</h4>
                                        </div>
                                        <p className="mb-2 text-muted font-xs">{textTruncate(item.description, 50)}</p>
                                    </div>
                                    <hr />
                                    <div className="flex-container">
                                        <h2 className="reward-card-title mb-0">
                                            <i className="zmdi zmdi-toll"></i>
                                            &nbsp;
                                            <NumberFormat
                                                value={item.price.toFixed(0)}
                                                thousandSeparator={true}
                                                displayType={'text'}
                                            ></NumberFormat>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </Slider>
    );
};

export default TopFavoriteReward;
