import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Point from './point';
import PointHistoryMobile from './pointMobile';

const Memberpoint = ({ match }) => {
    return (
        <div className="memberpoint-warpper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/point`} />
                <Route path={`${match.url}/point`} component={Point} />
                <Route exact path={`${match.url}/point-mobile`} component={PointHistoryMobile} />
            </Switch>
        </div>
    );
};
export default Memberpoint;
