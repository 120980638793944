import { FormGroup, Select, Typography } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import InjectMassage from 'util/IntlMessages';

const CustomSelect = ({ label, name, control, defaultValue, render, errors, rules, required, options, ...params }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            errors={errors}
            {...params}
            rules={rules}
            as={
                <FormGroup>
                    {label && (
                        <Typography
                            style={{
                                fontFamily: 'montserrat',
                                fontSize: '14px',
                                lineHeight: '140%',
                                color: '#444444',
                                marginBottom: '6px',
                            }}
                        >
                            <InjectMassage id={label} />
                            {required && ' *'}
                        </Typography>
                    )}
                    <Select
                        defaultValue={defaultValue}
                        disabled={params.disabled}
                        className="w-100"
                        native
                        inputProps={{
                            name: 'country',
                            id: 'value',
                        }}
                        style={{
                            borderRadius: '32px',
                            background: '#FFFFFF',
                            padding: '0.375rem 0.75rem',
                            fontFamily: 'montserrat',
                            fontSize: '1rem',
                        }}
                        disableUnderline
                    >
                        {options.map((option, i) => (
                            <option key={i} value={option.value}>
                                {option[params.language.short]}
                            </option>
                        ))}
                    </Select>
                    <span className="error-message">{errors && errors[name] && errors[name]['message']}</span>
                </FormGroup>
            }
        />
    );
};

export default CustomSelect;
