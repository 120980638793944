import React from 'react';
import Slider from 'react-slick';

const SessionSlider = ({ data, imageSlideConfig }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        swipe: true,
        touchMove: true,
        swipeToSlide: true,
        draggable: true,
        fade: true,
        cssEase: 'linear',
    };

    return (
        <div className="session-slider">
            <Slider {...settings}>
                {data &&
                    data !== null &&
                    data.map((data, key) => (
                        <div key={key}>
                            {data.redirect_path__c ? (
                                <a href={data.redirect_path__c} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={data.image_path__c}
                                        alt="session-slider"
                                        width={imageSlideConfig.width}
                                        height={imageSlideConfig.height}
                                        className="mr-0"
                                    />
                                </a>
                            ) : (
                                <img
                                    src={data.image_path__c}
                                    alt="session-slider"
                                    width={imageSlideConfig.width}
                                    height={imageSlideConfig.height}
                                    className="mr-0"
                                />
                            )}
                            {/* <div className="rct-img-overlay">
                        <h5 className="client-name">{data.name}</h5>
                        <span>{data.designation}</span>
                        <p className="mb-0 fs-14">{data.body}</p>
                    </div> */}
                        </div>
                    ))}
            </Slider>
        </div>
    );
};

export default SessionSlider;
