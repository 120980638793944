import { Dialog, Typography, Button } from '@material-ui/core';
import { closeDialog } from 'actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguage } from 'reducers/SettingReducer';
import { getRedemption } from 'actions';
import { getRedemption as getRedemptionService } from 'services';
import InjectMassage from 'util/IntlMessages';
import loadingGif from 'assets/gif/loading.gif';

const CustomNotification = () => {
    const dispatch = useDispatch();

    const { language } = useSelector(state => ({
        language: getLanguage(state.setting),
    }));
    const { open, id, orderedItem, title, content, onOk } = useSelector(state => state.dialog);

    const [orderNumber, setOrderNumber] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        const opts = {
            orderBy: 'name',
            isAsc: false,
            pageSize: 9,
            pageNo: 0,
        };
        dispatch(getRedemption({ queryParam: { language: language.languageId }, ...opts }));
        dispatch(closeDialog());
    };

    useEffect(() => {
        let interval;
        if (open && id) {
            setLoading(true);

            interval = setInterval(async () => {
                const res = await getRedemptionService({
                    queryParam: { language: language.languageId },
                    orderBy: 'name',
                    isAsc: false,
                });
                const recordList = res.data.result.recordList;
                const record = recordList.find(rec => rec.id === id);
                console.log(record.name);
                if (record && record.name !== null) {
                    setOrderNumber(record.name);
                    setLoading(false);
                    clearInterval(interval);
                }
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [open, id]);

    return (
        <Dialog open={open} PaperProps={{ style: { borderRadius: '12px' } }}>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                    <img src={loadingGif} width={'100%'} alt="loading-gif" />
                </div>
            ) : (
                <div style={{ padding: '16px' }}>
                    <Typography
                        style={{ fontSize: '24px', fontFamily: 'montserrat', color: '#E6A13B', marginBottom: '8px' }}
                    >
                        {title}
                    </Typography>
                    {orderNumber && (
                        <Typography
                            style={{
                                margin: '8px 0',
                                fontFamily: 'montserrat',
                                color: '#444444',
                                fontSize: '13px',
                                fontWeight: 600,
                            }}
                        >
                            <InjectMassage id="checkout.orderNo" /> : # {orderNumber}
                        </Typography>
                    )}
                    <Typography
                        style={{
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '160%',
                            color: '#444444',
                            fontFamily: 'montserrat',
                        }}
                    >
                        {content}
                    </Typography>
                    <Button
                        onClick={handleClose}
                        style={{ background: '#FFB800', borderRadius: '32px', marginTop: '16px' }}
                        fullWidth
                    >
                        <InjectMassage id="checkout.ok" />
                    </Button>
                </div>
            )}
        </Dialog>
    );
};

export default CustomNotification;
