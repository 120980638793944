import {
    GET_LOYALTY_POINT_HISTORY,
    GET_LOYALTY_POINT_HISTORY_FAILURE,
    GET_LOYALTY_POINT_HISTORY_SUCCESS,
} from 'actions/types';

const INIT_STATE = {
    recordList: [],
    count: 0,
    loading: false,
};

export const LoyaltyPointHistoryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LOYALTY_POINT_HISTORY:
            return { ...state, loading: true };

        case GET_LOYALTY_POINT_HISTORY_FAILURE:
            return { ...state, loading: false };

        case GET_LOYALTY_POINT_HISTORY_SUCCESS:
            return { ...state, loading: false, recordList: action.payload.recordList, count: action.payload.count };

        default:
            return { ...state };
    }
};

//selector
export const getRecordList = state => state.recordList;
export const getLoading = state => state.loading;
export const getCount = state => state.count;
