import Axios from 'axios';
import AppConfig from 'constants/AppConfig';

const BACKEND_URL = window.location.origin;
// const BACKEND_URL = 'https://efsg-gcrm-loyalty-sandbox-dev.herokuapp.com';

const httpGetById = (endpoint, id, opts) => {
    const requestConfig = {
        method: 'get',
        url: `${BACKEND_URL}/api/${AppConfig.backend.ver}/${endpoint}/${id}`,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        ...opts,
    };

    return Axios(requestConfig);
};

const httpGet = (endpoint, params, opts) => {
    const requestConfig = {
        method: 'get',
        url: `${BACKEND_URL}/api/${AppConfig.backend.ver}/${endpoint}`,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        params,
        ...opts,
    };

    return Axios(requestConfig);
};

const httpPutById = (endpoint, id, data, opts) => {
    const requestConfig = {
        method: 'put',
        url: `${BACKEND_URL}/api/${AppConfig.backend.ver}/${endpoint}/${id}`,
        data: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        ...opts,
    };
    return Axios(requestConfig);
};

const httpPost = (endpoint, data, opts) => {
    const requestConfig = {
        method: 'post',
        url: `${BACKEND_URL}/api/${AppConfig.backend.ver}/${endpoint}`,
        data: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        ...opts,
    };

    return Axios(requestConfig);
};

const httpPatchById = (endpoint, id, data, opts) => {
    const requestConfig = {
        method: 'patch',
        url: `${BACKEND_URL}/api/${AppConfig.backend.ver}/${endpoint}/${id}`,
        data: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        ...opts,
    };

    return Axios(requestConfig);
};

const httpDeleteById = (endpoint, id, opts) => {
    const requestConfig = {
        method: 'delete',
        url: `${BACKEND_URL}/api/${AppConfig.backend.ver}/${endpoint}/${id}`,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        ...opts,
    };
    return Axios(requestConfig);
};

const httpDelete = (endpoint, data, opts) => {
    const requestConfig = {
        method: 'delete',
        url: `${BACKEND_URL}/api/${AppConfig.backend.ver}/${endpoint}`,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        data,
        ...opts,
    };
    return Axios(requestConfig);
};

export { httpGetById, httpPost, httpPutById, httpPatchById, httpDeleteById, httpGet, httpDelete };
