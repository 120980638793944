import React from 'react';
import ReactDOM from 'react-dom';
import MainApp from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-PCP4GPC',
};

TagManager.initialize(tagManagerArgs)
ReactDOM.render(
    //<React.StrictMode>
    <MainApp />,
    //</React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
