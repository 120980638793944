export const MobileAreaCodeList = [
    {
        label: 'HK',
        value: '852',
    },
    {
        label: 'China',
        value: '86',
    },
];
