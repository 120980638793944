import React, { useState } from 'react';
import { Form, FormGroup, InputGroupAddon } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { injectIntl, intlShape } from 'react-intl';

import InjectMassage from 'util/IntlMessages';
import LanguageProvider from 'components/Header/LanguageProvider';
import CustomInput from 'components/CustomInput';
import CustomInputGroup from 'components/CustomInputGroup';
import SessionLayout from 'container/SessionLayout';
import RequirementList from 'components/PasswordRequirement/RequirementList';

import { getLoading, getError } from 'reducers/AuthReducer';

import { forgotPassword, forgetPasswordConfirmation } from 'actions';

// TODO: move to global?

const Forgotpwd = ({ history, intl }) => {
    yup.addMethod(yup.string, 'integer', function () {
        return this.matches(/^\d+$/, intl.formatMessage({ id: 'input.digitOnly' }));
    });

    const ForgotPwdSchema = yup.object().shape({
        'user-mail': yup
            .string()
            .required(intl.formatMessage({ id: 'input.emailRequired' }))
            .email(intl.formatMessage({ id: 'input.emailRestricted' })),
        'user-new-pwd': yup
            .string()
            .required(intl.formatMessage({ id: 'input.passwordRequired' }))
            .matches(
                /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/,
                intl.formatMessage({ id: 'input.specialCharRequired' })
            )
            .matches(/^.*[a-z].*$/, intl.formatMessage({ id: 'input.lowercaseRequired' }))
            .matches(/^.*[A-Z].*$/, intl.formatMessage({ id: 'input.uppercaseRequired' }))
            .matches(/^.*[0-9].*$/, intl.formatMessage({ id: 'input.numberRequired' }))
            .min(8, intl.formatMessage({ id: 'input.minLengthRequired' })),
        'confirm-pwd': yup
            .string()
            .oneOf([yup.ref('user-new-pwd'), null], intl.formatMessage({ id: 'input.passwordMatched' })),
        'confirmation-code': yup
            .string()
            .integer()
            .required(intl.formatMessage({ id: 'input.confirmationCodeRequired' })),
    });

    const emailSchema = yup.object().shape({
        'user-mail': yup
            .string()
            .required(intl.formatMessage({ id: 'input.emailRequired' }))
            .email(intl.formatMessage({ id: 'input.emailRestricted' })),
    });

    const dispatch = useDispatch();

    const { loading, error } = useSelector(state => ({
        loading: getLoading(state.auth),
        error: getError(state.auth),
    }));

    const { control, handleSubmit, errors, getValues } = useForm({
        criteriaMode: 'all',
        resolver: yupResolver(ForgotPwdSchema),
    });

    const [confirmationError, setConfirmationError] = useState('');

    const emailPH = intl.formatMessage({ id: 'forgot.enterEmail' });
    const cCodePH = intl.formatMessage({ id: 'forgot.enterConfirmationCode' });
    const nPwdPH = intl.formatMessage({ id: 'forgot.enterNewPassword' });
    const cPwdPH = intl.formatMessage({ id: 'forgot.enterConfirmationPassword' });

    const onForgotPassword = data => {
        setConfirmationError('');
        dispatch(
            forgetPasswordConfirmation(
                {
                    username: data['user-mail'],
                    confirmationCode: data['confirmation-code'],
                    password: data['user-new-pwd'],
                },
                history
            )
        );
    };

    const onGetConfirmationCode = () => {
        let userMail = getValues('user-mail');
        emailSchema
            .validate({ 'user-mail': userMail })
            .then(value => {
                setConfirmationError('');
                dispatch(forgotPassword({ username: value['user-mail'] }, history));
            })
            .catch(error => {
                setConfirmationError(error.errors[0]);
            });
    };

    return (
        <SessionLayout
            loading={loading}
            sessionHeaderRight={
                <div className="row justify-content-center align-self-center">
                    <LanguageProvider />
                </div>
            }
        >
            <div className="session-component-container w-100 shadow">
                <div className="session-body text-center h-100 px-5">
                    <div className="session-head mb-4">
                        <h2 className="font-weight-bold">
                            <InjectMassage id="forgot.resetPassword" />
                        </h2>
                        {error &&
                            (typeof error === 'number' ? (
                                <span className="error-message">
                                    <InjectMassage id={`error.${error}`} />
                                </span>
                            ) : (
                                <span className="error-message">
                                    <InjectMassage id={'error.default'} />
                                </span>
                            ))}
                    </div>
                    <Form onSubmit={handleSubmit(onForgotPassword)}>
                        <CustomInputGroup
                            disabled={loading}
                            errors={errors}
                            customError={confirmationError}
                            control={control}
                            type="mail"
                            id="user-mail"
                            defaultValue=""
                            name="user-mail"
                            className="has-input input-sm"
                            placeholder={emailPH}
                        >
                            <InputGroupAddon addonType="prepend">
                                <Button
                                    disabled={loading}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => onGetConfirmationCode()}
                                >
                                    <InjectMassage id="forgot.getCode" />
                                </Button>
                            </InputGroupAddon>
                        </CustomInputGroup>

                        <CustomInput
                            disabled={loading}
                            errors={errors}
                            control={control}
                            type="text"
                            name="confirmation-code"
                            defaultValue=""
                            id="confirmation-code"
                            className="has-input input-lg"
                            placeholder={cCodePH}
                        />

                        <CustomInput
                            disabled={loading}
                            // errors={errors}
                            control={control}
                            type="password"
                            name="user-new-pwd"
                            defaultValue=""
                            id="user-new-pwd"
                            className="has-input input-lg"
                            placeholder={nPwdPH}
                        />

                        <CustomInput
                            disabled={loading}
                            errors={errors}
                            control={control}
                            type="password"
                            name="confirm-pwd"
                            defaultValue=""
                            id="confirm-pwd"
                            className="has-input input-lg"
                            placeholder={cPwdPH}
                        />

                        <FormGroup>
                            <Button
                                disabled={loading}
                                color="primary"
                                type="submit"
                                variant="contained"
                                className="btn-info text-white btn-block btn-large w-100"
                            >
                                <InjectMassage id="forgot.resetPassword" />
                            </Button>
                        </FormGroup>
                        {loading ? (
                            <span>
                                <InjectMassage id="forgot.toLogin" />
                            </span>
                        ) : (
                            <Link to="/signin">
                                <InjectMassage id="forgot.toLogin" />
                            </Link>
                        )}

                        <RequirementList className="align-self-center" errors={errors['user-new-pwd']} />
                    </Form>
                </div>
            </div>
        </SessionLayout>
    );
};

Forgotpwd.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Forgotpwd);
