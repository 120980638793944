import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import IntlMessages from 'util/IntlMessages';
import SessionSlider from 'components/Widgets/SessionSlider';
import CircularProgressWithLabel from 'components/Widgets/CircularProgressWithLabel';
import TopFavoriteReward from 'components/Widgets/TopFavoriteReward';
import Link from '@material-ui/core/Link';
import DashBoardGridLoader from 'components/Loader/DashBoardGridLoader';
import { Card } from 'components/Card';
import * as _ from 'lodash';
import moment from 'moment';

import { getUser, getValidity } from 'reducers/AuthReducer';

import { getLanguage } from 'reducers/SettingReducer';

import { getRecordList, getLoading as getTopFavoriteRewardLoading } from 'reducers/TopFavoriteRewardReducer';

import { getRecordList as getTierRuleList, getLoading as getTierRuleLoading } from 'reducers/TierRuleReducer';
import { getBanners, getLoading as getBannerLoading } from 'reducers/LoyalityPortalConfigReducer';

import { getLoyaltyPortalConfig, getTopFavoriteReward, getTierRule, validateUserSession } from 'actions';

const LINK_URL_ENG = 'https://www.emxpro.com/en/activity/';

const LINK_URL_CN = 'https://www.cn-emxpro.com/activity/';

const LinkUrlMap = {
    english: LINK_URL_ENG,
    chinese: LINK_URL_CN,
    tchinese: LINK_URL_CN,
};

const HomeDashboard = ({ match, history }) => {
    const imageSlideConfig = {
        width: '100%',
        height: '100%',
    };

    const dispatch = useDispatch();

    const {
        user,
        banners,
        bannerLoading,
        language,
        topFavoriteRewardList,
        rewardLoading,
        tierRuleList,
        tierRuleLoading,
        valid,
    } = useSelector(state => ({
        user: getUser(state.auth),
        banners: getBanners(state.loyalityPortalConfig),
        bannerLoading: getBannerLoading(state.loyalityPortalConfig),
        language: getLanguage(state.setting),
        topFavoriteRewardList: getRecordList(state.topFavoriteReward),
        rewardLoading: getTopFavoriteRewardLoading(state.topFavoriteReward),
        tierRuleList: getTierRuleList(state.tierRule),
        tierRuleLoading: getTierRuleLoading(state.tierRule),
        valid: getValidity(state.auth),
    }));

    useEffect(() => {
        // dispatch(getTierRule());
        dispatch(validateUserSession(history));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (valid) {
            dispatch(getTierRule());
            let entityListResult = _.get(user, 'member.entityList');
            dispatch(getLoyaltyPortalConfig({ queryParam: { language: language.languageId } }));
            dispatch(
                getTopFavoriteReward({ queryParam: { language: language.languageId, entityList: entityListResult } })
            );
        }
    }, [language, valid]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log('user');
        console.log(user);
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setHomeBannerList(banners.recordList.filter(data => data.type__c === 'Home Page Banner'));

        let bannerList = banners.recordList.filter(data => data.type__c === 'Home Page Banner');
        let cloneBannerList = [];
        bannerList.forEach(banner => {
            let cloneBanner = { ...banner };
            cloneBanner.image_path__c = cloneBanner.mobile_image_path__c;
            cloneBannerList.push(cloneBanner);
        });
        setMobileHomeBannerList(cloneBannerList);
    }, [banners]);

    const [homeBannelList, setHomeBannerList] = useState([]);
    const [mobileHomeBannelList, setMobileHomeBannerList] = useState([]);

    const referralDetail = {
        total: _.get(user, 'member.referral_history__c.count') | 0,
        numberOfReferral: _.get(user, 'member.referral_threshold__c.number_of_referral__c') | 0,
        diff:
            (_.get(user, 'member.referral_history__c.count') | 0) %
            (_.get(user, 'member.referral_threshold__c.number_of_referral__c') | 0),
    };

    useEffect(() => {
        let currentTierLevel = _.get(user, 'member.tier__c.level__c');
        if (!currentTierLevel) currentTierLevel = 0;

        let tmpList = [];
        tierRuleList.forEach(tierRule => {
            let targetTier;
            let isUpgrade = false;

            if (tierRule.tier[currentTierLevel + 1]) {
                isUpgrade = true;
                targetTier = _.find(tierRule.tier, { level__c: currentTierLevel + 1 });
            } else {
                isUpgrade = false;
                targetTier = _.find(tierRule.tier, { level__c: currentTierLevel });
            }

            if (!targetTier) return;

            let targetValue = _.get(targetTier.childs[0], 'from_amount__c');
            tmpList.push({
                table: _.get(tierRule, 'table'),
                current: _.get(tierRule, 'result'),
                target: targetValue,
                isUpgrade: isUpgrade,
            });
        });

        setUpgradeRequirementList(tmpList);
    }, [tierRuleList]); // eslint-disable-line react-hooks/exhaustive-deps

    const [upgradeRequirementList, setUpgradeRequirementList] = useState([]);

    const handleNavigateWeb = () => {
        window.open(LinkUrlMap[language.languageId], '_blank');
    };

    return (
        <div>
            <div className="ecom-dashboard-wrapper">
                <Helmet>
                    <title>Dashboard</title>
                    <meta name="description" content="Dashboard" />
                </Helmet>
                {tierRuleLoading || bannerLoading || rewardLoading ? (
                    <DashBoardGridLoader />
                ) : (
                    <div>
                        <div className="row mt-2">
                            <div className="col home-banner-container">
                                <SessionSlider data={homeBannelList} imageSlideConfig={imageSlideConfig} />
                            </div>
                            <div className="col mobile-home-banner-container">
                                <SessionSlider data={mobileHomeBannelList} imageSlideConfig={imageSlideConfig} />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-sm-12 col-md-12 col-lg-8">
                                <div className="row">
                                    <div className="my-2 col-sm-12 col-md-12">
                                        <Card customClass="dashboard-welcome-container">
                                            <div className="row justify-content-center align-items-center dashboard-card-container py-2">
                                                <div className="col-12 col-sm-2">
                                                    <div className="text-center w-100">
                                                        {_.get(user, 'member.tier__c.tier_logo_path__c') ? (
                                                            <img
                                                                src={_.get(user, 'member.tier__c.tier_logo_path__c')}
                                                                alt="Tier"
                                                                className="mt-2"
                                                                width="50"
                                                                height="50"
                                                            />
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-10  text-sm-left text-center">
                                                    {_.get(language, 'languageId') === 'english' ? (
                                                        <span className="text-center w-100">
                                                            {<IntlMessages id="dashboard.welcomeMsg" />}
                                                            {_.get(user, 'member.firstname')}
                                                        </span>
                                                    ) : (
                                                        <span className="text-center w-100">
                                                            {_.get(user, 'member.firstname')}
                                                            &nbsp;
                                                            {<IntlMessages id="dashboard.welcomeMsg" />}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="my-2 col-lg-12 col-xl-12 col-xxxl-6">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6 order-3">
                                                <Card
                                                    heading={<IntlMessages id="dashboard.referred" />}
                                                    customClass="shadow dashboard-mini-container"
                                                >
                                                    <div className="flex-container dashboard-card-container">
                                                        <div className="w-100 text-center mt-4 mb-3 d-flex">
                                                            <div className="text-center w-100 dashboard-card-title mx-2 p-3 ">
                                                                {_.get(user, 'member.referral_history__c.count') ? (
                                                                    <div>
                                                                        <i className="zmdi zmdi-caret-up zmdi-hc-lg text-success mr-2"></i>
                                                                        {_.get(
                                                                            user,
                                                                            'member.referral_history__c.count'
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div>0</div>
                                                                )}
                                                                <div className="dashboard-card-mini-text">
                                                                    {<IntlMessages id="dashboard.referredPeople" />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="col-sm-6 col-md-6 order-4">
                                                <Card
                                                    heading={<IntlMessages id="dashboard.referredMore1" />}
                                                    customClass="shadow dashboard-mini-container"
                                                >
                                                    <div className="flex-container dashboard-card-container">
                                                        <div className="w-100 text-center mt-4 mb-3 d-flex">
                                                            <div className="text-center w-100 dashboard-card-subtitle mt-2 p-3">
                                                                <div className="dashboard-card-center-text">
                                                                    {referralDetail.numberOfReferral}
                                                                </div>

                                                                <div>
                                                                    <span className="dashboard-card-mini-text">
                                                                        {<IntlMessages id="dashboard.referredMore2" />}
                                                                    </span>
                                                                    &nbsp;
                                                                    <span className="text-danger font-weight-bold">
                                                                        <i className="zmdi zmdi-toll"></i>
                                                                        &nbsp;
                                                                        {_.get(
                                                                            user,
                                                                            'member.referral_threshold__c.fixed_loyalty_point__c',
                                                                            '0'
                                                                        ) + ' '}
                                                                    </span>
                                                                    {/* <span className="dashboard-card-mini-text">
																		{<IntlMessages id="dashboard.point" />}
																	</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="col-sm-6 col-md-6 order-1">
                                                <Card
                                                    heading={<IntlMessages id="dashboard.loyaltyPointHistory" />}
                                                    customClass="shadow dashboard-mini-container"
                                                >
                                                    <div className="flex-container dashboard-card-container">
                                                        <div className="w-100 text-center d-flex">
                                                            <div className="text-center w-100 dashboard-card-subtitle mx-2 p-3">
                                                                <i className="zmdi zmdi-toll"></i>
                                                                &nbsp;
                                                                <NumberFormat
                                                                    value={
                                                                        _.get(user, 'member.total_loyalty_point__c') -
                                                                        _.get(user, 'member.redeemed_loyalty_point__c')
                                                                    }
                                                                    thousandSeparator={true}
                                                                    displayType={'text'}
                                                                ></NumberFormat>
                                                                <br />
                                                                <div className="dashboard-card-mini-text">
                                                                    <IntlMessages id="dashboard.loyaltyPointExpiryDate" />{' '}
                                                                    :
                                                                    <br />
                                                                    {_.get(user, 'member.loyalty_point_expired_date__c')
                                                                        ? moment(
                                                                              _.get(
                                                                                  user,
                                                                                  'member.loyalty_point_expired_date__c'
                                                                              )
                                                                          ).format('YYYY-MM-DD')
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-container flex-reverse">
                                                        {/* <Link
                                                            className="pr-4"
                                                            href="javascript:void(0);"
                                                            onClick={() => handleNavigateWeb()}
                                                        >
                                                            <IntlMessages id="profile.getMore" />
                                                        </Link> */}
                                                    </div>
                                                </Card>
                                            </div>
                                            <div className="col-sm-6 col-md-6 order-2">
                                                <Card
                                                    heading={<IntlMessages id="dashboard.pointStatus" />}
                                                    customClass="shadow dashboard-mini-container"
                                                >
                                                    <div className="flex-container dashboard-card-container">
                                                        <div className="w-100 text-center d-flex">
                                                            <div className="text-center w-100 dashboard-card-subtitle mx-2 p-3">
                                                                <div>
                                                                    <div className="w-100 dashboard-card-subtitle">
                                                                        <div className="text-center">
                                                                            <span className="mr-1">
                                                                                <i className="zmdi zmdi-caret-up zmdi-hc-lg text-success"></i>
                                                                            </span>
                                                                            <span className="ml-1">
                                                                                <i className="zmdi zmdi-toll"></i>
                                                                                &nbsp;
                                                                                {_.get(
                                                                                    user,
                                                                                    'member.loyalty_point_history__c.Increment'
                                                                                ) | 0}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-center w-100 dashboard-card-subtitle">
                                                                        <div className="text-center">
                                                                            <span className="mr-1">
                                                                                <i className="zmdi zmdi-caret-down zmdi-hc-lg text-warning"></i>
                                                                            </span>
                                                                            <span className="ml-1">
                                                                                <i className="zmdi zmdi-toll"></i>
                                                                                &nbsp;
                                                                                {_.get(
                                                                                    user,
                                                                                    'member.loyalty_point_history__c.Redemption'
                                                                                ) | 0}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-2 col-lg-12 col-xl-12 col-xxxl-6">
                                        <Card
                                            heading={
                                                <span>
                                                    <IntlMessages id="dashboard.tierRequirement" />
                                                    (
                                                    <IntlMessages
                                                        id={
                                                            'tier.' +
                                                            _.get(user, 'member.tier__c.name', '').toLowerCase()
                                                        }
                                                    />
                                                    )
                                                </span>
                                            }
                                            customClass="shadow dashboard-mid-container"
                                        >
                                            <div className="flex-container dashboard-card-container">
                                                <div className="w-100 h-100 mt-3 mb-3 px-5 overflow-auto">
                                                    {upgradeRequirementList.map((item, i) => {
                                                        return (
                                                            <div className="my-4 row" key={i}>
                                                                <div className="col-6">
                                                                    <div className="">
                                                                        {item.table === 'deposit_and_withdrawal__c' ? (
                                                                            <div>
                                                                                <div className="font-weight-bold">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.depositRequirement" />
                                                                                    }{' '}
                                                                                    :{' '}
                                                                                </div>
                                                                                <div className="my-2">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.requirementLine1" />
                                                                                    }
                                                                                    <br />
                                                                                    <div className="text-center w-100 text-success font-weight-bold">
                                                                                        <NumberFormat
                                                                                            value={item.current}
                                                                                            thousandSeparator={true}
                                                                                            displayType={'text'}
                                                                                        ></NumberFormat>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.requirementLine2" />
                                                                                    }
                                                                                    <div className="text-center w-100 text-danger font-weight-bold my-1">
                                                                                        <NumberFormat
                                                                                            value={
                                                                                                item.target -
                                                                                                item.current
                                                                                            }
                                                                                            thousandSeparator={true}
                                                                                            displayType={'text'}
                                                                                        ></NumberFormat>
                                                                                    </div>
                                                                                    {/* {item.isUpgrade ? <IntlMessages id="dashboard.requirementLine3" /> : <IntlMessages id="dashboard.requirementLine4" />} */}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div></div>
                                                                        )}
                                                                        {item.table === 'transaction_history__c' ? (
                                                                            <div>
                                                                                <div className="font-weight-bold">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.transactionRequirement" />
                                                                                    }{' '}
                                                                                    :{' '}
                                                                                </div>
                                                                                <div className="my-2">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.requirementLine1" />
                                                                                    }
                                                                                    <br />
                                                                                    <div className="text-center w-100 text-success font-weight-bold">
                                                                                        <NumberFormat
                                                                                            value={item.current}
                                                                                            thousandSeparator={true}
                                                                                            displayType={'text'}
                                                                                        ></NumberFormat>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.requirementLine2" />
                                                                                    }
                                                                                    <div className="text-center w-100 text-danger font-weight-bold my-1">
                                                                                        <NumberFormat
                                                                                            value={
                                                                                                item.target -
                                                                                                item.current
                                                                                            }
                                                                                            thousandSeparator={true}
                                                                                            displayType={'text'}
                                                                                        ></NumberFormat>
                                                                                    </div>
                                                                                    {/* {item.isUpgrade ? <IntlMessages id="dashboard.requirementLine3" /> : <IntlMessages id="dashboard.requirementLine4" />} */}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div></div>
                                                                        )}
                                                                        {item.table === 'loyalty_point_history__c' ? (
                                                                            <div>
                                                                                <div className="font-weight-bold">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.pointRequirement" />
                                                                                    }{' '}
                                                                                    :{' '}
                                                                                </div>
                                                                                <div className="my-2">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.requirementLine5" />
                                                                                    }
                                                                                    <br />
                                                                                    <div className="text-center w-100 text-success font-weight-bold">
                                                                                        <i className="zmdi zmdi-toll"></i>
                                                                                        &nbsp;
                                                                                        <NumberFormat
                                                                                            value={item.current}
                                                                                            thousandSeparator={true}
                                                                                            displayType={'text'}
                                                                                        ></NumberFormat>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="">
                                                                                    {
                                                                                        <IntlMessages id="dashboard.requirementLine6" />
                                                                                    }
                                                                                    <div className="text-center w-100 text-danger font-weight-bold my-1">
                                                                                        <i className="zmdi zmdi-toll"></i>
                                                                                        &nbsp;
                                                                                        <NumberFormat
                                                                                            value={
                                                                                                item.target -
                                                                                                item.current
                                                                                            }
                                                                                            thousandSeparator={true}
                                                                                            displayType={'text'}
                                                                                        ></NumberFormat>
                                                                                    </div>
                                                                                    {/* {item.isUpgrade ? <IntlMessages id="dashboard.requirementLine3" /> : <IntlMessages id="dashboard.requirementLine4" />} */}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div></div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 text-success m-auto">
                                                                    <CircularProgressWithLabel
                                                                        diff={item.current}
                                                                        number={item.target}
                                                                        isPercent={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className="my-2 col-sm-12 col-md-12 col-lg-4">
                                <Card
                                    heading={<IntlMessages id="dashboard.topFavoriteReward" />}
                                    customClass="shadow dashboard-hugh-container favorite-item-container"
                                >
                                    <TopFavoriteReward datas={topFavoriteRewardList} />
                                </Card>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default HomeDashboard;
