import React from 'react';
import { NotificationContainer } from 'react-notifications';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUser } from 'reducers/AuthReducer';

import RouteLayout from './RouteLayout';
import ThemeProvider from './ThemeProvider';

import SignIn from 'routes/session/login';
import SignUp from 'routes/session/register';
import Forgotpwd from 'routes/session/forgot-password';
import Changepwd from 'routes/session/change-password';
import NewMemberForm from 'routes/session/new-member-reg';
import SSOLogin from 'routes/session/SSO-login';
import CustomNotification from 'components/CustomNotification';
/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, authUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            authUser ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const App = ({ location, match }) => {
    const { user } = useSelector(state => ({
        user: getUser(state.auth),
    }));

    if (location.pathname === '/') {
        if (user === null) {
            return <Redirect to={'/signin'} />;
        } else {
            return <Redirect to={'/app/dashboard'} />;
        }
    }

    return (
        <div>
            <ThemeProvider>
                <NotificationContainer />
                <CustomNotification />
                <InitialPath path={`${match.url}app`} authUser={user} component={RouteLayout} />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/forgot-password" component={Forgotpwd} />
                <Route path="/change-password" component={Changepwd} />
                <Route path="/new-member-reg" component={NewMemberForm} />
                <Route path="/sso-login" component={SSOLogin} />
            </ThemeProvider>
        </div>
    );
};

export default App;
