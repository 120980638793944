import { CLOSE_DIALOG, OPEN_DIALOG } from 'actions/types';

const INIT_STATE = {
    open: false,
    id: '',
    orderedItem: null,
    title: '',
    content: '',
    onOk: () => {},
};

export const DialogReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case OPEN_DIALOG:
            return { ...state, ...action.payload };
        case CLOSE_DIALOG:
            return { ...state, open: false, orderedItem: null, id: '', title: '', content: '', onOk: () => {} };
        default:
            return { ...state };
    }
};
