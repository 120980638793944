import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import HomeDashboard from './home';

const Dashboard = ({ match }) => {
    return (
        <div className="dashboard-warpper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/home`} />
                <Route path={`${match.url}/home`} component={HomeDashboard} />
            </Switch>
        </div>
    );
};
export default Dashboard;
