import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import * as _ from 'lodash';
import Requirement from './Requirement';

import { useSelector } from 'react-redux';

import { getLanguage } from 'reducers/SettingReducer';

const RequirementList = ({ errors, intl }) => {
    const { language } = useSelector(state => ({
        language: getLanguage(state.setting),
    }));

    const [checkedMap, setCheckMap] = useState({});

    const updateCheckedMap = errors => {
        let types = _.get(errors, 'types');
        let matchs = _.get(types, 'matches');
        let min = _.get(types, 'min');

        let errorList = [];

        if (_.isArray(matchs)) {
            errorList = errorList.concat(errorList, matchs);
        } else if (_.isString(matchs)) {
            errorList.push(matchs);
        }

        if (_.isArray(min)) {
            errorList = errorList.concat(errorList, min);
        } else if (_.isString(min)) {
            errorList.push(min);
        }

        updateCheckedMapField(errorList);
    };

    const resetCheckedMap = () => {
        setCheckMap({
            [intl.formatMessage({ id: 'input.specialCharRequired' })]: true,
            [intl.formatMessage({ id: 'input.uppercaseRequired' })]: true,
            [intl.formatMessage({ id: 'input.lowercaseRequired' })]: true,
            [intl.formatMessage({ id: 'input.minLengthRequired' })]: true,
            [intl.formatMessage({ id: 'input.numberRequired' })]: true,
        });
    };

    const hardResetCheckedMap = () => {
        setCheckMap({});
    };

    const allTrue = obj => {
        for (var o in obj) if (!obj[o]) return false;

        return true;
    };

    useEffect(() => {
        hardResetCheckedMap();
        resetCheckedMap();
    }, [language]);

    const updateCheckedMapField = fields => {
        if (_.isArray(fields)) {
            fields.map(field => {
                if (Object.keys(checkedMap).includes(field)) {
                    setCheckMap(preMap => ({ ...preMap, [field]: false }));
                }
                return null;
            });
        } else if (_.isString(fields)) {
            setCheckMap(preMap => ({ ...preMap, [fields]: false }));
        }
    };

    useEffect(() => {
        resetCheckedMap();
        updateCheckedMap(errors);
    }, [errors]);

    return (
        <div className="pl-3 pt-2">
            {!_.isEmpty(errors) && !allTrue(checkedMap) && (
                <div className="text-left">
                    <h5>{intl.formatMessage({ id: 'widgets.passwordRequiremnt' })}</h5>
                </div>
            )}
            {!_.isEmpty(errors) &&
                !allTrue(checkedMap) &&
                Object.keys(checkedMap).map((error, key) => (
                    <div key={key}>
                        <Requirement isvalid={checkedMap[error]} message={error} />
                    </div>
                ))}
        </div>
    );
};
RequirementList.propTypes = {
    intl: intlShape.isRequired,
};
export default injectIntl(RequirementList);
