import React from 'react';
import { Button, createStyles } from '@material-ui/core';

const CustomButton = ({ children, loading, disabled, onClick }) => {
    const styles = useStyles();

    return (
        <Button
            style={disabled ? styles.buttonDisabled : styles.button}
            onClick={onClick}
            disabled={loading || disabled}
        >
            {children}
        </Button>
    );
};

export default CustomButton;

const useStyles = createStyles(() => ({
    button: {
        background: '#FFB800',
        borderRadius: '17px',
        fontWeight: 600,
        fontSize: '0.938rem',
        fontFamily: 'montserrat',
        color: '#444444',
    },
    buttonDisabled: {
        background: '#CACACA',
        borderRadius: '17px',
        fontWeight: 600,
        fontSize: '0.938rem',
        fontFamily: 'montserrat',
        color: '#808080',
    },
}));
