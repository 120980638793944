import React from 'react';
import { Route, withRouter } from 'react-router-dom';

import DefaultLayout from 'container/DefaultLayout';

import routerService from 'services/_routerService';

const RouteLayout = ({ match }) => {
    return (
        <DefaultLayout>
            {routerService &&
                routerService.map((route, key) => (
                    <Route key={key} path={`${match.url}/${route.path}`} component={route.component} />
                ))}
        </DefaultLayout>
    );
};

export default withRouter(RouteLayout);
