import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import * as _ from 'lodash';

import IntlMessages from 'util/IntlMessages';

import { textTruncate } from 'util/helper';
import { Card } from 'components/Card';

import { postFavoriteReward, deleteFavoriteReward } from 'actions';

const Item = ({ data, loading, match, favoriteId }) => {
    const [checked, setChecked] = React.useState(false);

    useEffect(() => {
        favoriteId ? setChecked(true) : setChecked(false);
    }, [favoriteId]);

    const dispatch = useDispatch();

    const item = {
        sfid: _.get(data, 'sfid', '') || '',
        name: _.get(data, 'reward_item__c.name', '') || '',
        image: _.get(data, 'reward_item__c.image_path__c', '') || '',
        price: _.get(data, 'required_loyalty_point__c', 0) || 0,
    };
    const handleCheckedChange = event => {
        if (event.target.checked) {
            dispatch(postFavoriteReward({ rewardId: item.sfid }));
            setChecked(true);
        } else {
            dispatch(deleteFavoriteReward(favoriteId));
            setChecked(false);
        }
    };

    return (
        <div>
            <Card customClass="item-container">
                <div className="overlay-wrap overflow-hidden">
                    <div className="text-center p-4">
                        <img src={item.image} /*className="img-fluid"*/ alt="product" width="200" height="200" />
                    </div>
                    <div>
                        <Link to={match.path + '/' + item.sfid} className="overlay-item-image flex-container">
                            <div className="text-center w-100 cart-link text-white py-2">
                                {<IntlMessages id="reward.viewDetail" />}
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="product-info border-top p-3">
                    <div>
                        <div className="flex-container reward-item-title">
                            {/* <div className="item-name-container"> */}
                            <h4 className="item-name-container text-dark w-100">{item.name}</h4>
                            {/* </div> */}
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            icon={<FavoriteBorder />}
                                            checkedIcon={<Favorite />}
                                            name="checkedH"
                                            onChange={handleCheckedChange}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex-container">
                        <h2 className="reward-card-title mb-0">
                            <i className="zmdi zmdi-toll"></i>
                            &nbsp;
                            <NumberFormat
                                value={item.price.toFixed(0)}
                                thousandSeparator={true}
                                displayType={'text'}
                            ></NumberFormat>
                            {/* {<IntlMessages id="reward.points" />} */}
                        </h2>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default withRouter(Item);
