import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { useDispatch, useSelector } from 'react-redux';

import { collapsedSidebarAction } from 'actions';

import { getNavCollapsed } from 'reducers/SettingReducer';

import LanguageProvider from './LanguageProvider';
import LogoutBottom from './LogoutButtom';
import MoreProvider from './MoreProvider';

const Header = ({ windowWidth }) => {
    const { navCollapsed } = useSelector(state => ({
        navCollapsed: getNavCollapsed(state.setting),
    }));

    const dispatch = useDispatch();

    const onToggleNavCollapsed = event => {
        dispatch(collapsedSidebarAction(!navCollapsed));
    };

    return (
        <AppBar elevation={0} position="static" className="rct-header">
            <Toolbar className="d-flex justify-content-between w-100 pl-0">
                <div className="d-inline-flex align-items-center">
                    <ul className="list-inline mb-0 navbar-left">
                        <li className="list-inline-item" onClick={e => onToggleNavCollapsed(e)}>
                            <Tooltip title="Sidebar Toggle" placement="bottom">
                                <IconButton color="inherit" mini="true" aria-label="Menu" className="humburger p-0">
                                    <MenuIcon />
                                </IconButton>
                            </Tooltip>
                        </li>
                    </ul>
                </div>
                <div className="navbar-right list-inline mb-0 mr-1">
                    <LanguageProvider />
                    <div className="pr-3 d-none d-sm-none d-md-flex">
                        <div className="pl-3">
                            <LogoutBottom />
                        </div>
                    </div>
                    <MoreProvider windowWidth={windowWidth} />
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default withRouter(Header);
