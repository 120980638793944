import React, { useState } from 'react';
import { DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import Tooltip from '@material-ui/core/Tooltip';

import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from 'actions';
import { getLanguages, getLanguage } from 'reducers/SettingReducer';

import AppConfig from 'constants/AppConfig';

const LanguageProvider = () => {
    const dispatch = useDispatch();

    const { languages, locale } = useSelector(state => ({
        languages: getLanguages(state.setting),
        locale: getLanguage(state.setting),
    }));

    const [state, setState] = useState({
        langDropdownOpen: false,
    });

    const toggle = () => {
        setState({ ...state, langDropdownOpen: !state.langDropdownOpen });
    };

    const onChangeLanguage = language => {
        setState({ ...state, langDropdownOpen: false });
        dispatch(setLanguage(language));
    };

    return (
        <Dropdown
            nav
            className="list-inline-item language-dropdown tour-step-5 pr-3"
            isOpen={state.langDropdownOpen}
            toggle={toggle}
        >
            <DropdownToggle caret nav className="header-icon language-icon">
                <Tooltip title="Languages" placement="bottom">
                    <span>{AppConfig.flagIcon[locale.icon]}</span>
                </Tooltip>
            </DropdownToggle>
            <DropdownMenu className="pt-0">
                <div className="dropdown-content">
                    <div className="dropdown-top d-flex justify-content-between rounded-top bg-primary p-3">
                        <span className="text-white font-weight-bold">Languages</span>
                    </div>
                    <Scrollbars className="rct-scroll" autoHeight autoHeightMin={50} autoHeightMax={280}>
                        <ul className="list-unstyled mb-0 dropdown-list">
                            {languages.map((language, key) => (
                                <li key={key} onClick={() => onChangeLanguage(language)}>
                                    <div onClick={e => e.preventDefault()}>
                                        <span className="flex-container ml-2">{language.name}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Scrollbars>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default LanguageProvider;
