import React from 'react';
import IntlMessages from 'util/IntlMessages';
import Notfound from 'assets/img/not-found.png';
import { createStyles } from '@material-ui/core';

const NotFound = ({ message }) => {
    const styles = useStyles();
    return (
        <div style={styles.wrapper}>
            <div>
                <IntlMessages id={message} />
            </div>
            <img src={Notfound} width={'100%'} alt="not-found" />
        </div>
    );
};

export default NotFound;

const useStyles = createStyles(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        color: '#585858',
    },
}));
