// sidebar nav links
const NavLinks = {
    category1: [
        {
            menu_title: 'sidebar.memberreg',
            menu_icon: 'zmdi zmdi-account',
            path: '/app/memberregister/form',
            new_item: false,
            child_routes: null,
            selected: false,
        },
        {
            menu_title: 'sidebar.dashboard',
            menu_icon: 'zmdi zmdi-view-dashboard',
            path: '/app/dashboard/home',
            new_item: false,
            child_routes: null,
            selected: false,
        },
        {
            menu_title: 'sidebar.profile',
            menu_icon: 'zmdi zmdi-account-box',
            path: '/app/users/profile',
            new_item: false,
            child_routes: null,
            selected: false,
        },
        {
            menu_title: 'sidebar.redemption',
            menu_icon: 'zmdi zmdi-shopping-basket',
            path: '/app/redemption/award',
            new_item: false,
            child_routes: null,
            selected: false,
        },
        // {
        //     menu_title: 'sidebar.redemptionNew',
        //     menu_icon: 'zmdi zmdi-shopping-basket',
        //     path: '/app/redemption/award-mobile',
        //     new_item: false,
        //     child_routes: null,
        //     selected: false,
        // },
        {
            menu_title: 'sidebar.redemptionHistory',
            menu_icon: 'zmdi zmdi-bookmark',
            path: '/app/redemption/history',
            new_item: false,
            child_routes: null,
            selected: false,
        },
        // {
        //     menu_title: 'sidebar.redemptionHistoryNew',
        //     menu_icon: 'zmdi zmdi-bookmark',
        //     path: '/app/redemption/history-mobile',
        //     new_item: false,
        //     child_routes: null,
        //     selected: false,
        // },
        {
            menu_title: 'sidebar.pointHistory',
            menu_icon: 'zmdi zmdi-balance-wallet',
            path: '/app/memberpoint/point',
            new_item: false,
            child_routes: null,
            selected: false,
        },
        // {
        //     menu_title: 'sidebar.pointHistoryNew',
        //     menu_icon: 'zmdi zmdi-balance-wallet',
        //     path: '/app/memberpoint/point-mobile',
        //     new_item: false,
        //     child_routes: null,
        //     selected: false,
        // },
        // {
        //     menu_title: 'sidebar.promotion',
        //     menu_icon: 'zmdi zmdi-star',
        //     path: '/app/promotion',
        //     new_item: false,
        //     child_routes: null,
        //     selected: false,
        // },
    ],
};

export default NavLinks;
