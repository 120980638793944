import React from 'react';

const CardHeading = ({ title, link, removeLine }) => (
    <div className="text-center pt-3 rct-block-title">
        <h5>
            <div className="pb-3">{title}</div>
            <div className="contextual-link">{link}</div>
            {/* {
                !removeLine&&
                <div className="bg-white m-2 px-4 mx-5"/>
            } */}
        </h5>
    </div>
);

export { CardHeading };
