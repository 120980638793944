import React from 'react';
import { IconButton, InputBase, Paper } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

const SearchInput = ({ value, onChange, onSearch, placeholder, style }) => {
    return (
        <Paper
            style={{
                borderRadius: '32px',
                padding: '0 16px',
                height: '40px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexShrink: 0,
                border: '1px solid #CACACA',
                background: '#F7F7F7',
                ...style,
            }}
        >
            <InputBase
                value={value}
                placeholder={placeholder}
                onChange={e => onChange(e.target.value)}
                style={{ fontFamily: 'montserrat', color: '#585858', fontSize: '16px' }}
            />
            <IconButton type="button" onClick={onSearch}>
                <SearchOutlined />
            </IconButton>
        </Paper>
    );
};

export default SearchInput;
