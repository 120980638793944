import { COLLAPSED_SIDEBAR, SET_LANGUAGE } from 'actions/types';

import AppConfig from 'constants/AppConfig';

const INIT_STATE = {
    navCollapsed: AppConfig.navCollapsed,
    locale: localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')) : AppConfig.locale,
    languages: [
        {
            languageId: 'english',
            locale: 'en',
            name: 'English',
            icon: 'en',
            short: 'EN',
        },
        {
            languageId: 'chinese',
            locale: 'zh',
            name: '简体中文',
            icon: 'zh',
            short: 'SC',
        },
        {
            languageId: 'tchinese',
            locale: 'tzh',
            name: '繁體中文',
            icon: 'tzh',
            short: 'TC',
        },
    ],
};

export const SettingReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case COLLAPSED_SIDEBAR:
            return { ...state, navCollapsed: action.isCollapsed };

        case SET_LANGUAGE:
            return { ...state, locale: action.payload };

        default:
            return { ...state };
    }
};

//selector
export const getNavCollapsed = state => state.navCollapsed;
export const getLanguages = state => state.languages;
export const getLanguage = state => state.locale;
