import React, { useState } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getUser } from 'reducers/AuthReducer';
import * as _ from 'lodash';

import AppConfig from 'constants/AppConfig';

const UserBlock = () => {
    const [state, setState] = useState({
        userDropdownMenu: false,
        isSupportModal: false,
    });

    const { user } = useSelector(state => ({
        user: getUser(state.auth),
    }));

    const toggleUserDropdownMenu = () => {
        setState({ ...state, userDropdownMenu: !state.userDropdownMenu });
    };

    return (
        <div className="top-sidebar">
            <div className="sidebar-user-block">
                <div className="rct-dropdown d-flex align-items-center">
                    <div className="user-profile">
                        <img
                            src={AppConfig.sidebarProfileImage}
                            alt="user profile"
                            className="img-fluid rounded-circle"
                            width="50"
                            height="100"
                        />
                    </div>
                    <div className="user-info">
                        <span className="user-name ml-4">{_.get(user, 'member.name')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserBlock;
