import { TOGGLE_MENU, SELECT_MENU } from 'actions/types';
import update from 'immutability-helper';

import NavLinks from 'components/Sidebar/NavLinks';

const INIT_STATE = {
    sidebarMenus: NavLinks,
};

export const SidebarReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_MENU:
            let index = state.sidebarMenus[action.payload.stateCategory].indexOf(action.payload.menu);
            for (var key in state.sidebarMenus) {
                var obj = state.sidebarMenus[key];
                for (let i = 0; i < obj.length; i++) {
                    const element = obj[i];
                    if (element.open) {
                        if (key === action.payload.stateCategory) {
                            return update(state, {
                                sidebarMenus: {
                                    [key]: {
                                        [i]: {
                                            open: { $set: false },
                                        },
                                        [index]: {
                                            open: { $set: !action.payload.menu.open },
                                        },
                                    },
                                },
                            });
                        } else {
                            return update(state, {
                                sidebarMenus: {
                                    [key]: {
                                        [i]: {
                                            open: { $set: false },
                                        },
                                    },
                                    [action.payload.stateCategory]: {
                                        [index]: {
                                            open: { $set: !action.payload.menu.open },
                                        },
                                    },
                                },
                            });
                        }
                    }
                }
            }
            return update(state, {
                sidebarMenus: {
                    [action.payload.stateCategory]: {
                        [index]: {
                            open: { $set: !action.payload.menu.open },
                        },
                    },
                },
            });
        case SELECT_MENU:
            let result = state;
            for (let key in state.sidebarMenus.category1) {
                result = update(result, {
                    sidebarMenus: {
                        category1: {
                            [key]: {
                                selected: { $set: false },
                            },
                        },
                    },
                });
            }
            return update(result, {
                sidebarMenus: {
                    category1: {
                        [action.payload]: {
                            selected: { $set: true },
                        },
                    },
                },
            });
        default:
            return { ...state };
    }
};

//selector
export const getSidebarMenus = state => state.sidebarMenus;
