import React from 'react';

const CustomTabs = ({ items, activeTab, setActiveTab }) => {
    const find = items.find(item => item.tabId === activeTab);

    return (
        <div className="tabs">
            <div className="tab-title">
                {items.length > 0 &&
                    items.map((item, idx) => (
                        <div
                            key={idx}
                            className={activeTab === item.tabId ? 'title-wrapper-active' : 'title-wrapper'}
                            onClick={() => setActiveTab(item.tabId)}
                        >
                            {item.title}
                        </div>
                    ))}
            </div>
            <div className="tab-content">{find?.content}</div>
        </div>
    );
};

export default CustomTabs;
