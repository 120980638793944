import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { injectIntl, intlShape } from 'react-intl';

import { getLanguages } from 'reducers/SettingReducer';

import { validateSSOLogin, setLanguage, logout } from 'actions';
import * as _ from 'lodash';

const SSOLogin = ({ history, intl }) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    let accessToken = searchParams.get('accessToken');
    let language = searchParams.get('language');
    let redirectUrl = searchParams.get('redirect');
    let inAppEntity= searchParams.getAll('entity');

    const { languages } = useSelector(state => ({
        languages: getLanguages(state.setting),
    }));

    useEffect(() => {
        //dispatch the action and verify the access token
        if (!!accessToken) {
            dispatch(validateSSOLogin(history, redirectUrl, accessToken, inAppEntity));
            if (!!language) {
                //update language setting
                let [currentLanguage] = _.filter(languages, { short: language.toUpperCase() });
                dispatch(setLanguage(currentLanguage));
            }
        } else {
            dispatch(logout());
            history.push('/signin');
        }
    }, []);

    return null;
};

SSOLogin.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(SSOLogin);
