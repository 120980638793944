import React from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';

import { getCrmLoading } from 'reducers/AuthReducer';
const CRMLoginButton = ({ accountEmail, id, action, entity }) => {
    const dispatch = useDispatch();

    const { crmLoading } = useSelector(state => ({
        crmLoading: getCrmLoading(state.auth),
    }));

    const handleOnClick = () => {
        console.log('handleOnClick', accountEmail, entity);
        dispatch(action({ accountEmail, entity }));
    };

    return (
        <Button disabled={crmLoading} fullWidth variant="contained" color="primary" onClick={handleOnClick}>
            <IntlMessages id={id || 'widgets.ecrmLogin'} />
        </Button>
    );
};

export default CRMLoginButton;
