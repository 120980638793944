import AppConfig from 'constants/AppConfig';
const zhMessages = {
    'checkout.shippingForm': '运输表格',
    'checkout.rewardRedeem': '奖励换领',
    'checkout.itemname': '名称',
    'checkout.quantity': '数量',
    'checkout.username': '用户名称',
    'checkout.phonenumber': '电话号码',
    'checkout.addressLine1': '地址 1',
    'checkout.addressLine2': '地址 2',
    'checkout.city': '城市',
    'checkout.stateRegion': '州/地区',
    'checkout.redemption': '换取',
    'checkout.country': '国家',
    'checkout.orderNo': '编号',
    'checkout.ok': '确定',

    'checkout.placeholder.username': '输入您的姓名',
    'checkout.placeholder.phoneNumber': '输入您的电话号码',
    'checkout.placeholder.addressLine1': '输入您的地址第1行',
    'checkout.placeholder.addressLine2': '輸入您的地址第2行',
    'checkout.placeholder.city': '输入您的城市',
    'checkout.placeholder.stateRegion': '输入您所在的州/地区',

    'memberpoint.point': '积分',
    'memberpoint.pointTable': '会员积分',
    'memberpoint.pointHistory': '积分记录',
    'memberpoint.pointTransactionHistory': '积分交易记录',
    'memberpoint.historyId': '编号',
    'memberpoint.pointTransaction': '积分交易#',
    'memberpoint.title': '类型',
    'memberpoint.dateTime': '日期和时间',
    'memberpoint.entity': '业务单位',
    'memberpoint.source': '来源',

    'memberpoint.increment': '增加',
    'memberpoint.redemption': '兑换',
    'memberpoint.systemAdjustment': '系统调整',
    'memberpoint.deposit': '入金',
    'memberpoint.transaction': '交易',
    'memberpoint.referral': '推荐',
    'memberpoint.noHistoryFound': '您尚未有积分记录',

    'redemption.history': '兌換紀录',
    'redemption.historyTable': '兌換紀录',
    'redemption.redemptionId': '编号',
    'redemption.redemptionOrders': '编号',
    'redemption.redemptionDate': '兑换日期',
    'redemption.rewardName': '兑换产品',
    'redemption.quantity': '数量',
    'redemption.redemptionEntity': '业务单位',
    'redemption.redemptionPoint': '积分',
    'redemption.totalMemberPoint': '积分',
    'redemption.status': '状态',
    'redemption.status.Pending': '审核中',
    'redemption.status.Cancelled': '取消',
    'redemption.status.Confirmed': '确认',
    'redemption.status.Processing': '处理中',
    'redemption.status.Completed': '完成',
    'redemption.noRewardFound': '对不起，没有找到匹配的奖励',
    'redemption.noHistoryFound': '您尚未有兑换记录',
    'redemption.processing': '处理中',

    'profile.title': '用户资料',
    'profile.childAccount': '用户系统',
    'profile.referralHistory': '推荐记录',
    'profile.tierLevel': '层级',
    'profile.loyaltyPoint': '积分',
    'profile.loyaltyPointExpiryDate': '积分有效日期(年-月-日)',
    'profile.referralChat': '累积推荐人数',
    'profile.pointChart': '累积积分',
    'profile.pointAccountChart': '累积积分分布',
    'profile.accountName': '用户名称',
    'profile.referralNumber': '推荐号码',
    'profile.entity': '所属账号',
    'profile.email': '登记电邮',
    'profile.memberCode': '会员编号',
    'profile.referredMember': '推荐会员',
    'profile.referredDate': '推荐日期',
    'profile.verifiedDate': '验证日期',
    'profile.changePassword': '更改密码',
    'profile.getMore': '获取更多',
    'profile.qrcodeTitle': '推荐二维码:',
    'profile.copyLink': '复制二维码链接',

    'reward.filter': '筛选条件',
    'reward.searchPH': '您在寻找...',
    'reward.itemName': '奖励名称',
    'reward.search': '搜索...',
    'reward.pointRange': '积分范围',
    'reward.tagging': 'Tagging',
    'reward.confirm': '确认',
    'reward.selectedTagging': '选定的标签',
    'reward.availableTagging': '可用的标签',
    'reward.noTagging': '未选择标签',
    'reward.viewDetail': '查看详情',
    'reward.points': '积分',
    'reward.result': '結果',
    'reward.showMore': '展示更多',
    'reward.showLess': '展示较少',
    'reward.details': '详情',

    'reward.rewardDetail': '奖励详细',
    'reward.back': '上一页',
    'reward.redeem': '换取奖励',
    'reward.redeemSuccessTitle': '已提交兑换申请',
    'reward.redeemSuccessContent': '惊喜礼遇尽在EFSG Rewards！您可于兑换记录查询兑换状态。',

    'reward.itemCode': '奖励编号',
    'reward.itemDescription': '奖励简介',
    'reward.itemRemark': '备注',
    'reward.shippingInfo': '运输信息',
    'reward.itemTC': '条款及细则',
    'reward.noInformation': '无可用信息',

    'dashboard.dashboard': '会员概览',
    'dashboard.welcomeMsg': '欢迎回来!',
    'dashboard.tier': '层级',
    'dashboard.referralHistory': '推荐记录',
    'dashboard.loyaltyPointHistory': '积分纪录',
    'dashboard.pointStatus': '每月表现',
    'dashboard.loyaltyPointExpiryDate': '有效日期',
    'dashboard.referred': '您已推荐',
    'dashboard.referredPeople': '次数',
    'dashboard.referredMore1': '推荐额外',
    'dashboard.referredMore2': '人领取',
    'dashboard.point': '积分',
    'dashboard.tierRequirement': '升级要求',
    'dashboard.depositRequirement': '存款要求',
    'dashboard.transactionRequirement': '交易要求',
    'dashboard.pointRequirement': '积分要求',
    'dashboard.requirementLine1': '现有(美金)',
    'dashboard.requirementLine2': '升级需要(美金)',
    'dashboard.requirementLine3': '升级下一层!',
    'dashboard.requirementLine4': '保持当前等级!',
    'dashboard.requirementLine5': '现有积分',
    'dashboard.requirementLine6': '升级需要(积分)',
    'dashboard.topFavoriteReward': '最受欢迎的奖励',

    'login.memberLogin': '会员登入',
    'login.signIn': '登入',
    'login.forgotPassword': '忘记密码',
    'login.enterEmail': '输入电邮地址',
    'login.enterPassword': '输入密码',
    'login.termOfService': '服务条款',
    'login.right': `© ${AppConfig.year} ${AppConfig.brandName} 版权所有`,

    'changepw.changePassword': '更改密码',
    'changepw.email': '电子邮件地址',
    'changepw.oldPassword': '旧密码',
    'changepw.newPassword': '新密码',
    'changepw.confirmPassword': '重复输入密码',
    'changepw.confirm': '确认',

    'forgot.enterEmail': '输入电邮地址',
    'forgot.enterConfirmationCode': '验证码',
    'forgot.enterNewPassword': '新密码',
    'forgot.enterConfirmationPassword': '确认密码',
    'forgot.resetPassword': '重设密码',
    'forgot.getCode': '取得验证码',
    'forgot.toLogin': '已经有账号了？',

    'sidebar.profile': '个人资料 / 快速登入',
    'sidebar.redemption': '换取奖励',
    'sidebar.redemptionNew': 'Redemption Mobile',
    'sidebar.redemptionItem': '奖励',
    'sidebar.redemptionHistory': '兌換紀录',
    'sidebar.redemptionHistoryNew': 'Redemption History Mobile',
    'sidebar.pointNew': 'Member Point Mobile',
    'sidebar.point': '积分',
    'sidebar.pointHistory': '积分记录',
    'sidebar.pointHistoryNew': 'Points History Mobile',
    'sidebar.promotion': '夏日狂欢大抽奖',
    'sidebar.tier': '会员分类',
    'sidebar.tierHistory': '分类记录',
    'sidebar.ecrmLogin': 'eCRM 登入',
    'sidebar.loginPost': '登入',
    'sidebar.loginPre': '后台快速',

    'sidebar.dashboard': '会员概览',
    'sidebar.memberreg': '管理账户',
    'sidebar.modules': '模块',
    'widgets.logOut': '登出',
    'widgets.ecrmLogin': 'eCRM 登入',
    'widgets.eblLogin': '下载程式',
    'widgets.eieLogin': 'HCRM 登入',
    'widgets.eblmf4Login': 'BCRM 登入',
    'widgets.popupAlertTips':
        '为了让您有更好的Safari浏览体验，我们建议您使用我们的「快捷登入键」前完成以下iPhone设定: \n 1. 开启「设定」\n 2. 选择「Safari」\n 3. 关闭 「阻挡弹出式视窗」',
    'widgets.action': '后台快速登入',
    'widgets.passwordRequiremnt': '密码要求：',

    'error.default': '错误。 请稍后再试',
    'error.10001': '用户名和密码与策略不匹配',
    'error.10002': '无效的用户名和密码',
    'error.10003': '未确认用户',
    'error.10004': '用户名已存在',
    'error.10005': '确认代码已过期',
    'error.10006': '确认码不匹配',
    'error.10007': '成员未激活',
    'error.10008': '用户不存在',
    'error.10009': '需要新密码',
    'error.10010': '未找到会话',
    'error.10011': '用户已禁用。请联系管理员',
    'error.10012': '超过密码尝试次数。 请稍后再试 ',
    'error.10013': '用户已过期。请更改密码',
    'error.10017': '密码已过期。请更改密码',

    'input.emailRequired': '需要电子邮件',
    'input.emailRestricted': '必须是一个电子邮件',
    'input.passwordRequired': '需要密码',
    'input.passwordMatched': '密码必须匹配',
    'input.confirmationCodeRequired': '需要确认码”',
    'input.specialCharRequired': '需要特殊字符',
    'input.numberRequired': '需要数字',
    'input.uppercaseRequired': '需要大写字母',
    'input.lowercaseRequired': '需要小写字母',
    'input.minLengthRequired': '必须至少8个字符',
    'input.digitOnly': '只能有数字',

    'table.body.noMatch': '暂无相关纪录',
    'table.body.sort': '排序',
    'table.body.sorFor': '排序为',
    'table.pagination.next': '下一页',
    'table.pagination.previous': '上一页',
    'table.pagination.rowsPerPage': '每页行数：',
    'table.pagination.displayRows': '分',
    'table.toolbar.search': '搜索',
    'table.toolbar.downloadCsv': '下载CSV',
    'table.toolbar.print': '打印',
    'table.toolbar.viewColumns': '查看列',
    'table.toolbar.filterTable': '筛选表',
    'table.filter.all': '全部”',
    'table.filter.title': '筛选',
    'table.filter.reset': '重置',
    'table.viewColumns.title': '显示列',
    'table.viewColumns.titleAria': '显示/隐藏表列',
    'table.selectedRows.text': '已选择的行',
    'table.selectedRows.delete': '删除',
    'table.selectedRows.deleteAria': '删除选定的行',

    'tier.platinum': '白金',
    'tier.diamond': '钻石',
    'tier.gold': '黃金',
    'tier.silver': '白银',
    'tier.': '',

    'memberreg.form.not_member': '成为会员',
    'memberreg.form.title': '添加现有交易账户',
    'memberreg.form.title_sign_up': '现有客户登记',
    'memberreg.form.title_new_customer': '全新客户申请',
    'memberreg.form.title_new_customer_url': 'https://www.empfs.com/zh-CN/form/bullion-personal',
    'memberreg.form.membershipCode': '会员号码',
    'memberreg.form.invalid_email': '电邮地址无效',
    'memberreg.form.entity.entity_EBL': 'EBL',
    'memberreg.form.entity.entity_EIE': 'EIE',
    'memberreg.form.entity.entity_UK': 'UK',
    'memberreg.form.entity.entity_EBL_MF4': 'EBL 2.0',
    'memberreg.form.entity.entity_EBL.email': 'EBL登记电邮地址',
    'memberreg.form.entity.entity_EIE.email': 'EIE登记电邮地址',
    'memberreg.form.entity.entity_EBL_MF4.email': 'EBL 2.0 登记电邮地址',
    'memberreg.form.mobile': '手提电话号码',
    'memberreg.form.mobile_area': '电话区号',
    'memberreg.form.mobile_otp': '接收一次性密码',
    'memberreg.form.mobile_otp_verify': '验证',
    'memberreg.form.mobile_otp_verified': '已验证',
    'memberreg.form.mobile_otp_invalid': '一次性密码无效',
    'memberreg.form.mobile_otp_cancel': '取消',
    'memberreg.form.mobile_otp_confirm': '确认',

    'memberreg.form.preferred_email': '会员电邮',
    'memberreg.form.application_processing': '我们正在处理您的申请。如需协助，欢迎与我们联络。',
    'memberreg.form.info.accounts': '我们会以此电邮地址登记积分计划，并用以统一您的交易账户之积分计算。',
    'memberreg.form.info.communications': '通过电子邮件接收我们的最新优惠及活动资讯。',
    'memberreg.form.terms_conditions': '登记会员后，即表示您同意会员计划内的 {terms} 以及 {privacy} 。',
    'memberreg.form.terms_conditions_url': '条款和条件',
    'memberreg.form.privacy_url': '隐私政策',
    'memberreg.form.thanks.message': '感谢您的登记，我们的团队会尽快与您联络。',
    'memberreg.form.thanks.promotion': '最新推广活动',
    'memberreg.form.submit_button': '提交',

    'promotion.luckydraw.event': '抽奖活动',
};

export default zhMessages;
