import React from 'react';
import ContentLoader from 'react-content-loader';

const RewardItemListLoader = props => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={1000}
        // viewBox="0 0 100% 1000"
        backgroundColor="#d9d9d9"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="3%" y="0" rx="6" ry="6" width="30%" height="400" />
        <rect x="35%" y="0" rx="6" ry="6" width="30%" height="400" />
        <rect x="67%" y="0" rx="6" ry="6" width="30%" height="400" />
        <rect x="3%" y="420" rx="6" ry="6" width="30%" height="400" />
        <rect x="35%" y="420" rx="6" ry="6" width="30%" height="400" />
        <rect x="67%" y="420" rx="6" ry="6" width="30%" height="400" />

        <circle cx="45%" cy="900" r="10" />
        <circle cx="50%" cy="900" r="18" />
        <circle cx="55%" cy="900" r="10" />
    </ContentLoader>
);

export default RewardItemListLoader;
