import React from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';

import { logout } from 'actions';

import IntlMessages from 'util/IntlMessages';

const LogoutBottom = () => {
    const dispatch = useDispatch();

    const logoutUser = e => {
        e.preventDefault();
        dispatch(logout());
    };

    return (
        <Button variant="contained" color="secondary" onClick={e => logoutUser(e)}>
            <IntlMessages id="widgets.logOut" />
        </Button>
    );
};

export default LogoutBottom;
