import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const CircularProgressWithLabel = ({ diff, number, isPercent }) => {
    let absDiff = diff > 0 ? diff : 0;

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                color="inherit"
                variant="determinate"
                value={diff > 0 ? (diff / number) * 100 : 0}
                size="100px"
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">
                    <div className="circular-progress-text">
                        {`${Math.round((absDiff / number) * 100)}`}
                        {isPercent ? '%' : ''}
                    </div>
                </Typography>
            </Box>
        </Box>
    );
};

export default CircularProgressWithLabel;
