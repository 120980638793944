import Dashboard from 'routes/dashboard';
import Redemption from 'routes/redemption';
import Memberpoint from 'routes/memberpoint';
import Profile from 'routes/profile';
import MemberRegister from 'routes/memberregister';
import Promotion from 'routes/promotion';

const array = [
    {
        path: 'dashboard',
        component: Dashboard,
    },
    {
        path: 'redemption',
        component: Redemption,
    },
    {
        path: 'memberpoint',
        component: Memberpoint,
    },
    {
        path: 'users',
        component: Profile,
    },
    {
        path: 'memberregister',
        component: MemberRegister,
    },
    {
        path: 'promotion',
        component: Promotion,
    },
];

export default array;
