// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';

export const FORGOT_USER_PW = 'FORGOT_USER_PW';
export const FORGOT_USER_PW_SUCCESS = 'FORGOT_USER_PW_SUCCESS';
export const FORGOT_USER_PW_FAILURE = 'FORGOT_USER_PW_FAILURE';

export const CONFIRMATION_FORGOT_USER_PW = 'CONFIRMATION_FORGOT_USER_PW';
export const CONFIRMATION_FORGOT_USER_PW_SUCCESS = 'CONFIRMATION_FORGOT_USER_PW_SUCCESS';
export const CONFIRMATION_FORGOT_USER_PW_FAILURE = 'CONFIRMATION_FORGOT_USER_PW_FAILURE';

export const CHANGE_USER_PW = 'CHANGE_USER_PW';
export const CHANGE_USER_PW_SUCCESS = 'CHANGE_USER_PW_SUCCESS';
export const CHANGE_USER_PW_FAILURE = 'CHANGE_USER_PW_FAILURE';

export const VALIDATE_USER = 'VALIDATE_USER';
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
export const VALIDATE_USER_FAILURE = 'VALIDATE_USER_FAILURE';

export const SSO_LOGIN = 'SSO_LOGIN';
export const SSO_LOGIN_SUCCESS = 'SSO_LOGIN_SUCCESS';
export const SSO_LOGIN_FAILURE = 'SSO_LOGIN_FAILURE';

// Main Actions
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';

//redux
export const SET_LANGUAGE = 'SET_LANGUAGE';

//sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SELECT_MENU = 'SELECT_MENU';

//Favorite Reward
export const GET_FAVORITE_REWARD = 'GET_FAVORITE_REWARD';
export const GET_FAVORITE_REWARD_SUCCESS = 'GET_FAVORITE_REWARD_SUCCESS';
export const GET_FAVORITE_REWARD_FAILURE = 'GET_FAVORITE_REWARD_FAILURE';

export const POST_FAVORITE_REWARD = 'POST_FAVORITE_REWARD';
export const POST_FAVORITE_REWARD_SUCCESS = 'POST_FAVORITE_REWARD_SUCCESS';
export const POST_FAVORITE_REWARD_FAILURE = 'POST_FAVORITE_REWARD_FAILURE';

export const DELETE_FAVORITE_REWARD = 'DELTE_FAVORITE_REWARD';
export const DELETE_FAVORITE_REWARD_SUCCESS = 'DELETE_FAVORITE_REWARD_SUCCESS';
export const DELETE_FAVORITE_REWARD_FAILURE = 'DELETE_FAVORITE_REWARD_FAILURE';

export const GET_TOP_FAVORITE_REWARD = 'GET_TOP_FAVORITE_REWARD';
export const GET_TOP_FAVORITE_REWARD_SUCCESS = 'GET_TOP_FAVORITE_REWARD_SUCCESS';
export const GET_TOP_FAVORITE_REWARD_FAILURE = 'GET_TOP_FAVORITE_REWARD_FAILURE';

//Loyalty Point History
export const GET_LOYALTY_POINT_HISTORY = 'GET_LOYALTY_POINT_HISTORY';
export const GET_LOYALTY_POINT_HISTORY_SUCCESS = 'GET_LOYALTY_POINT_HISTORY_SUCCESS';
export const GET_LOYALTY_POINT_HISTORY_FAILURE = 'GET_LOYALTY_POINT_HISTORY_FAILURE';

//Loyalty portal config
export const GET_LOYALTY_PORTAL_CONFIG = 'GET_LOYALTY_PORTAL_CONFIG';
export const GET_LOYALTY_PORTAL_CONFIG_SUCCESS = 'GET_LOYALTY_PORTAL_CONFIG_SUCCESS';
export const GET_LOYALTY_PORTAL_CONFIG_FAILURE = 'GET_LOYALTY_PORTAL_CONFIG_FAILURE';

//Member
export const GET_CHILD_ACCOUNT = 'GET_CHILD_ACCOUNT';
export const GET_CHILD_ACCOUNT_SUCCESS = 'GET_CHILD_ACCOUNT_SUCCESS';
export const GET_CHILD_ACCOUNT_FAILURE = 'GET_CHILD_ACCOUNT_FAILURE';

export const GET_REFERRAL_HISTORY = 'GET_REFERRAL_HISTORY';
export const GET_REFERRAL_HISTORY_SUCCESS = 'GET_REFERRAL_HISTORY_SUCCESS';
export const GET_REFERRAL_HISTORY_FAILURE = 'GET_REFERRAL_HISTORY_FAILURE';

export const GET_YEARLY_RESULT = 'GET_YEARLY_RESULT';
export const GET_YEARLY_RESULT_SUCCESS = 'GET_YEARLY_RESULT_SUCCESS';
export const GET_YEARLY_RESULT_FAILURE = 'GET_YEARLY_RESULT_FAILURE';

//Redemption
export const GET_REDEMPTION = 'GET_REDEMPTION';
export const GET_REDEMPTION_SUCCESS = 'GET_REDEMPTION_SUCCESS';
export const GET_REDEMPTION_FAILURE = 'GET_REDEMPTION_FAILURE';

export const POST_REDEMPTION = 'POST_REDEMPTION';
export const POST_REDEMPTION_SUCCESS = 'POST_REDEMPTION_SUCCESS';
export const POST_REDEMPTION_FAILURE = 'POST_REDEMPTION_FAILURE';

//Reward
export const GET_REWARD = 'GET_REWARD';
export const GET_REWARD_SUCCESS = 'GET_REWARD_SUCCESS';
export const GET_REWARD_FAILURE = 'GET_REWARD_FAILURE';
export const SET_REWARD_FILTER = 'SET_REWARD_FILTER';
export const SET_REWARD_INPUT_PARAMS = 'SET_REWARD_INPUT_PARAMS';
export const GET_CURRENT_REWARD = 'GET_CURRENT_REWARD';
export const GET_CURRENT_REWARD_SUCCESS = 'GET_CURRENT_REWARD_SUCCESS';
export const GET_CURRENT_REWARD_FAILURE = 'GET_CURRENT_REWARD_FAILURE';

//Tier Rule
export const GET_TIER_RULE = 'GET_TIER_RULE';
export const GET_TIER_RULE_SUCCESS = 'GET_TIER_RULE_SUCCESS';
export const GET_TIER_RULE_FAILURE = 'GET_TIER_RULE_FAILURE';

//crm login
export const REDIRECT_CRM = 'REDIRECT_CRM';
export const REDIRECT_CRM_SUCCESS = 'REDIRECT_CRM_SUCCESS';
export const REDIRECT_CRM_FAILED = 'REDIRECT_CRM_FAILED';

//memberreg
export const GET_MEMBER_REG_RECORD = 'GET_MEMBER_REG_RECORD';
export const GET_MEMBER_REG_RECORD_SUCCESS = 'GET_MEMBER_REG_RECORD_SUCCESS';
export const GET_MEMBER_REG_RECORD_FAILURE = 'GET_MEMBER_REG_RECORD_FAILURE';

export const POST_MEMBER_REG_RECORD = 'POST_MEMBER_REG_RECORD';
export const POST_MEMBER_REG_RECORD_SUCCESS = 'POST_MEMBER_REG_RECORD_SUCCESS';
export const POST_MEMBER_REG_RECORD_FAILURE = 'POST_MEMBER_REG_RECORD_FAILURE';

//memberreg
export const GET_ACCOUNT_RECORD = 'GET_ACCOUNT_RECORD';
export const GET_ACCOUNT_RECORD_SUCCESS = 'GET_ACCOUNT_RECORD_SUCCESS';
export const GET_ACCOUNT_RECORD_FAILURE = 'GET_ACCOUNT_RECORD_FAILURE';

//promotion
export const GET_PROMOTION_LUCKYDRAW = 'GET_PROMOTION_LUCKYDRAW';
export const GET_PROMOTION_LUCKYDRAW_SUCCESS = 'GET_PROMOTION_LUCKYDRAW_SUCCESS';
export const GET_PROMOTION_LUCKYDRAW_FAILURE = 'GET_PROMOTION_LUCKYDRAW_FAILURE';
export const POST_PROMOTION_LUCKYDRAW = 'POST_PROMOTION_LUCKYDRAW';
export const POST_PROMOTION_LUCKYDRAW_SUCCESS = 'POST_PROMOTION_LUCKYDRAW_SUCCESS';
export const POST_PROMOTION_LUCKYDRAW_FAILURE = 'POST_PROMOTION_LUCKYDRAW_FAILURE';
export const SET_LUCKYDRAW_RESULT_INITIAL = 'SET_LUCKYDRAW_RESULT_INITIAL';

// dialog
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
