import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import { injectIntl, intlShape } from 'react-intl';

import { Card } from 'components/Card';
import * as _ from 'lodash';

import { getLanguage } from 'reducers/SettingReducer';

import { getLoyaltyPointHistory } from 'actions';

import { getRecordList as getLoyaltyPointHistoryList, getLoading, getCount } from 'reducers/LoyaltyPointHistoryReducer';

const Point = ({ match, intl }) => {
    const increment = intl.formatMessage({ id: 'memberpoint.increment' });
    const redemption = intl.formatMessage({ id: 'memberpoint.redemption' });
    const systemAdjustment = intl.formatMessage({ id: 'memberpoint.systemAdjustment' });
    const typeMap = {
        'Increment': increment,
        'Redemption': redemption,
        'System Adjustment': systemAdjustment,
    };

    const sourceMap = {
        transaction_history__c: intl.formatMessage({ id: 'memberpoint.transaction' }),
        account: intl.formatMessage({ id: 'memberpoint.referral' }),
        deposit_and_withdrawal__c: intl.formatMessage({ id: 'memberpoint.deposit' }),
    };

    const historyIdC = intl.formatMessage({ id: 'memberpoint.historyId' });
    const titleC = intl.formatMessage({ id: 'memberpoint.title' });
    const dateTimeC = intl.formatMessage({ id: 'memberpoint.dateTime' });
    const entityC = intl.formatMessage({ id: 'memberpoint.entity' });
    const sourceC = intl.formatMessage({ id: 'memberpoint.source' });

    const pointC = intl.formatMessage({ id: 'memberpoint.point' });

    const columnMap = {
        [historyIdC]: 'name',
        [titleC]: 'type__c',
        [dateTimeC]: 'createddate',
        [entityC]: 'child_account__c.entity__c',
        [sourceC]: 'table_name__c',
        [pointC]: 'loyalty_point__c',
    };

    const columns = Object.keys(columnMap);
    const columnAPIs = Object.values(columnMap);

    const [loyaltyPointShowList, setLoyaltyPointShowList] = useState([]);

    const [pageSize, setPageSize] = useState(0);

    const tableOptions = {
        print: false,
        filterType: 'dropdown',
        filter: false,
        customToolbarSelect: () => {},
        selectableRows: 'none',
        rowsPerPageOptions: [5, 10, 20],
        onChangePage: currentPage => {
            setOpts(o => ({ ...opts, pageNo: currentPage }));
            disaptch(getLoyaltyPointHistory({ queryParam: {}, ...opts, pageNo: currentPage }));
        },
        onChangeRowsPerPage: numberOfRows => {
            setOpts(o => ({ ...opts, pageSize: numberOfRows }));
            disaptch(getLoyaltyPointHistory({ queryParam: {}, ...opts, pageSize: numberOfRows }));
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (columnMap[changedColumn].indexOf('.') > -1) return;

            let param = { isAsc: direction === 'asc' ? true : false, orderBy: columnMap[changedColumn] };

            setOpts(o => ({ ...opts, ...param }));
            disaptch(getLoyaltyPointHistory({ queryParam: {}, ...opts, ...param }));
        },
        elevation: 0,
        serverSide: true,
        count: pageSize,
        download: false,
        textLabels: {
            body: {
                noMatch: intl.formatMessage({ id: 'table.body.noMatch' }),
                toolTip: intl.formatMessage({ id: 'table.body.sort' }),
                columnHeaderTooltip: column => intl.formatMessage({ id: 'table.body.sorFor' }) + ` ${column.label}`,
            },
            pagination: {
                next: intl.formatMessage({ id: 'table.pagination.next' }),
                previous: intl.formatMessage({ id: 'table.pagination.previous' }),
                rowsPerPage: intl.formatMessage({ id: 'table.pagination.rowsPerPage' }),
                displayRows: intl.formatMessage({ id: 'table.pagination.displayRows' }),
            },
            toolbar: {
                search: intl.formatMessage({ id: 'table.toolbar.search' }),
                downloadCsv: intl.formatMessage({ id: 'table.toolbar.downloadCsv' }),
                print: intl.formatMessage({ id: 'table.toolbar.print' }),
                viewColumns: intl.formatMessage({ id: 'table.toolbar.viewColumns' }),
                filterTable: intl.formatMessage({ id: 'table.toolbar.filterTable' }),
            },
            filter: {
                all: intl.formatMessage({ id: 'table.filter.all' }),
                title: intl.formatMessage({ id: 'table.filter.title' }),
                reset: intl.formatMessage({ id: 'table.filter.reset' }),
            },
            viewColumns: {
                title: intl.formatMessage({ id: 'table.viewColumns.title' }),
                titleAria: intl.formatMessage({ id: 'table.viewColumns.titleAria' }),
            },
            selectedRows: {
                text: intl.formatMessage({ id: 'table.selectedRows.text' }),
                delete: intl.formatMessage({ id: 'table.selectedRows.delete' }),
                deleteAria: intl.formatMessage({ id: 'table.selectedRows.deleteAria' }),
            },
        },
    };

    const [opts, setOpts] = useState({
        orderBy: 'name',
        isAsc: false,
        pageSize: 10,
        pageNo: 0,
    });

    const disaptch = useDispatch();

    const { loyaltyPointList, loading, count, language } = useSelector(state => ({
        loyaltyPointList: getLoyaltyPointHistoryList(state.loyaltyPointHistory),
        loading: getLoading(state.loyaltyPointHistory),
        count: getCount(state.loyaltyPointHistory),
        language: getLanguage(state.setting),
    }));

    useEffect(() => {
        setPageSize(Number(count));
    }, [count]);

    const resetLoyaltyPointShowList = () => {
        setLoyaltyPointShowList(list => []);
        loyaltyPointList.forEach((data, index) => {
            let listToPush = [];
            for (let APIName of columnAPIs) {
                if (APIName === 'createddate' && _.get(data, APIName)) {
                    // console.log(
                    //     moment(_.get(data, APIName)).utc().local().format('YYYY-MM-DD HH:mm:ss'),
                    //     moment(_.get(data, APIName)).utc().format('YYYY-MM-DD HH:mm:ss')
                    // );
                    listToPush.push(moment(_.get(data, APIName)).utc().local().format('YYYY-MM-DD HH:mm:ss'));
                    continue;
                }
                if (APIName === 'type__c') {
                    listToPush.push(_.get(typeMap, _.get(data, APIName)));
                    continue;
                }
                if (APIName === 'table_name__c') {
                    listToPush.push(_.get(sourceMap, _.get(data, APIName)));
                    continue;
                }
                listToPush.push(_.get(data, APIName));
            }
            setLoyaltyPointShowList(list => [...list, listToPush]);
        });
    };

    useEffect(() => {
        resetLoyaltyPointShowList();
    }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        disaptch(getLoyaltyPointHistory({ queryParam: {}, ...opts }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        resetLoyaltyPointShowList();
    }, [loyaltyPointList]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="memberpoint-wrapper">
            <Card>
                <MUIDataTable
                    title={
                        <Typography component="span">
                            <IntlMessages id="memberpoint.pointTable" />
                            {loading && (
                                <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
                            )}
                        </Typography>
                    }
                    data={loyaltyPointShowList}
                    columns={columns}
                    options={tableOptions}
                />
            </Card>
        </div>
    );
};

Point.prototype = {
    intl: intlShape.isRequired,
};

export default injectIntl(Point);
