import { Controller } from 'react-hook-form';
import { Input, FormGroup, InputGroup } from 'reactstrap';
import * as _ from 'lodash';

const CustomInputGroup = ({ children, name, control, defaultValue, render, errors, customError, rules, ...params }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            errors={errors}
            {...params}
            rules={rules}
            render={props => (
                <FormGroup className="has-wrapper">
                    <InputGroup>
                        <Input
                            disabled={params.disabled}
                            type={params.type}
                            placeholder={params.placeholder}
                            {...props}
                        />
                        {children}
                    </InputGroup>
                    <span className="error-message">{errors && errors[name] && errors[name]['message']}</span>
                    <span className="error-message">{_.isEmpty(errors[name]) && customError}</span>
                </FormGroup>
            )}
        ></Controller>
    );
};

export default CustomInputGroup;
