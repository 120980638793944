import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IntlMessages from 'util/IntlMessages';
import { injectIntl, intlShape } from 'react-intl';

function AlertBoxEmailOTP({ history, intl, ...props }) {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
                disableBackdropClick={true}
            >
                <DialogTitle id="form-dialog-title">
                    {intl.formatMessage({
                        id: 'memberreg.form.mobile_otp',
                    })}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="otpcode"
                        // label={t('form.otp.label')}
                        fullWidth
                        variant="outlined"
                        value={props.OTPCode}
                        inputProps={{
                            maxLength: 6,
                        }}
                        onChange={event => {
                            props.setOTPCode(event.target.value);
                        }}
                        error={!!props.OTPCodeErrorMessage}
                    />
                    {!!props.OTPCodeErrorMessage && <FormHelperText error>{props.OTPCodeErrorMessage}</FormHelperText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        <IntlMessages id="memberreg.form.mobile_otp_cancel" />
                    </Button>
                    <Button onClick={() => props.confirmButtonClick(props.OTPCode)} color="primary">
                        <IntlMessages id="memberreg.form.mobile_otp_confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
AlertBoxEmailOTP.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(AlertBoxEmailOTP);
