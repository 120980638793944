import { NotificationManager } from 'react-notifications';
import * as _ from 'lodash';

import {
    GET_FAVORITE_REWARD,
    GET_FAVORITE_REWARD_SUCCESS,
    GET_FAVORITE_REWARD_FAILURE,
    POST_FAVORITE_REWARD_SUCCESS,
    POST_FAVORITE_REWARD_FAILURE,
    POST_FAVORITE_REWARD,
    DELETE_FAVORITE_REWARD_SUCCESS,
    DELETE_FAVORITE_REWARD_FAILURE,
    DELETE_FAVORITE_REWARD,
    GET_LOYALTY_POINT_HISTORY_SUCCESS,
    GET_LOYALTY_POINT_HISTORY_FAILURE,
    GET_LOYALTY_POINT_HISTORY,
    GET_LOYALTY_PORTAL_CONFIG_SUCCESS,
    GET_LOYALTY_PORTAL_CONFIG_FAILURE,
    GET_LOYALTY_PORTAL_CONFIG,
    GET_REFERRAL_HISTORY,
    GET_REFERRAL_HISTORY_FAILURE,
    GET_REFERRAL_HISTORY_SUCCESS,
    GET_YEARLY_RESULT,
    GET_YEARLY_RESULT_SUCCESS,
    GET_YEARLY_RESULT_FAILURE,
    GET_CHILD_ACCOUNT,
    GET_CHILD_ACCOUNT_SUCCESS,
    GET_CHILD_ACCOUNT_FAILURE,
    GET_REDEMPTION,
    GET_REDEMPTION_SUCCESS,
    GET_REDEMPTION_FAILURE,
    POST_REDEMPTION,
    POST_REDEMPTION_FAILURE,
    POST_REDEMPTION_SUCCESS,
    GET_REWARD,
    GET_REWARD_FAILURE,
    GET_REWARD_SUCCESS,
    GET_CURRENT_REWARD,
    GET_CURRENT_REWARD_FAILURE,
    GET_CURRENT_REWARD_SUCCESS,
    SET_REWARD_FILTER,
    SET_REWARD_INPUT_PARAMS,
    GET_TOP_FAVORITE_REWARD_SUCCESS,
    GET_TOP_FAVORITE_REWARD_FAILURE,
    GET_TOP_FAVORITE_REWARD,
    GET_TIER_RULE_SUCCESS,
    GET_TIER_RULE_FAILURE,
    GET_TIER_RULE,
    GET_MEMBER_REG_RECORD,
    GET_MEMBER_REG_RECORD_SUCCESS,
    GET_MEMBER_REG_RECORD_FAILURE,
    POST_MEMBER_REG_RECORD,
    POST_MEMBER_REG_RECORD_SUCCESS,
    POST_MEMBER_REG_RECORD_FAILURE,
    GET_ACCOUNT_RECORD,
    GET_ACCOUNT_RECORD_SUCCESS,
    GET_ACCOUNT_RECORD_FAILURE,
    GET_PROMOTION_LUCKYDRAW,
    GET_PROMOTION_LUCKYDRAW_SUCCESS,
    GET_PROMOTION_LUCKYDRAW_FAILURE,
    POST_PROMOTION_LUCKYDRAW,
    POST_PROMOTION_LUCKYDRAW_SUCCESS,
    POST_PROMOTION_LUCKYDRAW_FAILURE,
    SET_LUCKYDRAW_RESULT_INITIAL,
    OPEN_DIALOG,
} from './types';

import {
    getFavoriteReward as getFavoriteRewardService,
    postFavoriteReward as postFavoriteRewardService,
    deleteFavoriteReward as deleteFavoriteRewardService,
    getLoyaltyPointHistory as getLoyaltyPointHistoryService,
    getLoyaltyPortal as getLoyaltyPortalService,
    getChildAccount as getChildAccountService,
    getReferralHistory as getReferralHistoryService,
    getYearlyResult as getYearlyResultService,
    getRedemption as getRedemptionService,
    postRedemption as postRedemptionService,
    getReward as getRewardService,
    getRewardById as getRewardByIdService,
    getTopFavoriteReward as getTopFavoriteRewardService,
    getTierRule as getTierRuleService,
    getExistingMemberReg as getExistingMemberRegService,
    postMemberRegForm as postMemberRegFormService,
    getExistingAccountRecord as getExistingAccountRecordService,
    getPromotionLuckyDraw as getPromotionLuckyDrawService,
    postPromotionLuckyDraw as postPromotionLuckyDrawService,
} from 'services';

export const getFavoriteReward = params => dispatch => {
    dispatch({ type: GET_FAVORITE_REWARD });
    getFavoriteRewardService(params)
        .then(res => {
            dispatch({ type: GET_FAVORITE_REWARD_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_FAVORITE_REWARD_FAILURE });
        });
};

export const postFavoriteReward = data => dispatch => {
    dispatch({ type: POST_FAVORITE_REWARD });
    postFavoriteRewardService(data)
        .then(res => {
            dispatch({ type: POST_FAVORITE_REWARD_SUCCESS });
            NotificationManager.success('Favorite Reward added');
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: POST_FAVORITE_REWARD_FAILURE });
        });
};

export const deleteFavoriteReward = id => dispatch => {
    dispatch({ type: DELETE_FAVORITE_REWARD });
    deleteFavoriteRewardService(id)
        .then(res => {
            dispatch({ type: DELETE_FAVORITE_REWARD_SUCCESS });
            NotificationManager.success('Favorite Reward deleted');
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: DELETE_FAVORITE_REWARD_FAILURE });
        });
};

export const getLoyaltyPointHistory = params => dispatch => {
    dispatch({ type: GET_LOYALTY_POINT_HISTORY });
    getLoyaltyPointHistoryService(params)
        .then(res => {
            dispatch({ type: GET_LOYALTY_POINT_HISTORY_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_LOYALTY_POINT_HISTORY_FAILURE });
        });
};

export const getLoyaltyPortalConfig = params => dispatch => {
    dispatch({ type: GET_LOYALTY_PORTAL_CONFIG });
    getLoyaltyPortalService(params)
        .then(res => {
            dispatch({ type: GET_LOYALTY_PORTAL_CONFIG_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_LOYALTY_PORTAL_CONFIG_FAILURE });
        });
};

export const getChildAccount = params => dispatch => {
    dispatch({ type: GET_CHILD_ACCOUNT });
    getChildAccountService(params)
        .then(res => {
            dispatch({ type: GET_CHILD_ACCOUNT_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_CHILD_ACCOUNT_FAILURE });
        });
};

export const getReferralHistory = params => dispatch => {
    dispatch({ type: GET_REFERRAL_HISTORY });
    getReferralHistoryService(params)
        .then(res => {
            dispatch({ type: GET_REFERRAL_HISTORY_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_REFERRAL_HISTORY_FAILURE });
        });
};
export const getYearlyResult = params => dispatch => {
    dispatch({ type: GET_YEARLY_RESULT });
    getYearlyResultService(params)
        .then(res => {
            dispatch({ type: GET_YEARLY_RESULT_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_YEARLY_RESULT_FAILURE });
        });
};

export const getRedemption = params => dispatch => {
    dispatch({ type: GET_REDEMPTION });
    getRedemptionService(params)
        .then(res => {
            dispatch({ type: GET_REDEMPTION_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_REDEMPTION_FAILURE });
        });
};

export const postRedemption = (data, history) => dispatch => {
    dispatch({ type: POST_REDEMPTION });
    postRedemptionService(data)
        .then(res => {
            dispatch({ type: POST_REDEMPTION_SUCCESS });
            NotificationManager.success('Redemption Request Successfully');
            history.push('/app/redemption/history');
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: POST_REDEMPTION_FAILURE });
        });
};

export const postRedemptionNew = (data, orderedItem, intl, history) => dispatch => {
    dispatch({ type: POST_REDEMPTION });
    postRedemptionService(data)
        .then(res => {
            const responseItem = res.data.result.recordList[0];
            dispatch({ type: POST_REDEMPTION_SUCCESS });
            history.push('/app/redemption/history-mobile');
            dispatch({
                type: OPEN_DIALOG,
                payload: {
                    open: true,
                    id: responseItem[0].id,
                    orderedItem,
                    title: intl.formatMessage({ id: 'reward.redeemSuccessTitle' }),
                    content: intl.formatMessage({ id: 'reward.redeemSuccessContent' }),
                },
            });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: POST_REDEMPTION_FAILURE });
        });
};

export const getReward = params => dispatch => {
    dispatch({ type: GET_REWARD });
    getRewardService(params)
        .then(res => {
            dispatch({ type: GET_REWARD_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_REWARD_FAILURE });
        });
};

export const setRewardFilter = params => dispatch => {
    dispatch({ type: SET_REWARD_FILTER, payload: params });
};

export const setRewardInputParams = params => dispatch => {
    dispatch({ type: SET_REWARD_INPUT_PARAMS, payload: params });
};

export const getCurrentReward = (id, data) => dispatch => {
    dispatch({ type: GET_CURRENT_REWARD });
    getRewardByIdService(id, { ...data })
        .then(res => {
            dispatch({ type: GET_CURRENT_REWARD_SUCCESS, payload: res.data.result.recordList[0] });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_CURRENT_REWARD_FAILURE });
        });
};

export const getTopFavoriteReward = params => dispatch => {
    dispatch({ type: GET_TOP_FAVORITE_REWARD });
    getTopFavoriteRewardService(params)
        .then(res => {
            dispatch({ type: GET_TOP_FAVORITE_REWARD_SUCCESS, payload: res.data.result });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_TOP_FAVORITE_REWARD_FAILURE });
        });
};

export const getTierRule = params => dispatch => {
    dispatch({ type: GET_TIER_RULE });
    getTierRuleService(params)
        .then(res => {
            dispatch({ type: GET_TIER_RULE_SUCCESS, payload: res.data });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_TIER_RULE_FAILURE });
        });
};

export const getMemberRegRecord = params => dispatch => {
    dispatch({ type: GET_MEMBER_REG_RECORD });
    getExistingMemberRegService(params)
        .then(res => {
            dispatch({ type: GET_MEMBER_REG_RECORD_SUCCESS, payload: res.data });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_MEMBER_REG_RECORD_FAILURE });
        });
};

export const getExistingAccountRecord = params => dispatch => {
    dispatch({ type: GET_ACCOUNT_RECORD });
    getExistingAccountRecordService(params)
        .then(res => {
            dispatch({ type: GET_ACCOUNT_RECORD_SUCCESS, payload: res.data });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_ACCOUNT_RECORD_FAILURE });
        });
};

export const postMemberRegRecord = params => dispatch => {
    dispatch({ type: POST_MEMBER_REG_RECORD });
    postMemberRegFormService(params)
        .then(res => {
            dispatch({ type: POST_MEMBER_REG_RECORD_SUCCESS, payload: res.data });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: POST_MEMBER_REG_RECORD_FAILURE });
        });
};

export const getPromotionEventRecord = params => dispatch => {
    dispatch({ type: GET_PROMOTION_LUCKYDRAW });
    getPromotionLuckyDrawService(params)
        .then(res => {
            dispatch({ type: GET_PROMOTION_LUCKYDRAW_SUCCESS, payload: res.data });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: GET_PROMOTION_LUCKYDRAW_FAILURE });
        });
};

export const postPromotionEventRecord = params => dispatch => {
    dispatch({ type: POST_PROMOTION_LUCKYDRAW });
    postPromotionLuckyDrawService(params)
        .then(res => {
            dispatch({ type: POST_PROMOTION_LUCKYDRAW_SUCCESS, payload: res.data });
        })
        .catch(error => {
            if (_.get(error, 'response.data')) {
                NotificationManager.error(error.response.data.message);
            }
            dispatch({ type: POST_PROMOTION_LUCKYDRAW_FAILURE });
        });
};

export const setLuckyDrawInitial = () => {
    return {
        type: SET_LUCKYDRAW_RESULT_INITIAL,
    };
};
