import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

const CustomMaterialInput = ({ name, control, defaultValue, render, errors, rules, ...params }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            // errors={errors}
            {...params}
            rules={rules}
            render={props => {
                return (
                    <TextField
                        label={params.label}
                        variant={params.variant}
                        disabled={params.disabled}
                        type={params.type}
                        placeholder={params.placeholder}
                        value={props.value}
                        error={!!params.error}
                        fullWidth={params.fullWidth}
                        InputProps={params.inputProps}
                        onChange={value => {
                            props.onChange(value);
                        }}
                        // inputRef={props.field.ref}
                        // helperText={props.fieldState.error ? props.fieldState.error.message : null}
                        id={params.id}
                        {...props}
                    />
                );
            }}
        />
    );
};

export default CustomMaterialInput;
