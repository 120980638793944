import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentReward } from 'actions';
import { getCurrentRecord, getLoading } from 'reducers/RewardReducer';
import { getLanguage } from 'reducers/SettingReducer';
import { getUser } from 'reducers/AuthReducer';
import * as _ from 'lodash';
import star from 'assets/img/star.png';
import NumberFormat from 'react-number-format';
import { Button, CircularProgress } from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import CustomTabs from './components/Tabs';
import { useHistory } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ReactImageGallery from 'react-image-gallery';

const AwardDetailMobile = ({ match }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { curReward, loading, language, user } = useSelector(state => ({
        curReward: getCurrentRecord(state.reward),
        loading: getLoading(state.reward),
        language: getLanguage(state.setting),
        user: getUser(state.auth),
    }));

    const [images, setImages] = useState([]);
    const [tabItems, setTabItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [isRedeemAble, setIsRedeemAble] = useState(false);

    const [isExpandAble, setIsExpandAble] = useState(false);
    const [isSeeMore, setIsSeeMore] = useState(false);

    const handleBack = () => {
        history.push('/app/redemption/award-mobile');
    };

    const handleRedeem = () => {
        history.push({
            pathname: match.url + '/checkout',
        });
    };

    useEffect(() => {
        dispatch(getCurrentReward(match.params.id, { queryParam: { language: language.languageId } }));
    }, [language]);

    useEffect(() => {
        let tmpImage = [];
        let tmpItems = [];
        let isAble = false;

        if (curReward) {
            // set images
            if (_.get(curReward, 'reward_item__c.image_path__c')) {
                tmpImage.push({
                    original: _.get(curReward, 'reward_item__c.image_path__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.image_path__c'),
                });
            }
            if (_.get(curReward, 'reward_item__c.sub_image_path_1__c')) {
                tmpImage.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_1__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_1__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.sub_image_path_2__c')) {
                tmpImage.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_2__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_2__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.sub_image_path_3__c')) {
                tmpImage.push({
                    original: _.get(curReward, 'reward_item__c.sub_image_path_3__c'),
                    thumbnail: _.get(curReward, 'reward_item__c.sub_image_path_3__c'),
                });
            }
            // end set images

            // set tab items content
            if (_.get(curReward, 'reward_item__c.item_remark__c')) {
                tmpItems.push({
                    tabId: 1,
                    title: <IntlMessages id="reward.itemRemark" />,
                    content: _.get(curReward, 'reward_item__c.item_remark__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.shipping_information__c')) {
                tmpItems.push({
                    tabId: 2,
                    title: <IntlMessages id="reward.shippingInfo" />,
                    content: _.get(curReward, 'reward_item__c.shipping_information__c'),
                });
            }

            if (_.get(curReward, 'reward_item__c.terms_conditions__c')) {
                tmpItems.push({
                    tabId: 3,
                    title: <IntlMessages id="reward.itemTC" />,
                    content: _.get(curReward, 'reward_item__c.terms_conditions__c'),
                });
            }
            // end set tab items content

            // check is reward redeemable
            if (user) {
                isAble = curReward?.required_loyalty_point__c > user.member.total_loyalty_point__c;
            }
            // end check is reward redeemable

            // check description content
            const desc = _.get(curReward, 'reward_item__c.item_description__c');
            if (desc && desc.split('\n').length > 6) {
                setIsExpandAble(true);
            }
            // end check description content

            setImages(tmpImage);
            setTabItems(tmpItems);
            setIsRedeemAble(isAble);
        }
    }, [curReward]);

    return (
        <div className="reward-new-wrapper">
            <div className="content-wrapper">
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <CircularProgress size={75} />
                    </div>
                ) : (
                    <>
                    <div className='reward-detail'>
                    <IntlMessages id="reward.details" />
                    </div>
                        <div className="slider-wrapper">
                            <ReactImageGallery
                                items={images}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                showNav={false}
                                showThumbnails={false}
                                showBullets={true}
                                renderItem={el => (
                                    <div className="d-flex justify-content-center" style={{ margin: '0 4px' }}>
                                        <img
                                            className="slider-img"
                                            src={el.original}
                                            width="320"
                                            height="320"
                                            alt={`slider`}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <div className="reward-title">{curReward?.reward_item__c.name}</div>
                        <div className="d-flex">
                            <div className="reward-point">
                                <NumberFormat
                                    value={curReward?.required_loyalty_point__c}
                                    thousandSeparator={true}
                                    displayType="text"
                                />
                            </div>
                            <img src={star} width={24} height={24} alt="star" />
                        </div>
                        <div className="reward-content">
                            <div>
                                <span className="reward-content-label">{<IntlMessages id="reward.itemCode" />} : </span>
                                <span className="reward-content-value">
                                    {_.get(curReward, 'reward_item__c.item_code__c')}
                                </span>
                            </div>
                            <div style={isExpandAble && !isSeeMore ? { height: '150px', overflow: 'hidden' } : {}}>
                                <span className="reward-content-label">
                                    {<IntlMessages id="reward.itemDescription" />} :{' '}
                                </span>
                                <br />
                                <span className="reward-content-value">
                                    {_.get(curReward, 'reward_item__c.item_description__c')}
                                </span>
                            </div>
                            {isExpandAble && (
                                <div
                                    style={{ textAlign: 'center', margin: '12px 0' }}
                                    onClick={() => setIsSeeMore(prev => !prev)}
                                >
                                    <IntlMessages id={!isSeeMore ? 'reward.showMore' : 'reward.showLess'} />{' '}
                                    {!isSeeMore ? <ExpandMore /> : <ExpandLess />}
                                </div>
                            )}
                        </div>
                        <div className="mt-3">
                            <CustomTabs activeTab={selectedTab} setActiveTab={setSelectedTab} items={tabItems} />
                        </div>
                    </>
                )}
            </div>
            <div className="reward-actions">
                <Button className="back-button" fullWidth onClick={handleBack} disabled={loading}>
                    <IntlMessages id="reward.back" />
                </Button>
                <Button
                    className={!isRedeemAble && !loading ? 'redeem-button' : 'redeem-button-disabled'}
                    fullWidth
                    disabled={isRedeemAble || loading}
                    onClick={handleRedeem}
                >
                    <IntlMessages id="reward.redeem" />
                </Button>
            </div>
        </div>
    );
};

export default AwardDetailMobile;
