import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

import { CardHeading } from './CardHeading';

const CollapsibleCard = ({ children, heading, customClass, collapsible }) => {
    const [collapse, setCollapse] = useState(false);

    const handleCollapse = event => {
        event.preventDefault();
        setCollapse(!collapse);
    };

    return (
        <div className={(customClass || '') + ' rct-block rct-collapse-block'}>
            {heading && (
                <CardHeading
                    title={heading}
                    link={
                        collapsible && (
                            <button onClick={handleCollapse}>
                                <i className="zmdi zmdi-minus"></i>
                            </button>
                        )
                    }
                    removeLine={collapse}
                />
            )}
            <Collapse isOpen={!collapse}>{children}</Collapse>
        </div>
    );
};

export { CollapsibleCard };
