import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput';

import { injectIntl, intlShape } from 'react-intl';

import SessionLayout from 'container/SessionLayout';
import IntlMessages from 'util/IntlMessages';
import LanguageProvider from 'components/Header/LanguageProvider';
import RequirementList from 'components/PasswordRequirement/RequirementList';

import { getLoading, getError } from 'reducers/AuthReducer';

import { changePassword } from 'actions';

const Changepwd = ({ history, intl }) => {
    const ChangePwdSchema = yup.object().shape({
        'user-mail': yup
            .string()
            .required(intl.formatMessage({ id: 'input.emailRequired' }))
            .email(intl.formatMessage({ id: 'input.emailRestricted' })),
        'user-new-pwd': yup
            .string()
            .required(intl.formatMessage({ id: 'input.passwordRequired' }))
            .matches(
                /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/,
                intl.formatMessage({ id: 'input.specialCharRequired' })
            )
            .matches(/^.*[a-z].*$/, intl.formatMessage({ id: 'input.lowercaseRequired' }))
            .matches(/^.*[A-Z].*$/, intl.formatMessage({ id: 'input.uppercaseRequired' }))
            .matches(/^.*[0-9].*$/, intl.formatMessage({ id: 'input.numberRequired' }))
            .min(8, intl.formatMessage({ id: 'input.minLengthRequired' })),
        'user-pwd': yup.string().required(intl.formatMessage({ id: 'input.passwordRequired' })),
        'confirm-pwd': yup
            .string()
            .oneOf([yup.ref('user-new-pwd'), null], intl.formatMessage({ id: 'input.passwordMatched' })),
    });

    const dispatch = useDispatch();

    const { loading, error } = useSelector(state => ({
        loading: getLoading(state.auth),
        error: getError(state.auth),
    }));

    const { control, handleSubmit, errors } = useForm({
        criteriaMode: 'all',
        resolver: yupResolver(ChangePwdSchema),
    });

    const onChangePassword = data => {
        dispatch(
            changePassword(
                { username: data['user-mail'], oldPassword: data['user-pwd'], password: data['user-new-pwd'] },
                history
            )
        );
    };

    const emailPH = intl.formatMessage({ id: 'changepw.email' });
    const oldPwPH = intl.formatMessage({ id: 'changepw.oldPassword' });
    const newPwPH = intl.formatMessage({ id: 'changepw.newPassword' });
    const confirmPwPH = intl.formatMessage({ id: 'changepw.confirmPassword' });

    return (
        <SessionLayout
            loading={loading}
            sessionHeaderRight={
                <div className="row justify-content-center align-self-center">
                    <LanguageProvider />
                </div>
            }
        >
            <div className="session-component-container w-100 shadow">
                <div className="session-body text-center h-100 px-5">
                    <div className="session-head my-4">
                        <h2 className="font-weight-bold">
                            <IntlMessages id="changepw.changePassword" />
                        </h2>
                        {error &&
                            (typeof error === 'number' ? (
                                <span className="error-message">
                                    <IntlMessages id={`error.${error}`} />
                                </span>
                            ) : (
                                <span className="error-message">
                                    <IntlMessages id={'error.default'} />
                                </span>
                            ))}
                    </div>
                    <Form onSubmit={handleSubmit(onChangePassword)}>
                        <CustomInput
                            errors={errors}
                            control={control}
                            type="mail"
                            name="user-mail"
                            id="user-mail"
                            defaultValue=""
                            className="has-input input-lg"
                            placeholder={emailPH}
                        />

                        <CustomInput
                            errors={errors}
                            control={control}
                            type="password"
                            name="user-pwd"
                            id="user-pwd"
                            defaultValue=""
                            className="has-input input-lg"
                            placeholder={oldPwPH}
                        />

                        <CustomInput
                            // errors={errors}
                            control={control}
                            type="password"
                            name="user-new-pwd"
                            id="user-new-pwd"
                            defaultValue=""
                            className="has-input input-lg"
                            placeholder={newPwPH}
                        />

                        <CustomInput
                            errors={errors}
                            control={control}
                            type="password"
                            name="confirm-pwd"
                            id="confirm-pwd"
                            defaultValue=""
                            className="has-input input-lg"
                            placeholder={confirmPwPH}
                        />

                        <FormGroup>
                            <Button
                                disabled={loading}
                                color="primary"
                                className="btn-block text-white w-100"
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                <IntlMessages id="changepw.confirm" />
                            </Button>
                        </FormGroup>

                        <RequirementList className="align-self-center" errors={errors['user-new-pwd']} />
                    </Form>
                </div>
            </div>
        </SessionLayout>
    );
};

Changepwd.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Changepwd);
